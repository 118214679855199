<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h1>Trockenläuferpumpe (überschlägig)</h1>
    <ul>
      <li>
        Die Anzahl der Anlagen sollte nicht Vereinfacht werden, da dies zur
        Effizienzsteigerung führt.
      </li>
    </ul>

    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Schritte vor der Berechnung</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol type="1">
            <li>
              <b>Einsparpotential und Wirtschaftlichkeit mit EARNS-Potentialanalyse 
                abschätzen anhand der Typenschilddaten</b>
              <p>Erste Annahmen hierbei:</p>
                <ul>
                  <li>
                    Die Bestands-Pumpe läuft im Auslegungspunkt gemäß Typenschild
                  </li>
                </ul> 
              </li> 
              <li> 
                <b>Bedarf überprüfen und mit aktuellem Betriebspunkt vergleichen</b>
                <p>
                  Häufig ist die Bestands-Pumpe größer dimensioniert als erforderlich. Dies kann z.B. daran liegen, dass die beheizten Bereiche umgenutzt wurden und keine Anpassung des Heizbedarfs und der Heizwassermenge erfolgte, oder weil es früher häufig üblich war eine große Sicherheit einzuplanen. 
                  Ziel ist es, die neue Pumpe so klein wie erforderlich auszulegen. 
                  Hierzu lohnt es sich, mindestens folgende Punkte zu prüfen:
                </p>
                <ul>
                  <li>
                    Ist die Pumpe überdimensioniert? In Heizungsanlagen liegt oft 
                    eine Überdimensionierung um bis zu 100% vor.
                  </li>
                  <li>
                    Übereinstimmung der Betriebszeiten der Anlage mit den 
                    Nutzungszeiten der versorgten Zone. Läuft die Pumpe länger als eigentlich erforderlich?
                  </li>
                  <li>
                    Wurden Gebäudeteile stillgelegt, ohne dass die zu bewegende 
                    Heizwassermenge reduziert wurde?
                  </li>
                  <li>
                    Kann durch einen hydraulischen Abgleich die benötigte 
                    Heizwassermenge reduziert werden?
                  </li>
                </ul>
              </li> 
              <li>
              <b>Auslegungs-Betriebspunkt für die neue Pumpe definieren</b>
              </li> 
              <li> 
                <b>Einsparpotential und Wirtschaftlichkeit mit EARNS-
                  Potentialanalyse abschätzen.</b>
                <ul>
                  <li>
                    Anhand der gemessenen Daten (Bestandspumpe) und des neuen 
                    Auslegungspunktes (Neupumpe). In dem EARNS-Potentialanalyse-
                    Report wird eine neue Pumpe vorgeschlagen.
                  </li>
                </ul>
              </li> 
              <li> 
              <b>Überprüfen der baulichen Einbausituation für die Konzeption des 
                Neusystems</b>
              <ul>
                <li>
                  Hierdurch scheiden evtl. bereits gewisse Lösungen für das 
                  Neusystem aus
                </li>
              </ul>
              </li> 
              <li> 
              <b>Überprüfung der Pumpe</b>
              <ul>
                <li>
                  Anhand von Herstellersoftware sollte die für den jeweiligen 
                  Anwendungsfall (Einbausituation, Betriebspunkt, Kosten) optimal 
                  geeignete Pumpe ermittelt werden.
                </li>
              </ul>
              </li> 
              <li> 
              <b>Kosten für die gewählte Systemlösung ermitteln</b>
              <ul>
                <li>
                  unter Berücksichtigung aller relevanten Faktoren wie Aufwand für 
                  Demontage, Umbau, Einbringung, Entleeren, Anschlussarbeiten, 
                  Inbetriebnahme, etc.
                </li>
              </ul>
              </li> 
              <li> 
              <b>Angebotspreis für die gewählte Systemlösung kalkulieren</b>
              </li> 
              <li> 
              <b>Finale Einsparhöhe gemäß der gewählten Systemlösung berechnen</b>
              </li> 
              <li> 
              <b>Finale Wirtschaftlichkeit (Einsparung, Rendite, Amortisation) 
                anhand des Angebotspreises und der finalen Einsparhöhe ermitteln
              </b>
            </li>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Parameter der Anlage</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Anzahl</td>
                <td>
                  Anzahl der Trockenläuferpumpen des gleichen Typs und der gleichen
                  Betriebsweise.
                </td>
              </tr>
              <tr>
                <td>Nennleistung</td>
                <td>Auswahl der auf dem Typenschild angegebenen Nennleistung in kW</td>
              </tr>
              <tr>
                <td>Baujahr</td>
                <td>Eingabe des Baujahrs, falls das Baujahr nicht vorhanden ist, das nächst mögliche verwenden.</td>
              </tr>
              <tr>
                <td>VSD / FU</td>
                <td>Wählen Sie die vorhandene Regelungsmethode.</td>
                <td></td>
              </tr>
              <tr>
                <td>Jährliche Betriebsstunden</td>
                <td>
                  Gesamte Betriebszeit pro Jahr in h. Für einen 24-Stunden-
                  Betrieb an 365 Tagen im Jahr geben Sie 8760 Stunden ein.
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungsgrundlage und Daten</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <h3>Energieeffizienzklassen für Pumpen</h3>
          <p>
            Die Anforderung an den Wirkungsgrad von Trockenläuferpumpen ist in den
            vergangenen Jahren erheblich gestiegen. Dabei wird nicht nur der Antrieb
            durch den elektrischen Motor, sondern auch der hydraulische Wirkungsgrad
            der Pumpe bewertet.
          </p>

          <h4>Anforderungen an der Pumpe</h4>
          <p>
            Die Norm IEC 60034-30:2008 definiert die Wirkungsgradklassen für
            Drehstrom-Niederspannungsmotoren im Leistungsbereich von 0,75 kW bis 375
            kW. Die Abkürzung IE steht für International Efficiency
          </p>
          <table>
            <thead>
              <tr>
                <th>Klasse</th>
                <th>Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>IE1</td>
                <td>Standard Wirkungsgrad (vergleichbar EFF2)</td>
              </tr>
              <tr>
                <td>IE2</td>
                <td>Hoher Wirkungsgrad (vergleichbar EFF1)</td>
              </tr>
              <tr>
                <td>IE3</td>
                <td>Premium Wirkungsgrad</td>
              </tr>
              <tr>
                <td>IE4</td>
                <td>Super Premium Efficiency</td>
              </tr>
              <tr>
                <td>IE5</td>
                <td>Ultra Premium Efficiency</td>
              </tr>
            </tbody>
          </table>

          <img
            src="@/assets/help/electricdrive/power-eff.png"
            alt="Effizienz über Nennleitung"
          />

          <p>Vorschriften:</p>
          <ul>
            <li>
              Seit Juli 2021 müssen Dreiphasenmotoren mit einer Nennausgangsleistung
              zwischen 0,75 kW und höchstens 1000 kW das Effizienzniveau IE3
              aufweisen.
            </li>
            <li>
              Ab Juli 2023 ist für Motoren mit einer Leistung zwischen 75 kW und 200
              kW das Effizienzniveau IE4 verpflichtend.
            </li>
          </ul>

          <h4>Anforderung an den hydraulischen Wirkungsgrad</h4>
          <p>
            Der hydraulische Wirkungsgrad wird am Mindesteffizienzindex MEI gemessen.
            (gem. Verordnung (EU) 547/2012).
          </p>
          <p>Seit dem 01.01.2015 wird ein MEI &gt; 0,4 gefordert.</p>

          <h3>Geplante Pumpe</h3>
          <span><b><a href="https://wilo.com/de/de/Katalog/de/produkte-expertise">Wilo</a></b></span>
          <p>
            Die vorausgewählte Trockenläuferpumpe für den Austausch ist die
            Wilo-Stratos GIGA. Die Pumpen der Stratos GIGA Reihe von Wilo sind die
            ersten Trockenläuferpumpen in EC-Motorentechnologie bis zu einer Leistung
            von ca 7,5 kW. Der Antrieb erfolgt über Hocheffizienz EC-Motoren, die
            heute schon oberhalb der höchsten Güteklasse von Elektromotoren liegen,
            welche ab 2017 Vorschrift ist.
          </p>
          <p>
            Ebenfalls liegen die hydraulischen Eigenschaften der Trockenläuferpumpen
            der Stratos GIGA Reihe mit einem Mindesteffizienzindex (MEI) ≥ 0,7 (gemäß
            ErP Direktive 2009/125/EC [Commission Regulation (EU) 547/2012]) heute
            schon oberhalb der ab 2015 geltenden Vorschriften.
          </p>
          <p>
            Durch eine integrierte elektronische Leistungsanpassung mit einfacher
            Bedienung erreichen die Pumpen einen bis zu dreimal größeren Regelbereich
            als herkömmliche elektronisch geregelte Pumpen und mittels einer
            Kommunikationsschnittstelle für alle gängigen Protokolle (Modbus, BACnet,
            CAN, PLR, LON) können die Pumpen direkt an die Gebäudeautomation
            angebunden werden.
          </p>

        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Zusätzliche Informationen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-container>
            <h2>Trockenläuferpumpe</h2>
            <img src="@/assets/help/drypump/drypump.png" alt="Trockenläuferpumpe" />
            <figcaption>Quelle: Wilo</figcaption>
            <p>
              Elektromotoren sind in Europa die wichtigsten Stromverbraucher: Sie sind
              für rund 70 % des industriellen Stromverbrauchs und ein Drittel des
              Stromverbrauchs im Dienstleistungssektor verantwortlich. Fast 20 % des
              weltweiten Stromverbrauchs von Motoren entfallen dabei auf Pumpen. Eine
              Reduzierung des Energieverbrauchs kommt nicht nur der Umwelt – durch
              Ressourcenschonung und weniger klimaschädliches CO2 – zugute, sondern
              auch der Nachhaltigkeitsbilanz der Unternehmen. Denn Stromkosten machen
              den größten Teil der Lebenszykluskosten einer Pumpe aus – bis zu 85 %.
              Das weltweite Einsparpotenzial allein durch die Anwendung moderne
              Pumpentechnik beläuft sich auf bis zu 246 TWh/a, das entspricht einer
              Größenordnung von 24 Atomkraftwerken.
            </p>

            <h3>Optimierungsmöglichkeiten</h3>
            <p>
              Folgende Maßnahmen haben wesentlichen Einfluss auf die Energiekosten einer
              Trockenläuferpumpe:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Kategorie</th>
                  <th>Beschreibung</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Motor</td>
                  <td>Austausch der Pumpe durch neue energieeffiziente Pumpe</td>
                </tr>
                <tr>
                  <td>Dimensionierung</td>
                  <td>Anpassen der Pumpengröße an den tatsächlichen Bedarf</td>
                </tr>
                <tr>
                  <td>Regelung / Steuerung</td>
                  <td>
                    Einsatz einer stufenlosen Drehzahlregelung (Variable Speed Drive
                    VSD)
                  </td>
                </tr>
                <tr>
                  <td>Betriebszeit</td>
                  <td>
                    Anpassung der Betriebszeit an die tatsächlichen Nutzungszeiten
                  </td>
                </tr>
                <tr>
                  <td>Verbraucher</td>
                  <td>Systemoptimierung zur Reduzierung des Bedarfs</td>
                </tr>
              </tbody>
            </table>
           
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Quellen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h2>Quellen</h2>
          <ul>
            <li>ZVEI-Publikation „Motoren und geregelte Antriebe“</li>
            <li>ZVEI-Publikation „Energiesparen mit elektrischen Antrieben“</li>
            <li>Topmotors.ch</li>
            <li>Motor-Challenge-Programm der EU</li>
            <li>Hochschule Luzern, Technik & Architektur</li>
            <li>EU-Richtlinie 2005/32/EG und IEC 60034-30</li>
            <li>EU-Richtlinie 2019/1781</li>
            <li>
              Arqum GmbH / Forschungsstelle für Energiewirtschaft e.V. i. A. des
              Bayerischen Staatsminis-teriums für Umwelt und Gesundheit (2010)
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>
        Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>

  </div>
</template>

<script>

export default {
  name: "DryPump1Help"
};
</script>

<style scoped>
</style>