<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h1>Sonstige Verbraucher</h1>
    <p>
      Diese Methode erlaubt es, die Ergebnisse aus einer eigenen Berechnung, Annahme 
      oder einer detaillierten Berechnung, Simulation außerhalb von EARNS in Earns 
      einpflegen zu können.
    </p>

    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Parameter der Anlage</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Anzahl</td>
                <td>Eingabe der Anzahl</td>
              </tr>
              <tr>
                <td>Stromverbrauch</td>
                <td>Eingabe des Stromverbrauchs in kWh/a</td>
              </tr>
              <tr>
                <td>Wärmeverbrauch</td>
                <td>Eingabe des Wärmeverbrauchs in kWh/a</td>
              </tr>
              <tr>
                <td>Kälteverbrauch</td>
                <td>Eingabe des Kälteverbrauchs in kWh/a</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungstools</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Kostenlose Berechnungstools</figcaption>
            <thead>
              <th>Bezeichnung und Link</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td><a href="https://measur.ornl.gov/landing-screen" target="_blank">Die kostenlose Alternative zu EARNS - MEASUR von U.S. Department of Energy</a></td>
                <td>
                  Software zur Energieanalyse in der Industrie zur Reduzierung des Versorgungsbedarfs.
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>
        Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>
  </div>
</template>

<script>

export default {
  name: "FreeConsumerHelp"
}
</script>

<style scoped>

</style>