<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h1>Sonstige Erzeuger</h1>
    <p>
      Diese Methode erlaubt es, die Ergebnisse aus einer eigenen Berechnung, Annahme 
      oder einer detaillierten Berechnung, Simulation außerhalb von EARNS in Earns 
      einpflegen zu können.
    </p>

    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Übergreifend</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
              <tbody>
              <tr>
                <td>Primär erzeugte Energieform</td>
                <td>Auswahl der Primär erzeugten Energieform</td>
              </tr>
              </tbody>
          </table>  
          <table>
            <figcaption>Parameter der Anlage</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Primäre Aufwandszahl</td>
                <td>Eingabe der Aufwandszahl</td>
              </tr>
              <tr>
                <td>Primärer Energieträger</td>
                <td>Auswahl des primären Energieträgers</td>
              </tr>
              <tr>
                <td>Energiebedarf</td>
                <td>Eingabe des Energiebedarfs in kWh/a</td>
              </tr>
              <tr>
                <td>Passiv erzeugte Energieform</td>
                <td>Auswahl der passiv erzeugten Energieform</td>
              </tr>
              <tr>
                <td>Passive Aufwandszahl</td>
                <td>Eingabe der passiven Aufwandszahl</td>
              </tr>
              <tr>
                <td>Anteil elektrischer Hilfsenergie</td>
                <td>Eingabe der elektrischer Hilfsenergie</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungstools</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Kostenlose Berechnungstools für Wärmepumpen werden hier <a href="https://www.energie-experten.org/news/
          waermepumpe-online-berechnen-die-besten-kostenlosen-online-tools-rechner">energie-experten.org</a> empfohlen. 
          <table>
            <figcaption>Kostenlose Berechnungstools</figcaption>
            <thead>
              <th>Bezeichnung und Link</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td><a href="https://holzheu.shinyapps.io/Luft-WP-Altgaul/" target="_blank">Wärmepumpe - WP-Rechner von Dr. Christoph Glawe</a></td>
                <td>
                  WP-Rechner von Dr. Christoph Glawe berechnet Heizkurve, Heizlast, Auslegung der WP-Leistung, Stromverbrauch, etc.
                </td>
              </tr>
              <tr>
                <td><a href="https://measur.ornl.gov/landing-screen" target="_blank">Die kostenlose Alternative zu EARNS - MEASUR von U.S. Department of Energy</a></td>
                <td>
                  Software zur Energieanalyse in der Industrie zur Reduzierung des Versorgungsbedarfs.
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>


      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Beispiele</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-container>
            <h2>Beispiele</h2>
            <h3>Wärmepumpe statt Heizkessel</h3>
            <p>Eine vereinfachte Heizkessel tausch durch Wärmepumpe kann mit folgenden Parametern eingegeben werden:</p>
            <table>
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Wert</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Primär erzeugte Energieform</td>
                  <td>Wärme</td>
                </tr>
                <tr>
                  <td>Primäre Aufwandszahl</td>
                  <td>0,9 für Kessel (Referenz) und 4 für Wärmepumpe (Geplant)</td>
                </tr>
                <tr>
                  <td>Primärer Energieträger</td>
                  <td>Gas für Kessel (Referenz) und Strom für Wärmepumpe (Geplant)</td>
                </tr>
                <tr>
                  <td>Passiv erzeugte Energieform</td>
                  <td>keine</td>
                </tr>
                <tr>
                  <td>Anteil elektrischer Hilfsenergie</td>
                  <td>0,02 für Kessel (Referenz) und 0 für Wärmepumpe (Geplant)</td>
                </tr>
              </tbody>
            </table>

            <h3>Heizkessel</h3>
            <p>Ein vereinfachter Heizkessel kann mit folgenden Parametern eingegeben werden:</p>
            <table>
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Wert</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Primär erzeugte Energieform</td>
                  <td>Wärme</td>
                </tr>
                <tr>
                  <td>Primäre Aufwandszahl</td>
                  <td>Nutzungsgrad des Kessels</td>
                </tr>
                <tr>
                  <td>Primärer Energieträger</td>
                  <td>Gas</td>
                </tr>
                <tr>
                  <td>Passiv erzeugte Energieform</td>
                  <td>keine</td>
                </tr>
                <tr>
                  <td>Anteil elektrischer Hilfsenergie</td>
                  <td>0,02</td>
                </tr>
              </tbody>
            </table>

            <h3>BHKW</h3>
            <p>Ein vereinfachtes BHKW kann mit folgenden Parametern eingegeben werden:</p>
            <table>
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Wert</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Primär erzeugte Energieform</td>
                  <td>Wärme (für ein Wärme geführtes BHKW)</td>
                </tr>
                <tr>
                  <td>Primäre Aufwandszahl</td>
                  <td>thermischer Wirkungsgrad</td>
                </tr>
                <tr>
                  <td>Primärer Energieträger</td>
                  <td>Gas</td>
                </tr>
                <tr>
                  <td>Passiv erzeugte Energieform</td>
                  <td>Strom</td>
                </tr>
                <tr>
                  <td>Passive Aufwandszahl</td>
                  <td>elektrischer Wirkungsgrad</td>
                </tr>
                <tr>
                  <td>Anteil elektrischer Hilfsenergie</td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
            <p>Für komplexere Erzeugersysteme sprechen Sie uns gerne an.</p>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>
        Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>
  </div>
</template>

<script>

export default {
  name: "FreeGeneratorHelp"
}
</script>

<style scoped>

</style>