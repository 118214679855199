<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h2>Ventilator</h2>
    <ul>
      <li>
        Die Anzahl der Anlagen sollte nicht Vereinfacht werden, da dies zur
        Effizienzsteigerung führt.
      </li>
      <li>
        Die min. Grenze der Luftmenge liegt bei 1000 m³/h, da sich die Methode
        auf diese Dimension der Luftmenge beschränkt.
      </li>
    </ul>

    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Schritte vor der Berechnung</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol>
            <li>
              <b
                >Einsparpotential und Wirtschaftlichkeit mit EARNS
                abschätzen anhand der Typenschilddaten</b
              >
              <p>Erste Annahme hierbei:</p>
              <ul>
                <li>
                  Der Bestands-Ventilator läuft im Auslegungspunkt gemäß Typenschild
                </li>
              </ul>
            </li>
            <li>
              <b
                >Tatsächlichen Betriebspunkt durch Messung ermitteln (bei größeren
                Ventilatoren)</b
              >
              <p>
                Messung der Luftmenge, des Gesamt- bzw. Totaldrucks, der Drehzahl, und
                der elektrischen Leistungsaufnahme (Wirkleistung inkl. cos phi)
              </p>
            </li>
            <li>
              <b>Bedarf überprüfen und mit aktuellem Betriebspunkt vergleichen</b>
              <p>
                Häufig ist der Bestands-Ventilator größer ausgelegt als eigentlich
                benötigt wird. Dies kann z.B. daran liegen, dass die belüfteten
                Bereiche umgenutzt wurden und keine Anpassung der Luftmenge erfolgte.
              </p>
              <p>Eine Reduzierung der Luftmenge bringt hohe Energieeinsparungen:</p>
              <ul>
                <li>10% weniger Luftmenge bedeuten ca. 28% weniger Stromverbrauch</li>
                <li>20% weniger Luftmenge bedeuten ca. 50% weniger Stromverbrauch</li>
              </ul>

              <p>
                Aus diesem Grunde lohnt es sich mindestens folgende Punkte zu prüfen:
              </p>
              <ul>
                <li>
                  Vergleich der Luftwechselraten in den versorgten Zonen mit den
                  Vorgaben. Lässt sich die Luftmenge reduzieren?
                </li>
                <li>
                  Übereinstimmung der Betriebszeiten der Anlage mit den Nutzungszeiten
                  der versorgten Zone. Läuft der Ventilator länger als eigentlich
                  erforderlich?
                </li>
                <li>
                  Wurden Gebäudeteile stillgelegt, ohne dass die Luftmenge reduziert
                  wurde?
                </li>
                <li>
                  Änderung der Anforderungen an die Luftaufbereitung: Können
                  Luftkühler ausgebaut werden, wenn eine Gebäudezone keiner Kühlung
                  mehr bedarf? Kann die Pressung reduziert werden?
                </li>
                <li>
                  Muss die Anlage immer 100% Luftmenge bringen, oder kann diese über
                  Luftqualitätssensoren o.ä. entsprechend dem Bedarf heruntergeregelt
                  werden?
                </li>
              </ul>
            </li>
            <li>
              <b
                >Auslegungs-Betriebspunkt für den neuen Ventilator mit dem Kunden
                festlegen</b
              >
            </li>
            <li>
              <b
                >Einsparpotential und Wirtschaftlichkeit mit EARNS
                abschätzen</b
              >
              <p>
                anhand der gemessenen Daten (Bestandsventilator) und des neuen
                Auslegungspunk-tes (Neuventilator).EARNS schlägt einen
                Neuventilator-Typ vor.
              </p>
            </li>
            <li>
              <b
                >Überprüfen der baulichen Einbausituation für die Konzeption des
                Neusystems</b
              >
              <p>
                Hierdurch scheiden evtl. bereits gewisse Lösungen für das Neusystem
                aus
              </p>
            </li>
            <li>
              <b
                >Anhand von Herstellersoftware (ebm-papst, Gebhardt, etc) die für den
                jeweiligen Anwendungsfall (Einbausituation, Betriebspunkt, Kosten)
                optimal geeignetste Systemlösung auswählen</b
              >
            </li>
            <li>
              <b
                >Einsatz einer stufenlosen Drehzahlregelung (VSD Variable Speed Drive)
                bzw. eines Frequenzumrichters (FU) prüfen</b
              >
              <p>
                Der Einsatz eines VSD/FU’s sollte für das neue Ventilatorsystem
                geprüft werden, da dies hohe Einsparungen bringen kann. Achtung: Nicht
                immer ist dies eine wirtschaftlich sinnvolle Maßnahme. Bei
                Anwendungen, die konstant im Maximallastbereich betrieben werden, ist
                eine Drehzahlregelung oft nicht sinnvoll. Ein FU benötigt ebenfalls
                Energie, besitzt Verluste und muss gewartet werden. Viele Fehler
                können bei der Einstellung der Parameter des FU´s gemacht werden.
                Dadurch kann der Motor ggf. nicht optimal an das Gesamtsystem
                angepasst werden
              </p>
              <p>
                Kann der Ventilator jedoch oft in Teillast laufen, so rentiert sich
                ein VSD/FU i.d.R. sehr schnell.
              </p>
              <p>
                Zu beachten: Für Systeme mit EC- oder PM-Motor ist kein FU
                erforderlich, da diese eine integrierte VSD haben.
              </p>
            </li>
            <li>
              <b
                >Kosten für die gewählte Systemlösung ermitteln, unter
                Berücksichtigung aller relevanten Faktoren wie Aufwand für Demontage,
                Umbau, Einbringung, Anschlussarbeiten, Inbetriebnahme, etc.</b
              >
            </li>
            <li><b>Angebotspreis für die gewählte Systemlösung kalkulieren</b></li>
            <li>
              <b>Finale Einsparhöhe gemäß der gewählten Systemlösung berechnen</b>
            </li>
            <li>
              <b
                >Finale Wirtschaftlichkeit (Einsparung, Rendite, Amortisation) anhand
                des Angebotspreises und der finalen Einsparhöhe ermitteln</b
              >
            </li>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Parameter der Anlage</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Anzahl</td>
                <td>
                  Anzahl der Ventilatoren des gleichen Typs und der gleichen
                  Betriebsweise.
                </td>
              </tr>
              <tr>
                <td>Luftmenge</td>
                <td>Geben Sie die zu fördernde Luftmenge in m³/h ein - min.: 1000 m³/h</td>
              </tr>
              <tr>
                <td>Gesamtdruck</td>
                <td>Gesamtdruck (statischer Gesamtdruck, bzw. stat. Druck-erhöhung) ein in Pa - min.: 1 m³/h</td>
              </tr>
              <tr>
                <td>Laufrad</td>
                <td>Auswahl des Laufrads</td>
              </tr>
              <tr>
                <td>Regelung</td>
                <td>Auswahl der Regelung. Wählen Sie bei modernen Ventilatoren „VSD Anlagenkennlinie“, wenn der Ventilator 
                  entlang der Anlagenkennlinie geregelt wird, oder „VSD Konstantdruck“ wenn der 
                  Druck konstant gehalten wird.</td>
              </tr>
              <tr>
                <td>Leistungsklasse</td>
                <td>Auswahl der Leistungsklasse in kW</td>
              </tr>
              <tr>
                <td>Polzahl</td>
                <td>Auswahl Anzahl der Pole</td>
              </tr>
              <tr>
                <td>Effizienzklasse</td>
                <td>Auswahl der Effizienzklasse</td>
              </tr>
              <tr>
                <td>Antrieb</td>
                <td>Auswahl des Antriebs</td>
              </tr>
              <tr>
                <td>Anteil Volllast</td>
                <td>Anteil Volllast</td>
              </tr>
              <tr>
                <td>Anteil 75% Teillast</td>
                <td>Anteil 75% Teillast</td>
              </tr>
              <tr>
                <td>Anteil 50% Teillast</td>
                <td>Anteil 50% Teillast</td>
              </tr>
              <tr>
                <td>Anteil 25% Teillast</td>
                <td>Anteil 25% Teillast</td>
              </tr>
              <tr>
                <td>Jährliche Betriebsstunden</td>
                <td>Anzahl an Betriebsstunden im Jahr</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungsgrundlage und Daten</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <h3>Wirkungsgrad Bestandssystem</h3>
          <p>
            EARNS rechnet beim Ventilator mit dem
            Gesamtwirkungsgrad des Ventilator-Motor-Antriebs-System. Hierfür
            werden Daten aus Ventilatorinspektionen herangezogen, welche zur
            Einschätzung der Effizienz und daraus folgenden elektrischen
            Leistungsaufnahme dienen.
          </p>
          <h4>Wirkungsgrad Neusystem</h4>
          <p>
            EARNS rechnet mit dem Gesamtwirkungsgrad des
            Ventilator-Motor-Antriebs-Neusystems. Hierfür werden die besten
            Wirkungsgrade der o.g. Ventilatorsysteme im jeweiligen
            Anwendungsbereich verwendet.
          </p>
          <p>
            Die Prüfung, welches der o.g. Neusysteme für den jeweiligen
            Anwendungsfall, Betriebspunkt und Einbausituation das günstigste
            ist, ist vom Projektbearbeiter im Rahmen der Analyse
            vorzunehmen.
          </p>
          <p>
            Betrachtet man das Effizienzsteigerungspotential, so haben die
            Maßnahmen in etwa folgende Anteile:
          </p>
          <ul>
            <li>
              Einsatz eines Ventilator-Neusystems (Verbesserung
              Gesamtwirkungsgrad) 25%
            </li>
            <li>Elektronische Drehzahlregelung (VSD/FU) 35%</li>
            <li>Bedarfsreduzierung 40%</li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Zusätzliche Informationen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
            <h2>Ventilator</h2>
            <img
              src="@/assets/help/fan/fan-system.png"
              alt="Ventilatorsystem"
            />
            <p>
              Für Europa schätzt das Fraunhofer-Institut für Systemtechnik und
              Innovationsforschung ISI, Karlsruhe, den Energieverbrauch für
              Ventilatoren auf weit über 200 Milliarden Kilowattstunden. Das
              Institut analysierte den europäischen Markt für Ventilatoren.
              Nach den Berechnungen sind bei Ventilatoren Energieeinsparungen
              bis 20 Prozent und mehr erreichbar. Die Industrie könnte somit
              jährlich etwa 2,6 Milliarden Euro an Energiekosten sparen.
            </p>

            <h3>EU-Ökodesign-Richtlinie 1253/2014</h3>
            <p>
              Am 26. November 2014 ist die neue EU-Ökodesign-Richtlinie
              1253/2014 zur Mindesteffizienz von Lüftungsanlagen in Kraft
              getreten. Demnach müssen Lüftungs- und Klimageräte in zwei
              Schritten ab 1. Januar 2016 und 1. Januar 2018
              energieeffizienter werden. Betroffen sind alle Geräte, die zur
              Lüftung von Gebäuden dienen und über eine elektrische
              Anschlussleitung über 30 W verfügen. Dabei ist es unerheblich,
              ob das Gebäude als Wohnraum oder für andere Zwecke genutzt wird.
              Geräte, die ausschließlich in explosionsgefährdeten Bereichen
              genutzt werden, sowie Geräte, die entweder in einem
              Lufttemperaturbereich über 100 °C oder unterhalb von -40 °C
              betrieben werden, sind ausgenommen.
            </p>
            <p>
              Grundsätzlich klassifiziert die Richtlinie drei Gerätekategorien
              nach dem Volumenstrom, an die unterschiedliche Anforderungen
              gestellt werden:
            </p>
            <ul>
              <li>bis 250 m³/h Volumenstrom: Wohnungslüftungsgeräte</li>
              <li>
                250 und 1.000 m³/h Volumenstrom: Lüftungsgeräte für
                Wohngebäude und Nichtwohngebäude.
              </li>
              <li>
                Über 1.000 m³/h Volumenstrom: Lüftungsgeräte für
                Nichtwohngebäude
              </li>
            </ul>
            <p>
              Im Folgenden sind die Anforderungen an Lüftungsgeräte für
              Nichtwohngebäude über 1.000 m³/h Volumenstrom zusammengestellt.
              Bei diesen Geräten differenziert die Verordnung im ersten
              Schritt nach
            </p>
            <ul>
              <li>einfachen Zuluft- oder Abluftgeräten,</li>
              <li>Geräten, die Zuluft und Abluft kombinieren.</li>
            </ul>

            <h4>Die Mindestanforderungen bei der Nicht-Wohnraum-Lüftung</h4>
            <p>
              Für einfache Zuluft- oder Abluftgeräte ist eine
              Mindest-Ventilatoreffizienz einzuhalten. Das heißt, die
              Ventilatoren im eingebauten Zustand müssen in Abhängigkeit der
              Leistungsaufnahme einen Mindestwirkungsgrad aufweisen, der
              entsprechend nach folgender Formel berechnet wird:
            </p>
            <ul>
              <li>6,2 % * ln(P) + 35 %, wenn P ≤ 30 kW und</li>
              <li>56,1 %, wenn P > 30 kW.</li>
            </ul>
            <img
              src="@/assets/help/fan/fan-power-eta.png"
              alt="Effizienzverlauf"
            />
            <figcaption>
              Abbildung: Mindesteffizienz für Ventilatoren in 2016 und 2018
            </figcaption>
            <p>
              Ist das einfache Zuluft- oder Abluftgerät mit einer Filterstufe
              versehen, wird zusätzlich der Strombedarf von Ventilator plus
              sauberem Filter im eingebauten Zustand begrenzt. Alle einfachen
              Zuluft- oder Abluftgeräte müssen ab 2016 eine elektrische
              Leistungsaufnahme von 250 Ws/m³ und ab 2018 von 230 Ws/m³ (Siehe
              auch unten SFP-Klassen) unterschreiten.
            </p>
            <p>
              Sind Zuluft und Abluft in einem Gerät kombiniert, müssen die
              Ventilatoren ab 2016 entweder mit einer mehrstufigen oder
              stufenlosen Drehzahlregelung ausgerüstet werden. Ab 2018 ist
              darüber hinaus eine Filterüberwachung vorgeschrieben, die
              automatisch den Filtertausch mittels Warnsignal anmahnt.
            </p>
            <p>
              Bereits ab 2016 müssen alle kombinierten Zuluft- und
              Abluftgeräte mit mehr als 1.000 m³/h Volumenstrom über eine
              Wärmerückgewinnung verfügen. Für diese gelten folgende
              Mindestanforderungen an den trockenen Wirkungsgrad bei
              ausgeglichenem Volumenstrom (gem. EN 308 bei +5/+25 °C):
            </p>
            <p>
              Ab 2016 für Kreislaufverbundsysteme (KVS) 63 %, für alle anderen
              Wärmerückgewinnungssysteme (Plattenwärmeübertrager oder
              Rotationswärmeübertrager) 67 %. Ab 2018 für KVS 68 %, für alle
              anderen Wärmerückgewinnungssysteme 73 %.
            </p>

            <h3>SFP-Klassen</h3>
            <p>
              Klassifiziert werden Ventilatoren in Specific Fan Power-, kurz
              SFP-Klassen. Diese Klassen geben an, welche elektrische Leistung
              ein Ventilator pro Volumenstrom benötigt. (s.u.) Nach der
              aktuellen Energieeinsparverordnung (EnEV 2009) sind Neuanlagen
              mit mehr als 4000 m³/h nur noch mit höchstens SFP-4 vorgesehen.
            </p>

            <div>$$SFP = P/V\ oder\ SFP= \delta p / \eta$$</div>
            <div align="center">mit</div>
            <div>\[P=V*\delta p/\eta\]</div>
            <div>
              \[\eta_\mathrm{System}=\eta_\mathrm{FU}*\eta_\mathrm{Motor}*\eta_\mathrm{Antrieb}*\eta_\mathrm{Ventilator}
              \]
            </div>
            <div>\(\eta_\mathrm{System}\) = Systemwirkungsgrad</div>
            <div>
              \(\eta_\mathrm{FU}\) = Wirkungsgrad des Frequenzumrichters
            </div>
            <div>
              \(\eta_\mathrm{Motor}\) = Wirkungsgrad des Antriebsmotors
            </div>
            <div>
              \(\eta_\mathrm{Antrieb}\) = Wirkungsgrad des Antriebs
              (Keilriemen 92%-97%, Flachriemen ca. 98%)
            </div>
            <div>
              \(\eta_\mathrm{Ventilator}\) = Wirkungsgrad des Ventilators
            </div>

            <p>SFP-Klassen für Ventilatoren:</p>
            <table>
              <thead>
                <tr>
                  <th>Kategorie</th>
                  <th>spezifische Ventilatorleistung [Ws/m³]</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>SFP-1</td>
                  <td>&lt;500</td>
                </tr>
                <tr>
                  <td>SFP-2</td>
                  <td>500&ndash;750</td>
                </tr>
                <tr>
                  <td>SFP-3</td>
                  <td>750&ndash;1250</td>
                </tr>
                <tr>
                  <td>SFP-4</td>
                  <td>1250&ndash;2000</td>
                </tr>
                <tr>
                  <td>SFP-5</td>
                  <td>2000&ndash;3000</td>
                </tr>
                <tr>
                  <td>SFP-6</td>
                  <td>3000&ndash;4500</td>
                </tr>
                <tr>
                  <td>SFP-7</td>
                  <td>&gt;4500</td>
                </tr>
              </tbody>
            </table>

            <h3>Optimierungsmöglichkeiten</h3>
            <p>
              Folgende Maßnahmen haben wesentlichen Einfluss auf die
              Energiekosten eines Ventilators:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Kategorie</th>
                  <th>Beschreibung</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Motor</td>
                  <td>
                    Austausch des Ventilatorsystems inkl. Motor und Antrieb
                  </td>
                </tr>
                <tr>
                  <td>Dimensionierung</td>
                  <td>
                    Anpassen der Ventilatorgröße an den tatsächlichen Bedarf
                  </td>
                </tr>
                <tr>
                  <td>Regelung / Steuerung</td>
                  <td>
                    Einsatz einer stufenlosen Drehzahlregelung (Variable Speed
                    Drive VSD)
                  </td>
                </tr>
                <tr>
                  <td>Betriebszeit</td>
                  <td>
                    Anpassung der Betriebszeit an die tatsächlichen
                    Nutzungszeiten
                  </td>
                </tr>
                <tr>
                  <td>Verbraucher</td>
                  <td>
                    Systemoptimierung zur Reduzierung des Bedarfs (Luftmenge,
                    Druck)
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              Mit der auführlichen Ventilatorberechnung in EARNS lassen sich
              folgende Bestandssysteme betrachten:
            </p>
            <ul>
              <li>
                Ventilatoren mit vorwärtsgekrümmten Schaufeln (Trommelläufer)
              </li>
              <li>
                Ventilatoren mit rückwärtsgekrümmten Schaufeln (Radialläufer)
              </li>
            </ul>

            <h3>Bestands-Ventilator-Systeme</h3>
            <p>
              Bei den Bestandssystemen sind zwei typische Ventilatorsysteme
              hinterlegt:
            </p>

            <div class="row">
              <div class="column">
                <img
                  src="@/assets/help/fan/trommel.png"
                  alt="Trommelläufer"
                  style="width:84%"
                />
              </div>
              <div class="column">
                <img
                  src="@/assets/help/fan/backwards.png"
                  alt="Rückwärtsgekrümmter Ventilator"
                  style="width:100%"
                />
              </div>
            </div>
            <figcaption>
              Abbildung: Trommelläufer im Gehäuse (links) und Rückwärtsgekrümmter
              Ventilator im Gehäuse (rechts)
            </figcaption>

            <h3>Neusysteme</h3>
            <p>
              Nachfolgend sind mehrere Neusysteme beschrieben, die derzeit die
              höchsten Systemwirkungsgrade erreichen. Diese wurden der
              EARNS-Kalkulation zugrunde gelegt.
            </p>

            <img src="@/assets/help/fan/direkt.png" alt="Direktantrieb" />
            <figcaption>
              Abbildung: Direktangetriebener Radial-Ventilator mit EC-Motor (RadiPac,
              ebm-papst) Einsatzbereich: 1.200 bis 32.000 m³/h ; bis 2.000 Pa
            </figcaption>

            <p>
              Im RadiPac werden alle Bauteile eines herkömmlichen Ventilators
              vereint. Das Laufrad sitzt unmittelbar auf der Welle des Motors,
              und die Steuerungstechnik ist im Motorelement integriert. Durch
              die Kompaktheit des Ventilators kann er auch an Orten mit
              begrenztem Platz zum Einsatz kommen. Er muss nur noch eingebaut
              werden und ist danach betriebsbereit.
            </p>
            <p>
              Bedarf es eines höheren Volumenstroms, als ein Ventilator ihn
              erbringen kann, können mehrere RadiPac-Ventilatoren zu einer so
              genannten „Fan-Wall“ zusammen gebaut werden. Hierbei werden die
              Ventilatoren parallel, 2-dimensional angeordnet, um den
              Volumenstrom zu erhöhen.
            </p>

            <img src="@/assets/help/fan/fan-wall.png" alt="Ventilatorwand" />
            <figcaption>
              Abbildung: RadiPac Fan-Wall, bestehend aus 3x3 Ventilatoren
            </figcaption>

            <img
              src="@/assets/help/fan/power-spec.png"
              alt="Ventilatorsystem"
            />
            <figcaption>
              Abbildung: Leistungsbereiche verschiedener Baugrößen des RadiPac
            </figcaption>

            <img
              src="@/assets/help/fan/direkt-double.png"
              alt="Zweiseitig saugend"
            />
            <figcaption>
              Abbildung: Direktangetriebener Radial-Ventilator mit EC-Motor, zweiseitig
              saugend (RadiFit, ebm-papst) Einsatzbereich: 1.500 bis 10.000
              m³/h ; bis 1.500 Pa
            </figcaption>
            <p>
              Der RadiFit-Ventilator integriert alle Komponenten eines
              konventionellen Ventilators. An einem innen liegenden,
              hocheffizienten EC-Motor ist das Radiallaufrad unmittelbar
              montiert, wodurch etwaige Antriebsverluste wegfallen. Die
              Steuerungseinheit des Motors ist an der Außenseite des
              Spiralgehäuses angebracht und ermöglicht zusammen mit dem Motor
              ein sehr gutes Teillastverhalten von 1:10 bei weiterhin hohen
              Wirkungsgraden.
            </p>
            <img src="@/assets/help/fan/kompakt.png" alt="Kompakt saugend" />
            <figcaption>
              Abbildung: Veringerung der Einzellteile
            </figcaption>
            <p>
              Durch die Kompaktheit des Ventilators verringern sich die
              Wartungs- und Instandhaltungskosten drastisch, wodurch sich ein
              Austausch eines Bestandsventilators bereits nach wenigen Jahren
              amortisiert und durch marktübliche Einbaumaße ist der Austausch
              in der Regel ohne Probleme durchzuführen.
            </p>
            <img
              src="@/assets/help/fan/performance-radfit.png"
              alt="Leistungsbereiche RadFit"
            />
            <figcaption>
              Abbildung: Leistungsbereiche verschiedener Baugrößen des RadiFit
            </figcaption>

            <img
              src="@/assets/help/fan/direkt-kompakt.png"
              alt="Leistungsbereiche RadFit"
            />
            <figcaption>
              Abbildung: Direktangetriebener Radialventilator (RLM E6, Gebhardt) mit
              IE3-Motor und Frequenzumformer - Einsatzbereich: 550 bis 60.000
              m³/h ; bis 1.800 Pa
            </figcaption>
            <p>
              Die Typbeschreibung xxx-E6 bedeutet, dass es sich um die
              Evo-Reihe von Nicotra-Gebhardt handelt, die sich durch besonders
              hohe Wirkungsgrade auszeichnet. Die Wirkungsgrade liegen je nach
              Ventilatorgröße bei 65 bis 70%. Die Baureihe beinhaltet ein
              Komplett-Einbaumodul mit Motor-Laufrad-Einheit, Tragplatte mit
              Einströmdüse und Motorbock auf einem Grundrahmen.
            </p>

            <img
              src="@/assets/help/fan/keil-radial.png"
              alt="Keilriemengetriebener Radialventilator"
            />
            <figcaption>
              Abbildung: Keilriemengetriebener Radialventilator (ADH, RDH, RZR Gebhardt)
              zweiseitig saugend mit IE3-Motor und Frequenzumformer -
              Einsatzbereich: 600 bis 300.000 m³/h ; bis 3.500 Pa
            </figcaption>
            <p>
              Riemengetriebene Radialventilatoren zeichnen sich weitgehend
              dadurch aus, dass sie sowohl hohe Drücke, als auch große
              Volumenströme ermöglichen können, wodurch sie sich besonders für
              den Betrieb in großen Anlagen eignen, in denen hohe
              Druckverluste vorliegen. Dabei ist ein Vorteil, dass die
              Ventilatoreinheit auf Grund der Trennung von Antrieb und
              Ventilator durch einen Riementrieb leicht an veränderte
              Betriebsbedingungen angepasst werden kann, zum Beispiel indem
              der Motor getauscht wird. Durch die Dezentralität entstehen aber
              erhöhte Wartungs- und Verschleißkosten, ebenso wie zusätzliche
              Verluste durch den Riementrieb, wodurch der Wirkungsgrad
              verringert wird. Im Allgemeinen steigt der Wirkungsgrad mit der
              Größe des Ventilators an.
            </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Quellen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul>
            <li>Nicotra Gebhardt GmbH</li>
            <li>ebmpapst Gruppe</li>
            <li>AL-KO</li>
            <li>
              ILK Dresden Fachbericht ILK-B-31-13-3839, Auftraggeber: Danfoss
              GmbH
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>
        Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>
  </div>
</template>

<script>

export default {
  name: "Fan2Help",
  mounted() {
    if (window.MathJax) {
      window.MathJax.typeset();
    }
  }
};
</script>

<style scoped></style>
