<template>
  <tr class="action">
    <td :class="{ 'pl-10': indent }" @click="editAction(action)">
      {{ action.name }}
    </td>
    <td @click="editAction(action)">
      {{ checkMethodTexts(action) }}
      <v-tooltip v-if="action.errors.length" slot="append" bottom color="error">
        <template v-slot:activator="{ on }">
          <v-icon color="error" class="ml-2" v-on="on">mdi-alert</v-icon>
        </template>
        <span v-for="error in action.errors" :key="error">
          {{ error }}<br />
        </span>
      </v-tooltip>
    </td>
    <td class="text-right" @click="editAction(action)">
      <v-tooltip top color="white">
        <v-simple-table>
          <tr>
            <th class="text-left">Energiekosten:&nbsp;</th>
            <td class="text-right">
              {{ $filters.number(-action.energyCostSavings) }} €
            </td>
          </tr>
          <tr>
            <th class="text-left">B&I:&nbsp;</th>
            <td class="text-right">
              {{ $filters.number(-action.deltaOperationalCost) || "-" }} €
            </td>
          </tr>
        </v-simple-table>

        <template #activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{
            $filters.number(action.savings)
          }}</span>
        </template>
      </v-tooltip>
    </td>
    <td class="text-right" @click="editAction(action)">
      {{ $filters.number(action.co2Savings/1000) || "-" }}
    </td>
    <td class="text-right" @click="editAction(action)">
      {{ $filters.number(action.invest) || "-" }}
    </td>
    <td
      class="text-right"
      v-for="eid in energies"
      @click="editAction(action)"
      :key="eid.id"
    >
      <template v-if="action.errors.length === 0">
        <template v-if="action.energySavings && action.energySavings[eid]">
          {{ $filters.number(action.energySavings[eid]) }}
        </template>
        <template v-else>-</template>
      </template>
    </td>
    <td>
      <span style="white-space: nowrap">
        <v-btn
          :disabled="action.errors.length != 0"
          :href="docxDownloadHref"
          color="primary"
          icon
          title="Kurzbericht dieser Maßnahme als Word Export"
        >
          <v-icon>mdi-file-word</v-icon>
        </v-btn>
        <v-btn
          icon
          @click.stop="editAction(action)"
          title="Maßnahme bearbeiten"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </span>
    </td>
  </tr>
</template>

<script>
export default {
  name: "ProjectCalcActionRow",
  props: {
    action: { type: Object, required: true },
    energies: { type: Array, required: true },
    indent: { type: Boolean, default: false },
    methodTexts: null
  },

  computed: {
    docxDownloadHref() {
      return `/api/v1/actions/${this.action.id}/action_report/`;
    }
  },
  methods: {
    checkMethodTexts(action) {
      if (this.methodTexts != null) {
        return this.methodTexts[action.type];
      }
    },
    editAction(action) {
      this.$router.push({
        name: "Action",
        params: { actionId: action.id }
      });
    }
  }
};
</script>
<style scoped>
tbody .action {
  cursor: pointer;
}
</style>
