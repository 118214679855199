<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h2>Beleuchtung</h2>

    <v-expansion-panels accordion class="mt-5">
         <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Übergreifend</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Zone</td>
                <td>Auswahl der Gebäudezone.</td>
              </tr>
              <tr>
                <td>Fläche</td>
                <td>Eingabe der Fläche.</td>
              </tr>
              <tr>
                <td>Austausch</td>
                <td>Auswahl der Austauschoption.</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Parameter der Anlage</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Manuelle Brenndauer</td>
                <td>Option manuelle Brenndauer.</td>
              </tr>
              <tr>
                <td>Brenndauer</td>
                <td>Eingabe der Brenndauer, wenn die Berechnete von der Norm abweicht.</td>
              </tr>
              <tr>
                <td>Leuchte</td>
                <td>Auswahl des Leuchtentyps.</td>
              </tr>
              <tr>
                <td>Anzahl</td>
                <td>
                    Anzahl der Leuchten des gleichen Typs und der gleichen Betriebsweise.
                </td>
              </tr>
              <tr>
                <td>Leistung je Lampe</td>
                <td>Auswahl der Leistung einer Lampe.</td>
              </tr>
              <tr>
                <td>Lampen je Leuchte</td>
                <td>Auswahl des Alters der Lichtanlage.</td>
              </tr>
              <tr>
                <td>Vorschaltgerät</td>
                <td>Auswahl des Alters der Lichtanlage.</td>
              </tr>
              <tr>
                <td>Vorschaltgeräte je Leuchte</td>
                <td>Auswahl des Alters der Lichtanlage.</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungsgrundlage und Daten</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h3>Leuchten und Lampen</h3>
          <p>
            Mit Hilfe der Methode 2 der Beleuchtung können Sie eine detaillierte Berechnung 
            durchführen. Geben Sie eine Bezeichnung für die zu betrachtende 
            Zone und die dazugehörigen erforderlichen Informationen ein. Sie haben die Auswahl 
            verschiedener Zonen, von denen die Brenndauer (diese kann auch manuell angepasst 
            werden) und die verschiedenen Beleuchtungsmöglichkeiten abhängig sind.  
          </p>
          <p>
            Es besteht die Auswahl zwischen einem Leuchtmittel- oder einem Leuchtensystemtausch. 
            Bei einem Leuchtmitteltausch wird nur das Leuchtmittel (die Lampe) ausgetauscht, 
            bei einem Leuchtensystemtausch wird die komplette Leuchte (z.B. Deckenleuchte) 
            inkl. Leuchtmittel getauscht. 
          </p>
          <p>
            Die Auswahl der Leuchte wird beschränkt mit der Art des Austauschs. Verschiedene 
            Vorschaltgeräte können für eine Bestands- oder Austauschvariante ausgewählt werden. 
            Im Bestand stehen folgende Vorschaltgeräte zur Auswahl. 
          </p>
          <p>Vorschaltgeräte beim Leuchtmittel-/Leuchtensystemtausch:</p>
          <ul>
            <li>
              „-„ – kein Vorschaltgerät
            </li>
            <li>
              EVG – Elektronisches Vorschaltgerät
            </li>
            <li>
              VVG – Verlustarmes Vorschaltgerät
            </li>
            <li>
              KVG – Konventionelles Vorschaltgerät
            </li>
          </ul>
          <p>
            Für die Austauschvarianten bei einem Leuchtensystemtausch stehen folgende 
            Vorschaltgeräte zur Auswahl.
          </p>
          <p>
            Zusätzliche Vorschaltgeräte bzw. LED Treiber beim Leuchtensystemtausch:
          </p>
          <ul>
            <li>
              HFP – schaltbares EVG (elektronisches Vorschaltgerät)
            </li>
            <li>
              HFR – regelbares EVG
            </li>
            <li>
              HFD – DALI regelbares EVG
            </li>
            <li>
              PSD – DALI regelbarer LED Treiber
            </li>
            <li>
              PSU – schaltbarer LED Treiber (Vorschaltgerät)
            </li>
            <li>
              PSR – regelbares, elektronisches Betriebsgerät
            </li>
          </ul>
          <p>
            Die Anzahl der Vorschaltgeräte je Leuchte steht von 1 bis zur Anzahl der 
            Lampen je Leuchte zur Verfügung. Beispiel: Bei einer 3-flammigen Leuchte 
            stehen 1 bis 3 Vorschaltgeräte je Leuchte zu Auswahl. Bitte zählen Sie die 
            Vorschaltgeräte durch, nicht immer ist die Anzahl der Lampen je Leuchte 
            gleich der Anzahl der Vorschaltgeräte!
          </p>
          <p>
            Verschiedene Steuerungsmöglichkeiten werden in der Applikation zur Auswahl 
            gestellt. Es kann ausgewählt werden, ob schon eine Steuerungsvariante im 
            Bestand vorhanden ist oder ob eine Steuerung für den Austausch vorgesehen 
            ist. Je nach ausgewählter Steuerung wird die Brenndauer mit einem Faktor 
            multipliziert. 
          </p>
          <p>
            Folgende Steuerungsmöglichkeiten stehen insgesamt zur Auswahl:
          </p>
          <ul>
            <li>
              kein Präsenzmelder, keine Tageslichtabschaltung	1
            </li>
            <li>
              mit Präsenzsteuerung 0,75
            </li>
            <li>
              mit Tageslichtabschaltung 0,7
            </li>
            <li>
              mit Präsenzsteuerung und Tageslichtabschaltung 0,6
            </li>
          </ul>
          <p>
            Nicht immer stehen alle Steuerungsmöglichkeiten zur Auswahl, sie sind 
            abhängig von dem Vorschaltgerät, welches eingesetzt werden soll.
          </p>
          <p>
            Die regelbaren EVGs bzw. LED Treiber lassen sich sowohl mit 
            einer Präsenzsteuerung als auch mit einer Tageslichtsteuerung koppeln:
          </p>
          <ul>
            <li>
              HFR – regelbares EVG
            </li>
            <li>
              HFD – DALI regelbares EVG
            </li>
            <li>
              PSD – DALI regelbarer LED Treiber
            </li>
            <li>
              PSR – regelbarem, elektronischen Betriebsgerät
            </li>
          </ul>
          <p>
            Die schaltbaren EVGs bzw. LED Treiber sind nur für eine Präsenzsteuerung geeignet:
          </p>
          <ul>
            <li>
              HFP – schaltbares EVG (elektronisches Vorschaltgerät)
            </li>
            <li>
              PSU – schaltbarer LED Treiber (Vorschaltgerät)
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Hinterlegte Austauschlösungen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <p>
            In diesem Abschnitt erhalten Sie einen Überblick über die hinterlegten 
            Austauschlösungen. Sie haben beim Austausch die Möglichkeit auszuwählen, 
            ob Sie nur die Leuchtmittel oder das gesamte Leuchtensystem (Leuchte 
            inkl. Lampe) austauschen möchten. Für beide Möglichkeiten werden die 
            hinterlegten Technologien erläutert. Bei den hinterlegten 
            handelt es sich um einen Auszug der gängigen Lösungen. 
          </p>
          <h2>Leuchtmitteltausch</h2>
          <p>
            Bei einem Leuchtmitteltausch wir nur das Leuchtmittel getauscht. 
            Die Leuchte bleibt dabei unverändert. Folgende Leuchtmittel sind für den Austausch 
            hinterlegt:
          </p>
          <p>
            Bestand:
          </p>
          <ul>
            <li>
              NV-Halogensport
            </li>
            <li>
              T5-Leuchtstofflampe
            </li>
            <li>
              T8-Leuchtstofflampe
            </li>
          </ul>
          <p>
            Geplant:
          </p>
          <ul>
            <li>
              LED
            </li>
            <li>
              T8 LED-Leuchtstofflampe
            </li>
            <li>
              T5 Power Saver Set
            </li>
            <li>
              T5 Leuchtstofflampe
            </li>
            <li>
              T5 TLD-Eco
            </li>
          </ul>
          <h3>NV-Halogenspot</h3>
          <img
            src="@/assets/help/lighting/image004.jpg"
            alt="NV-Halogenspot"
          />
          <figcaption>
            Abbildung: NV-Halogenspot
          </figcaption>
          <h2>Leuchtsystemtausch</h2>
          <p>
            Anders als beim Leuchtmitteltausch wird beim Leuchtensystemtausch 
            die Leuchte inkl. Leuchtmittel getauscht. Je nach Anwendungsfall 
            stehen verschiedene Möglichkeiten zur Verfügung. Folgende 
            Austauschkombinationen sind für den Leuchtmitteltausch in EANRS hinterlegt:
          </p>
          <ul>
            <li>
              T5-Anbauleuchte – LED Anbauleuchte
            </li>
            <li>
              T8-Anbauleuchte – T5-Anbauleuchte
            </li>
            <li>
              T8-Einlegeleuchte – T5-Einlegeleuchte/LED-Einlegeleuchte
            </li>
            <li>
              Downlight mit Kompaktleuchtstoffröhren – LED-Downlight
            </li>
            <li>
              Einbaustrahler (Spots) – LED-Einbaustrahler (LED-Spots)
            </li>
            <li>
              Wand- und Deckenleuchte – LED-Wand- und Deckenleuchte
            </li>
            <li>
              Konv. Feuchtraumleuchte – LED-Feuchtraumleuchte
            </li>
            <li>
              Konv. Lichtleiste – LED-Lichtleiste
            </li>
            <li>
              Konv. Lichtband – LED-Lichtband
            </li>
            <li>
              Konv. Hallenreflektorleuchte – LED-Hallenleuchte
            </li>
            <li>
              Außenleuchte – LED-Außenleuchte
            </li>
          </ul>
          <img
            src="@/assets/help/lighting/image004.jpg"
            alt="T8-Leuchtstoffröhre"
          />
          <figcaption>
            Abbildung: T8-Leuchtstoffröhre 
          </figcaption>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Zusätzliche Informationen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <h2>Lichttechnische Grundbegriffe </h2>
          <img
            src="@/assets/help/lighting/lighting.png"
            alt="Hängelampe"
          />
          <figcaption>
            Abbildung: Beispiel Hängelampe im Büro
          </figcaption>
          <h3>Leuchte</h3>
          <p>
            Der gesamte Beleuchtungskörper inklusive aller für Befestigung, Betrieb
            und Schutz der Lampe notwendigen Komponenten ist die &bdquo;Leuchte&ldquo;
            &ndash; im Volksmund auch gerne als &bdquo;Lampe&ldquo; bezeichnet. Die
            Leuchte schützt die Lampe, verteilt und lenkt deren Licht, verhindert,
            dass es blendet.
          </p>
          <p>Leuchten werden unterschieden nach</p>
          <ul>
            <li>
              Art der eingesetzten Lampen (für Energiesparlampen, für
              Leuchtstofflampen, für Entladungslampen),
            </li>
            <li>
              der Anzahl der Lampen (ein-, zweilampig bzw. ein-, zweiflammig, usw.),
              nach dem Einsatzbereich (Innenleuchten, Außenleuchten),
            </li>
            <li>dem Einsatzort (Schreibtischleuchten, Badleuchten usw.),</li>
            <li>
              der Schutzart (Leuchten für trockene, feuchte und staubige Räume),
            </li>
            <li>
              der Bauart (offene Leuchten, geschlossene Leuchten, Reflektorleuchten,
              Spiegelleuchten, Rasterleuchten, Wannenleuchten, Scheinwerfer),
            </li>
            <li>der Montageart (Wand-, Decken-, Pendel-, oder Handleuchten),</li>
            <li>
              dem Verwendungszweck (technische Leuchten, Wohnraumleuchten, dekorative
              Leuchten oder Effektleuchten) oder
            </li>
            <li>
              nach der Beleuchtungsaufgabe (Arbeitsplatzleuchten, Straßenleuchten).
            </li>
          </ul>

          <h3>Lampe </h3>
          <p>
            Ohne Lampe kein Licht: &bdquo;Lampe&ldquo; bezeichnet die technische
            Ausführung einer künstlichen Lichtquelle. Die Lampe wandelt elektrische
            Energie in Licht um. Die Lampe wird in der Leuchte eingesetzt, die das
            Licht der Lampe verteilt, lenkt und vor Blendung schützt.
          </p>
          <p>
            Lampen gibt es in vielen Ausführungen. Sie unterscheiden sich durch die
            Art der Lichterzeugung, ihre geometrische Form und ihre lichttechnischen
            Eigenschaften. Im Wohnbereich werden zum Beispiel Halogenlampen,
            Energiesparlampen und Leuchtstofflampen eingesetzt.
          </p>

          <h3>Vorschaltgerät</h3>
          <p>
            Für den Betrieb von Entladungslampen &ndash; zum Beispiel
            Leuchtstofflampen &ndash; sind Vorschaltgeräte (VGs) erforderlich. Denn
            nach der Zündung dieser Lampen erfolgt eine lawinenartige Ionisierung der
            enthaltenen Edelgase oder Metalldämpfe, die zu einem starken Anstieg des
            Lampenstroms führt. Er würde die Lampe nach kurzer Zeit zerstören. Daher
            begrenzt ein VG den Lampenstrom, auch im Betrieb.
          </p>
          <p>
            Zu unterscheiden sind konventionelle (KVGs) und verlustarme (VVGs)
            Vorschaltgeräte sowie elektronische (EVGs) Vorschaltgeräte. EVG für
            Leuchtstofflampen gibt es in dimmbarer und nicht dimmbarer Ausführung. Bei
            Energiesparlampen sind VG im Schraubsockel integriert, ansonsten sind sie
            Bestandteil der Leuchte.
          </p>

          <h3>Lichtstrom in Lumen (lm) </h3>
          <p>
            Der Lichtstrom &Phi; &ndash; gemessen in Lumen (lm) &ndash; ist die
            Lichtleistung einer Lampe. Er beschreibt die von der Lichtquelle in alle
            Richtungen abgestrahlte Leistung im sichtbaren Bereich. Eine Glühlampe hat
            etwa 1.400 Lumen, eine zweiseitig gesockelte Halogenlampe bis zu 44.000
            Lumen. Der Lichtstrom einzelner LEDs beträgt zwischen 18 und 30 Lumen.
          </p>
          <p>
            Gerade im Bereich der LEDs ist die Angabe des Lichtstroms von großer
            Bedeutung. Oftmals werden LEDs auf den Markt gebracht, die eine geringere
            Leistungsaufnahme im Vergleich zu Konkurrenzprodukten haben, jedoch auch
            einen viel geringeren Lichtstrom. Dadurch wird ein
            &bdquo;schwächeres&ldquo; Licht erzeugt. Es ist daher wichtig, diese
            Angabe neben der Leistungsaufnahme ebenfalls zu vergleichen.
          </p>
          <img src="@/assets/help/lighting/lighting_lichtstrom.png" />
          <figcaption>
            Abbildung: Visualisierung des Lichtstroms 
          </figcaption>
          <h3>Beleuchtungsstärke in Lux (lx) </h3>
          <p>
            Die Beleuchtungsstärke (Kurzzeichen: E, Maßeinheit: Lux) definiert, wie
            viel Licht &ndash; lichttechnisch genauer wie viel Lichtstrom (in Lumen)
            &ndash; auf eine bestimmte Fläche fällt: Sie beträgt ein Lux, wenn der
            Lichtstrom von einem Lumen einen Quadratmeter Fläche gleichmäßig
            ausleuchtet. Ein Beispiel: Eine normale Kerzenflamme hat im Abstand von
            einem Meter ungefähr ein Lux Beleuchtungsstärke.
          </p>
          <p>
            Die Beleuchtungsstärke wird mit einem Luxmeter auf horizontalen und
            vertikalen Flächen gemessen. Sie beschreibt allerdings nicht präzise den
            Helligkeitseindruck eines Raumes, da dieser wesentlich von den
            Reflexionseigenschaften der Raumflächen abhängt. So erscheint ein weißer
            Raum bei gleicher Beleuchtungsstärke heller als ein dunkler. Siehe auch:
            halbzylindrische Beleuchtungsstärke.
          </p>
          <p>
            Weil die Lichtverteilung einer normalen Beleuchtung nicht absolut
            gleichmäßig ist, geben Normen in der Regel die mittlere Beleuchtungsstärke
            an. Sie berücksichtigt die Ungleichmäßigkeiten rechnerisch: Die mittlere
            Beleuchtungsstärke ist das gewichtete arithmetische Mittel aller
            Beleuchtungsstärken im Raum.
          </p>
          <div class="row">
            <div class="column">
              <img
                src="@/assets/help/lighting/lighting_beleuchtungsstaerke.png"
                alt="Beläuchtungstärke"
                style="width:75%"
              />
            </div>
            <div class="column">
              <img
                src="@/assets/help/lighting/lighting_beleuchtungsstaerke_ax.png"
                alt="Beleuchtungsachsen"
                style="width:75%"
              />
            </div>
          </div>
          <figcaption>
            Abbildung: Beleuchtungsstärke (links) und gewichtete arithmetische Mittel aller Beleuchtungsstärken im Raum (rechts)
          </figcaption>

          <h3>Lichtstärke in Candela (cd) </h3>
          <p>
            Die Lichtstärke (Kurzzeichen: I) ist der Teil des Lichtstroms, der in eine
            bestimmte Richtung strahlt. Sie wird in Candela (cd) gemessen.
          </p>
          <p>
            Die räumliche Verteilung der Lichtstärke (Lichtstärkeverteilung)
            charakterisiert die Lichtausstrahlung von Leuchten und Reflektorlampen.
            Die Lichtstärkeverteilung wird grafisch in Form von Kurven dargestellt,
            den Lichtstärkeverteilungskurven (LVK) &ndash; siehe Lichtstärkeverteilung
          </p>
          <img src="@/assets/help/lighting/lighting_lichtstärke.png" />
          <figcaption>
            Abbildung: Visualisierung der Lichtstärke
          </figcaption>
          <h3>Lichtstärkeverteilung </h3>
          <p>
            Die Lichtstärkeverteilung beschreibt die räumliche Verteilung der
            Lichtstärke. Form und Symmetrie der Lichtstärkeverteilung kennzeichnen
            tief- und breitstrahlende bzw. symmetrisch und asymmetrisch strahlende
            Leuchten oder Reflektorlampen.
          </p>
          <p>
            Zur Darstellung dient die Lichtstärkeverteilungskurve (LVK): Sie entsteht,
            wenn die in Polarkoordinaten dargestellten Lichtstärken einer Leuchte oder
            Reflektorlampe in ihren verschiedenen Ausstrahlungsrichtungen miteinander
            zu einem Kurvenzug verbunden werden.
          </p>
          <img
            src="@/assets/help/lighting/lighting_lichtstaerkeverteilung.png"
          />
          <figcaption>
            Abbildung: Visualisierung der Lichtstärkeverteilung
          </figcaption>
          <h3>
            Lichtausbeute in Lumen/Watt (lm/W) 
          </h3>
          <p>
            Die Lichtausbeute ist das Maß für die Wirtschaftlichkeit einer Lampe. Sie
            sagt aus, wie viel Lichtstrom in Lumen (lm) pro Watt (W) ihrer
            Leistungsaufnahme eine Lampe erzeugt. Je höher das Verhältnis Lumen/Watt,
            desto besser setzt eine Lampe die eingebrachte Energie in Licht um. Einige
            Beispiele:
          </p>
          <ul>
            <li>Allgebrauchsglühlampe 12 lm/W,</li>
            <li>Halogenlampe 20 lm/W,</li>
            <li>Energiesparlampe 60 lm/W,</li>
            <li>stabförmige Leuchtstofflampe 90 lm/W</li>
            <li>LEDs 100-120 lm/W.</li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Quellen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul>
            <li>
              Die Definitionen und Abbildungen des Kapitels 4 sind der Webseite
              <a href="http://www.licht.de">http://www.licht.de</a> entnommen. Dort
              erhalten Sie auch weiterführende Begriffserklärungen, etc.
            </li>
            <li>
              Weitere Informationen über die verschiedenen Austauschlösungen erhalten Sie auf 
              <a href="http://www.lighting.philips.de">http://www.lighting.philips.de</a>.
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Lighting2Help"
};
</script>

<style scoped>
</style>