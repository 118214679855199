<template>
  <div>
    <h2>Neue Maßnahme</h2>
    <p>
      Die Hilfe beinhaltet nützliche Information über Eingabe, Berechnung und
      Grundlagen der Maßnahmen.
    </p>
    <p>Die Eingabe erfolgt über Eingabefelder oder Menüoptionen.<br /></p>
    <table>
      <thead>
        <tr>
          <th>Parameter</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Methode</td>
          <td>Methode der Maßnahme oder Anglagenart</td>
        </tr>
        <tr>
          <td>Maßnahmen Name</td>
          <td>Name einzelner Maßnahmen</td>
        </tr>
        <tr>
          <td>Gruppe</td>
          <td>Hinzufügen zu einer Gruppierung mehrerer Maßnahmen
            <router-link :to="{ name: 'ProjectDashboard'}">
              Neue Gruppe erstellen.
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen Methode berechnet werden. 
      Die überschlägigen Methoden benötigen weniger Daten und sind somit schneller zu untersuchen. 
      Die genormten / wissenschaftlichen Methoden bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>Folgende Punkte sind zu beachten:</p>
    <dl>
      <dt><strong>Überschlägige Methoden:</strong></dt>
      <ul>
      <li>
        Ziel ist es, mit geringem Aufwand für
        Begehung und Datenaufnahme einen Überblick über die vorhandenen
        Energieeinsparpotentiale einer Liegenschaft zu erhalten. Es wird für 
        die Berechnung dieser Methode, aufgrund der geringen Anzahl an Parametern
        ist mit einer Genauigkeit von +- 15% zurechnen.
      </li>
      <li>
        Bei der Verwendung dieser Methode sollte beachtet werden, dass die Anlagenparameter
        stark vereinfacht sind. Deshalb wird bei einer ungewöhnlich niedrigen oder hohen Einsparung
        empfohlen Ihre Maßnahme mit der ausführlichen Methode gegenzurechnen.
      </li>
      </ul>
      <dt><strong>Detaillierte Methoden:</strong></dt>
      <ul>
      <li>
        Ziel ist es, mit einer detaillierten
        Begehung und Datenaufnahme eine genaue Berechnung der vorhandenen
        Energieeinsparpotentiale einer Liegenschaft zu erhalten.
      </li>
      <li>
        Werden vom Kunden im Rahmen der Potentialanalyse keine detailliertere
        Angaben geliefert, so wird die überschlägige Methode für die
        Durchführung der Potentialanalyse empfohlen.
      </li>
      </ul>
    </dl>
    <v-footer class="mt-10">
      <small>
        Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "NewMethod",
};
</script>

<style scoped></style>
