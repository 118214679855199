<template>
  <div>
    <h2>Projekte</h2>
    <p>
      Willkommen zu den Projektdetails. Bei der Neuerstellung eines Projekts werden hier die 
      Projektinformationen eingegeben. Um diese abzuspeichern muss mindestens die Projektbezeichnung
      eingegeben sein. Außerdem müssen die historischen Energieverbrauchsdaten für die Analyse eingetragen sein.
      Zusätzlich kann das Projekt einer Branche sowie Kategorie zugewiesen werden und hinterlegen Sie die 
      Bruttogrundfläche des zu betrachtenden Gebäudes. Die Projektdaten beziehen sich auf den Projektstandort, 
      die Kundendaten auf den Auftraggeber.
    </p>
    <p>Folgende Funktionen sind hier vorhanden:</p>
    <ul>
      <li>
        Allgemeine Kundendaten hinterlegen.
      </li>
      <li>
        Historische Energieverbrauchsdaten hinterlegen.
      </li>
      <li>
        Energiepreise für Berechnung anpassen.
      </li>
      <li>
        Primärenergiefaktoren für Berechnung anpassen.
      </li>
      <li>
        CO2-Äquivalente für Berechnung anpassen.
      </li>
      <li>
        Zusätzlich können der Standort und die Energiedaten visuell dargestellt werden.
      </li>
    </ul>
    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Zusätzliche Informationen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h3>Energieverbrauch und Kosten aus Energieabrechnungen</h3>
            <p>
              Hier ist vorgesehen, die Jahres-Energiekosten und -verbräuche gemäß den Rechnungen 
              der Energielieferanten (Stadtwerke, Stromversorger, Heizölrechnung, etc.) anzugeben. 
              Es sind mindestens Werte für ein vollständiges Jahr und alle verwendeten Energieträger anzugeben. 
              Da Abrechnungszeiträume 
              der Energieversorger nicht immer mit dem Kalenderjahr übereinstimmen, können näherungsweise 
              auch die Daten eines abweichenden Abrechnungszeitraum verwendet werden, z.B. Anfang März bis 
              Ende Februar. Um die Entwicklung des Verbrauchs und der Kosten beurteilen zu können, ist es 
              sinnvoll, die Daten der letzten drei Jahre anzugeben.
            </p>
              <dl>
                <dt><strong>Erdgas:</strong></dt>
                <dd>
                    Eingabe des in den Gasrechnungen angegebenen Erdgasverbrauchs. Dieser ist 
                    üblicherweise auf den Brennwert (Hs, früher Ho) bezogen.
                </dd>
                <dt><strong>Heizöl:</strong></dt>
                <dd>
                    Eingabe des in den Heizöl-Rechnungen angegebenen Heizölverbrauchs. Dieser 
                    ist auf den Brennwert (Hs, früher Ho) zu beziehen. 
                    Die Umrechnung hierzu erfolgt mit folgendem Zusammenhang:
                </dd>
                <dt></dt>
                <dd><strong>
                  1 Liter Heizöl = 10,6 kWh (Hs)
                </strong></dd>
              </dl>
            <p>
              Zuletzt wird der Durchschnittspreis (€/kWh) aus den eingegebenen 
              Jahres-Energiekosten (€/a) und dem Jahres-Energieverbrauch (MWh/a) angezeigt.
            </p>

          <h3>Energiepreise</h3>
            <p>
              Für Energiepreise wurden am 23.11.2022 Standardwerte erhoben.
            </p>
            <dl>
              <dt><strong>Hinweis:</strong></dt>
              <dd>
                In der Analyse werden sie auf fehlende Energiepreise hingewiesen
              </dd>
            </dl>
          <h3>Primärenergiefaktoren</h3>
            <p>
              Die Primärenergiefaktoren richten sich nach der DIN 18599 „Energetische Bewertung 
              von Gebäuden“ und der aktuellen Energieeinsparverordnung (EnEV 2014). Bei Bedarf 
              können Sie diese Werte je nach Projekt anpassen. 
            </p>
          
          <h3>CO2-Äquivalente</h3>
            <p>
              Die CO2-Äquivalente richten sich nach GEMIS 4.9 (Globales Emissions-Modell 
              integrierter Systeme). Bei Bedarf können Sie diese Werte ebenfalls je nach Projekt anpassen. 
              Die Aktuellsten C02-Äquivalente finden Sie im "Informationsblatt CO2-Faktoren" der bafa.
            </p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer class="mt-10">
      <small
        >Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.</small
      >
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "NewMethod",
};
</script>

<style scoped></style>
