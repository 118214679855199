<template>
  <v-form @submit.prevent="" ref="form">
    <v-text-field
      v-model.number="amount"
      type="number"
      :error-messages="fieldErrors.amount"
      label="Anzahl"
      :hint="hint.amount"
      :rules="validationErrors.amount"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.amount"
        @click="getReferenceParameter(`amount`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="ratedPower"
      :error-messages="fieldErrors.ratedPower"
      label="Nennleistung"
      :items="ratedPowers"
      suffix="kW"
      :rules="validationErrors.rated_power"
      validate-on-blur
    >
      <v-tooltip v-if="hint.rated_power" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.rated_power }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.rated_power"
        @click="getReferenceParameter(`rated_power`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="motorType"
      :error-messages="fieldErrors.motorType"
      label="Bauart"
      :items="motorTypes"
      :rules="validationErrors.motor_type"
      validate-on-blur
    >
      <v-tooltip v-if="hint.motor_type" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.motor_type }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.motor_type"
        @click="getReferenceParameter(`motor_type`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-checkbox
      v-model.number="vsdFu"
      :error-messages="fieldErrors.vsdFu"
      label="VSD / FU"
    >
      <v-tooltip v-if="hint.vsd_fu" slot="append" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.vsd_fu }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.vsd_fu"
        @click="getReferenceParameter(`vsd_fu`)"
        slot="append"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-checkbox>

    <v-autocomplete
      v-model="poles"
      :error-messages="fieldErrors.poles"
      label="Polzahl"
      :items="poleList"
      :rules="validationErrors.poles"
      validate-on-blur
    >
      <v-tooltip v-if="hint.poles" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.poles }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.poles"
        @click="getReferenceParameter(`poles`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="efficiencyClass"
      :error-messages="fieldErrors.efficiencyClass"
      label="Effizienzklasse"
      :items="efficiencyClasses"
      :rules="validationErrors.efficiency_class"
      validate-on-blur
    >
      <v-tooltip v-if="hint.efficiency_class" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.efficiency_class }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.efficiency_class"
        @click="getReferenceParameter(`efficiency_class`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      v-model.number="mechanicalDemand"
      type="number"
      :error-messages="fieldErrors.mechanicalDemand"
      label="Mechanische Leistung"
      suffix="kW"
      :hint="hint.mechanical_demand"
      :rules="validationErrors.mechanical_demand"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.mechanical_demand"
        @click="getReferenceParameter(`mechanical_demand`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="fullLoad"
      type="number"
      :error-messages="fieldErrors.fullLoad"
      label="Anteil Volllast"
      suffix="%"
      :hint="hint.full_load"
      :rules="validationErrors.full_load"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.full_load"
        @click="getReferenceParameter(`full_load`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="partLoad75"
      type="number"
      :error-messages="fieldErrors.partLoad75"
      label="Anteil 75% Teillast"
      suffix="%"
      v-if="!(fullLoad == null) && fullLoad < 100 || partLoad75"
      :hint="hint.part_load_75"
      :rules="validationErrors.part_load_75"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.part_load_75"
        @click="getReferenceParameter(`part_load_75`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="partLoad50"
      type="number"
      :error-messages="fieldErrors.partLoad50"
      label="Anteil 50% Teillast"
      suffix="%"
      v-if="!(fullLoad == null) && fullLoad < 100 || partLoad50"
      :hint="hint.part_load_50"
      :rules="validationErrors.part_load_50"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.part_load_50"
        @click="getReferenceParameter(`part_load_50`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="partLoad25"
      type="number"
      :error-messages="fieldErrors.partLoad25"
      label="Anteil 25% Teillast"
      suffix="%"
      v-if="!(fullLoad == null) && fullLoad < 100 || partLoad25"
      :hint="hint.part_load_25"
      :rules="validationErrors.part_load_25"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.part_load_25"
        @click="getReferenceParameter(`part_load_25`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="uptime"
      type="number"
      :error-messages="fieldErrors.uptime"
      label="Jährliche Betriebsstunden"
      suffix="h"
      :hint="hint.uptime"
      :rules="validationErrors.uptime"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.uptime"
        @click="getReferenceParameter(`uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import { djangoChoicesToVuetify } from "@/utils";
import { cacheAdapter } from "@/cache";

export default {
  name: "ElectricDrive2Form",
  extends: BaseForm,

  data() {
    return {
      amount: null,
      ratedPower: null,
      motorType: null,
      vsdFu: false,
      poles: null,
      efficiencyClass: null,
      mechanicalDemand: null,
      fullLoad: null,
      partLoad75: null,
      partLoad50: null,
      partLoad25: null,
      uptime: null,

      fields: {
        amount: "amount",
        ratedPower: "rated_power",
        motorType: "motor_type",
        vsdFu: "vsd_fu",
        poles: "poles",
        efficiencyClass: "efficiency_class",
        mechanicalDemand: "mechanical_demand",
        fullLoad: "full_load",
        partLoad75: "part_load_75",
        partLoad50: "part_load_50",
        partLoad25: "part_load_25",
        uptime: "uptime"
      },

      ratedPowers: [],
      motorTypes: [],
      poleList: [2, 4, 6],
      efficiencyClasses: []
    };
  },

  beforeMount() {
    this.fetchRatedPowers();
    this.fetchMotorTypes();
    this.fetchEfficiencyClasses();
  },

  mounted() {
    if (!this.vsdFu) {
      this.vsdFu = false;
    }
    if (!this.partLoad75) {
      this.partLoad75 = 0;
    }
    if (!this.partLoad50) {
      this.partLoad50 = 0;
    }
    if (!this.partLoad25) {
      this.partLoad25 = 0;
    }
  },

  methods: {
    fetchRatedPowers() {
      this.$http
        .get("/api/v1/methods/electricdrive2/rated_powers/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.ratedPowers = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {
          // TODO: error handling
        });
    },
    fetchMotorTypes() {
      this.$http
        .get("/api/v1/methods/electricdrive2/motor-types/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.motorTypes = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {
          // TODO: error handling
        });
    },
    fetchEfficiencyClasses() {
      this.$http
        .get("/api/v1/methods/electricdrive2/efficiency-classes/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.efficiencyClasses = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {
          // TODO: error handling
        });
    }
  }
};
</script>

<style scoped></style>
