<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h1>RLT</h1>

    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Schritte vor der Berechnung</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol type="1">
            <li>
              <b>Erfassen der Typenschilddaten der RLT-Anlage</b>
              <ul>
                <li>
                  Alle relevanten Daten der bestehenden Anlage erfassen, 
                  einschließlich der Leistungsaufnahme, Luftvolumenströme, 
                  Drehzahl und Druckverhältnisse.
                </li>
              </ul> 
            </li> 
            <li> 
              <b>Ermittlung des aktuellen Betriebspunkts</b>    
              <ul>
                <li>
                  Durch Messungen die tatsächlichen Betriebsparameter der 
                  Anlage feststellen, wie z.B. Luftvolumenstrom, Druckdifferenz, 
                  Temperatur und relative Luftfeuchtigkeit.
                  Prüfung, ob die Anlage tatsächlich im auf dem Typenschild 
                  angegebenen Auslegungspunkt arbeitet.
                </li>
              </ul>
            </li> 
            <li> 
              <b>
              Überprüfung des Bedarfs und Vergleich mit dem aktuellen Betriebspunkt
              </b>
              <ul>
                <li>
                  Überprüfen, ob der aktuelle Luftvolumenstrom dem tatsächlichen 
                  Bedarf entspricht. In vielen Fällen wird die Anlage 
                  überdimensioniert betrieben.
                </li>
                <li>
                  Analyse der Luftverteilung und ob einzelne Zonen überversorgt sind.
                </li>
                <li>
                  Untersuchung, ob die Betriebszeiten der RLT-Anlage mit den 
                  tatsächlichen Nutzungszeiten übereinstimmen.
                </li>
              </ul>
            </li> 
            <li>
              <b>Identifikation von Optimierungspotentialen im System</b>
              <ul>
                <li>
                  Mechanische Systemoptimierungen und Bedarfsreduzierungen 
                  können erhebliches Einsparpotential bieten.                 
                </li>
                <li>
                  Prüfen, ob die Anlage durch Regelungstechniken wie variable 
                  Frequenzumrichter oder andere Regelmechanismen effizienter 
                  betrieben werden kann.                  
                </li>
                <li>
                  Überlegen, ob die Luftmenge reduziert oder die Luftverteilung 
                  optimiert werden kann, um Energie zu sparen.                  
                </li>
              </ul>
            </li>
            <li>
              <b>Bestimmung des neuen Auslegungspunktes für die Anlage</b>
              <ul>
                <li>
                  Festlegen eines optimierten Auslegungspunktes basierend auf 
                  den tatsächlichen Bedarfsermittlungen und den gemessenen 
                  Betriebsdaten.                  
                </li>
                <li>
                  Planung und Anpassung der Luftvolumenströme, um 
                  Überdimensionierungen zu vermeiden und den Energieverbrauch 
                  zu minimieren.
                </li>
              </ul> 
            </li>
            <li>
              <b>Abschätzung des Einsparpotentials und der Wirtschaftlichkeit</b>
              <ul>
                <li>
                  Analyse des Einsparpotentials durch Reduktion des 
                  Luftvolumenstroms, Anpassung der Betriebszeiten und 
                  Einführung von Regelungstechniken.                  
                </li>
                <li>
                  Durchführung einer Wirtschaftlichkeitsanalyse, um zu 
                  bewerten, ob die vorgeschlagenen Maßnahmen zu einer 
                  kosteneffizienten Reduktion des Energieverbrauchs führen.                  
                </li>
              </ul>
            </li>
            <li>
              <b>Überprüfung der baulichen Gegebenheiten</b>
              <ul>
                <li>
                  Überprüfen der Einbausituation der bestehenden Anlage, 
                  insbesondere im Hinblick auf den möglichen Einsatz neuer, 
                  effizienterer Komponenten.
                </li>
                <li>
                  Prüfung der Kompatibilität von neuen, energieeffizienten 
                  Ventilatoren oder Wärmerückgewinnungssystemen mit den 
                  baulichen Gegebenheiten.                  
                </li>
              </ul>
            </li>
            <li>
              <b>Auswahl der optimalen Systemlösung</b>
              <ul>
                <li>
                  Mit Hilfe von Herstellersoftware und Expertenwissen die 
                  bestmögliche Lösung für die spezifischen Anforderungen 
                  und Einbaubedingungen der Anlage auswählen.
                </li>
                <li>
                  Berücksichtigung von Faktoren wie Luftvolumenstrom, Druck, 
                  Geräuschentwicklung und Kosten.                  
                </li>
              </ul>
            </li>
            <li>
              <b>Prüfung der Einsatzmöglichkeit von Regelungstechnik</b>
              <ul>
                <li>
                  Den Einsatz einer stufenlosen Drehzahlregelung oder eines 
                  Frequenzumrichters in Erwägung ziehen, falls dies zur 
                  Einsparung beitragen kann.                  
                </li>
                <li>
                  Analyse der Wirtschaftlichkeit dieser Regelungstechniken 
                  im spezifischen Anwendungsfall.                  
                </li>
              </ul>
            </li>
            <li>
              <b>Kostenermittlung für die ausgewählte Systemlösung</b>
              <ul>
                <li>
                  Ermittlung aller anfallenden Kosten, einschließlich der Anschaffung, 
                  Installation, Inbetriebnahme sowie Wartung der neuen Komponenten.                  
                </li>
              </ul>
            </li>
            <li> 
              <b>Angebotserstellung und Wirtschaftlichkeitsanalyse</b>
              <ul>
                <li>
                  Erstellen eines Angebots basierend auf der gewählten Systemlösung.                  
                </li>
                <li>
                  Berechnung der finalen Einsparhöhe und Wirtschaftlichkeit unter 
                  Berücksichtigung der Investitionskosten und der erwarteten 
                  Energieeinsparungen.                  
                </li>
              </ul>
            </li>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Parameter der Anlage</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Ventilatoren separat berechnen</td>
                <td>
                  Die Berechnung der elektrischen Arbeit durch das Ventilatorsystem
                  kann über die Methode Ventilator detailliert erfolgen. Dazu sollte das
                  Ventilatorsystem hier nicht berechnet werden, um die elektrische
                  Arbeit nicht doppelt zu berücksichtigen.
                </td>
              </tr>
              <tr>
                <td>Volumenstrom</td>
                <td>Eingabe mittlerer Volumenstrom im Nennbetrieb</td>
              </tr>
              <tr>
                <td>Absenkvolumenstrom</td>
                <td>Eingabe mittlerer Volumenstrom im Asenkbetrieb</td>
              </tr>
              <tr>
                <td>Druckverlust</td>
                <td>Gesamter Druckverlust in Nennbetrieb des Luftstrangs</td>
              </tr>
              <tr>
                <td>Systemwirkungsgrad</td>
                <td>
                  Systemwirkungsgrad zur Luftförderung bestehend 
                  aus Ventilator, Kraftübertrageng (z.B. Keilriemen), Motor und ggf. FU
                </td>
              </tr>
              <tr>
                <td>Tägliche Betriebsstunden</td>
                <td>
                  Mittlere tägliche Betriebsstunden der RLT-Anlage. Zur Mittelung 
                  nur Tage berücksichtigen, an denen die Anlage betrieben wird
                </td>
              </tr>
              <tr>
                <td>Betriebstage im Jahr</td>
                <td>Anzahl der Tage im Jahr, an denen die RLT-Anlage betrieben wird</td>
              </tr>
              <tr>
                <td>Absenkbetrieb</td>
                <td>Optional Absenkbetrieb auswählbar</td>
              </tr>
              <tr>
                <td>Tägliche Betriebsstunden im Absenkbetrieb</td>
                <td>Mittlere tägliche Betriebsstunden der RLT-Anlage im Absenkbetrieb</td>
              </tr>
              <tr>
                <td>Zulufttemperatur</td>
                <td>Mittlere Zulufttemperatur</td>
              </tr>
              <tr>
                <td>Feuchteanforderung</td>
                <td>Angaben zur Regelung der Feuchteanforderung</td>
              </tr>
              <tr>
                <td>Befeuchter</td>
                <td>Art des Befeuchtersystems</td>
              </tr>
              <tr>
                <td>Wärmerückgewinnung</td>
                <td>Art der Wärmerückgewinnung</td>
              </tr>
              <tr>
                <td>Wirkungsgrad Wärmerückgewinnung</td>
                <td>Wirkungsgrad der Wärmerückgewinnung</td>
              </tr>
              <tr>
                <td>Kühlregister</td>
                <td>Optional Kühlregister auswählbar</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungsgrundlage und Daten</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <h3>Methodik</h3>
          <p>Die Leistungsaufnahme wird für die Betriebspunkte Volllast und Absenkbetrieb berechnet.</p>
          <img src="@/assets/help/hvac/hvac.png" alt="RLT-Anlage">
            <p>
              DIN 18599-3 erlaubt die Endenergieberechnung von Lüftungsanlagen. Es 
              wird dabei unterschieden zwischen Anlagen, die mit einem konstanten 
              Volumenstrom betrieben werden und Anlagen welche einheitlich über das 
              Jahr verteilt, gleichmäßig geregelt werden (Absenkbetrieb). Die Energie 
              für die Lüftungsanlagen setzt sich aus der elektrischen Energie für die 
              Luftförderung und der Energie für die Luftkonditionierung zusammen. Die 
              Berechnung der jeweiligen Energien erfolgt auf Grundlage von 
              Energiekennwerten für die Monate Januar bis Dezember.
            </p>
            <p>Der Energiebedarf für die Luftkonditionierung hängt des Weiteren von folgenden Faktoren ab:</p>
            <ul>
              <li>Feuchteanforderung</li>
              <li>Typ des Luftbefeuchtungssystem</li>
              <li>Typ des Wärmerückgewinnungssystem</li>
              <li>Dimensionierung des Wärmerückgewinnungssystem</li>
              <li>Volumenstrom</li>
            </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>
        Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>
  </div>
</template>

<script>

export default {
  name: "HVACHelp"
}
</script>

<style scoped>

</style>