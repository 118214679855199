<template>
  <v-form @submit.prevent="">
    <v-row>
      <v-col :cols="6" :xs="12">
        <v-card>
          <v-card-title>
            <div class="headline">Allgemeine Daten</div>
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="name"
              :error-messages="fieldErrors.name"
              label="Projektbezeichnung"
              :hint="hint.name"
            />

            <v-text-field
              v-model="street"
              :error-messages="fieldErrors.street"
              label="Straße/Hausnummer"
              :hint="hint.street"
            />

            <v-text-field
              v-model="postcode"
              :error-messages="fieldErrors.postcode"
              label="PLZ"
              :hint="hint.postcode"
            />

            <v-text-field
              v-model="city"
              :error-messages="fieldErrors.city"
              label="Stadt"
              :hint="hint.city"
            />

            <v-autocomplete
              v-model="branch"
              :error-messages="fieldErrors.branch"
              label="Branche"
              :items="branches"
              item-text="name"
              item-value="id"
            >
              <v-tooltip v-if="hint.branch" slot="append-outer" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information</v-icon>
                </template>
                <span>{{ hint.branch }}</span>
              </v-tooltip>
            </v-autocomplete>

            <v-autocomplete
              v-model="category"
              :error-messages="fieldErrors.category"
              label="Kategorie"
              :items="categories"
              item-text="name"
              item-value="id"
              :disabled="!branch"
            >
              <v-tooltip v-if="hint.category" slot="append-outer" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information</v-icon>
                </template>
                <span>{{ hint.category }}</span>
              </v-tooltip>
            </v-autocomplete>

            <v-text-field
              v-model="area"
              :error-messages="fieldErrors.area"
              label="Bruttogrundfläche"
              suffix="m²"
              :hint="hint.area"
            />
            <v-btn v-if="!localise" @click="runLocalise">Auf der Karte anzeigen</v-btn>
            <v-btn v-if="localise" @click="runLocalise">Karte schliessen</v-btn>
          </v-card-text>
        </v-card>
          <MapContainer v-if="localise" :mapLon="lon" :mapLat="lat" />
        <v-card>
          <Statscard class="mt-2" :projectData="data" :projectValid="valid" />
        </v-card>
      </v-col>

      <v-col :cols="6" :xs="12">
        <v-card>
          <v-row no-gutters>
            <v-card-title class="headline">
              Energiedaten
            </v-card-title>
            <v-row no-gutters class="justify-end">
              <div>
                <v-container>
                  <Helpbar :help="ProjectDetailsHelp" />
                </v-container>
              </div>
            </v-row>
          </v-row>

          <v-expansion-panels accordion rounded hover>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="heading">
                  Energieverbrauch und Kosten aus Energieabrechnungen
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-select v-model="year" :items="years"></v-select>

                <EnergyStatsYear
                  v-if="energyConsumptionData[yearIndex]"
                  v-model="energyConsumptionData[yearIndex]"
                  :fuels="fuels"
                  :errors="energyConsumptionDataYearErrors"
                  :hintFuel="hintFuel.energyConsumptionData"
                ></EnergyStatsYear>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="heading">Energiepreise für Berechnung</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table class="mt-5">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Energieträger</th>
                        <th class="text-left">Energiepreis (€/kWh)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in fuels" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>
                          <v-text-field
                            label=""
                            v-model="energyPriceData[item.id]"
                            placeholder="0"
                            :error-messages="energyPriceDataErrors[index]"
                            outlined
                            dense
                            class="mt-6"
                          >
                            <v-tooltip
                              v-if="hintFuel.energyPriceData[item.name]"
                              slot="append-outer"
                              bottom
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">mdi-information</v-icon>
                              </template>
                              <span>{{
                                hintFuel.energyPriceData[item.name]
                              }}</span>
                            </v-tooltip>
                            <v-btn
                              @click="
                                getStandardParameter(
                                  item.name,
                                  'energyPriceData'
                                )
                              "
                              slot="append-outer"
                              title="Standardwert nehmen"
                              icon
                            >
                              <v-icon>mdi-cog-counterclockwise</v-icon>
                            </v-btn>
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="heading">Primärenergiefaktoren</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table class="mt-5">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Energieträger</th>
                        <th class="text-left">Primärenergiefaktor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in fuels" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>
                          <v-text-field
                            label=""
                            v-model="energyPerformanceData[item.id]"
                            placeholder="0"
                            :error-messages="energyPerformanceDataErrors[index]"
                            outlined
                            dense
                            class="mt-6"
                          >
                            <v-tooltip
                              v-if="hintFuel.energyPerformanceData[item.name]"
                              slot="append-outer"
                              bottom
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">mdi-information</v-icon>
                              </template>
                              <span>{{
                                hintFuel.energyPerformanceData[item.name]
                              }}</span>
                            </v-tooltip>
                            <v-btn
                              @click="
                                getStandardParameter(
                                  item.name,
                                  'energyPerformanceData'
                                )
                              "
                              slot="append-outer"
                              title="Standardwert nehmen"
                              icon
                            >
                              <v-icon>mdi-cog-counterclockwise</v-icon>
                            </v-btn>
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="heading">CO₂-Äquivalente</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table class="mt-5">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Energieträger</th>
                        <th class="text-left">CO₂-Äquivalente (g/kWh)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in fuels" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>
                          <v-text-field
                            label=""
                            v-model="energyCo2Data[item.id]"
                            placeholder="0"
                            :error-messages="energyCo2DataErrors[index]"
                            outlined
                            dense
                            class="mt-6"
                          >
                            <v-tooltip
                              v-if="hintFuel.energyCo2Data[item.name]"
                              slot="append-outer"
                              bottom
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">mdi-information</v-icon>
                              </template>
                              <span>{{
                                hintFuel.energyCo2Data[item.name]
                              }}</span>
                            </v-tooltip>
                            <v-btn
                              @click="
                                getStandardParameter(item.name, 'energyCo2Data')
                              "
                              slot="append-outer"
                              title="Standartwert nehmen"
                              icon
                            >
                              <v-icon>mdi-cog-counterclockwise</v-icon>
                            </v-btn>
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="end" no-gutters class="mt-5">
      <v-btn @click="submit" :disabled="!valid">Speichern</v-btn>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";


import Helpbar from "@/components/Helpbar";
import ProjectDetailsHelp from "@/components/help/ProjectDetailsHelp";
import Statscard from "@/components/projectstats/Statscard";
import MapContainer from "@/components/MapContainer";
import EnergyStatsYear from "@/components/project/EnergyStatsYear";
import ValidationMixin from "@/components/ValidationMixin";
import EnergyDataTableInputs from "@/components/project/EnergyDataTableInputs";
import { cacheAdapter } from "@/cache";

export default {
  name: "ProjectDetails",
  mixins: [ValidationMixin],
  validationUrl: "/api/v1/projects/validate/",
  components: {
    EnergyDataTableInputs,
    EnergyStatsYear,
    MapContainer,
    Statscard,
    Helpbar,
  },
  props: {},

  data() {
    return {
      ownerOverride: null,
      name: null,
      city: null,
      postcode: null,
      street: null,
      branch: null,
      category: null,
      area: null,
      ProjectDetailsHelp,

      hint: String,
      hintFuel: {
        // help_text (Backend) für max. 1 Parameter, heißt bei Auswahlfedern
        // mit detailierter Hilfe wird die help_text im Frontend implementiert.
        energyConsumptionData: {
          Netzstrom: "",
          "Gas LL": "",
          "Gas E": "",
          Öl: "Umrechnungsfaktor 10,6 kWh pro Liter HEL wird verwendet",
          Pellets: "",
          Hackschnitzel: "",
          Fernwärme: ""
        },
        energyPriceData: {
          Netzstrom: "",
          "Gas LL": "",
          "Gas E": "",
          Öl: "",
          Pellets: "",
          Hackschnitzel: "",
          Fernwärme: ""
        },
        energyPerformanceData: {
          Netzstrom: "",
          "Gas LL": "",
          "Gas E": "",
          Öl: "",
          Pellets: "",
          Hackschnitzel: "",
          Fernwärme: ""
        },
        energyCo2Data: {
          Netzstrom: "",
          "Gas LL": "",
          "Gas E": "",
          Öl: "",
          Pellets: "",
          Hackschnitzel: "",
          Fernwärme: ""
        }
      },

      fields: {
        owner: "owner",
        name: "name",
        postcode: "postcode",
        street: "street",
        city: "city",
        branch: "branch",
        category: "category",
        area: "area",
        energyDataPayload: "energy_data",
        energyConsumptionDataPayload: "energy_consumption_data"
      },

      energyCo2Data: {},
      energyPerformanceData: {},
      energyPriceData: {},

      energyConsumptionData: {},

      energyConsumptionDataLastPayload: {},

      branches: [],
      categories: [],
      fuels: [],

      localise: false,
      lon: null,
      lat: null,
      endpoint: "https://nominatim.openstreetmap.org/search.php",
      format: "jsonv2",

      year: new Date().getFullYear()
    };
  },

  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    project() {
      return this.$store.state.currentProject;
    },
    updating() {
      return !!this.projectId;
    },
    years() {
      let todayYear = new Date().getFullYear();
      let result = [];
      for (let year = todayYear; year >= todayYear - 10; year -= 1) {
        result.push(year);
      }
      return result;
    },
    yearIndex() {
      return this.years.indexOf(this.year);
    },
    data: {
      get() {
        return this.getDataFromFields();
      },
      set(data) {
        return this.setFieldsFromData(data);
      }
    },
    owner: {
      get() {
        return this.ownerOverride || this.$store.getters.userId;
      },
      set(val) {
        this.ownerOverride = val;
      }
    },
    energyDataPayload: {
      get() {
        return Object.values(this.fuels).map((fuel) => ({
          fuel: fuel.id,
          price: this.energyPriceData[fuel.id] ?? 0,
          co2: this.energyCo2Data[fuel.id] ?? 0,
          performance: this.energyPerformanceData[fuel.id] ?? 0
        }));
      },
      set(data) {
        data.forEach((fuelData) => {
          this.energyPriceData[fuelData.fuel] = fuelData.price;
          this.energyPerformanceData[fuelData.fuel] = fuelData.performance;
          this.energyCo2Data[fuelData.fuel] = fuelData.co2;
        });
        this.energyPriceData = { ...this.energyPriceData };
        this.energyPerformanceData = { ...this.energyPerformanceData };
        this.energyCo2Data = { ...this.energyCo2Data };
      }
    },
    energyConsumptionDataPayload: {
      get() {
        return Object.values(this.energyConsumptionData)
          .map((yearData, i) => {
            return this.fuels.map((fuel) => ({
              year: this.years[i],
              fuel: fuel.id,
              consumption: yearData[fuel.id].consumption,
              cost: yearData[fuel.id].cost
            }));
          })
          .flat()
          .filter((x) => x.consumption || x.cost);
      },
      set(data) {
        for (let entry of data) {
          let yearIndex = this.years.indexOf(entry.year);
          this.energyConsumptionData[yearIndex][entry.fuel] = {
            consumption: entry.consumption,
            cost: entry.cost
          };
        }
        this.energyConsumptionData = { ...this.energyConsumptionData };
      }
    },
    energyPriceDataErrors() {
      if (!this.fieldErrors.energyDataPayload) return [];
      return this.fieldErrors.energyDataPayload.map((x) => x.price);
    },
    energyPerformanceDataErrors() {
      if (!this.fieldErrors.energyDataPayload) return [];
      return this.fieldErrors.energyDataPayload.map((x) => x.performance);
    },
    energyCo2DataErrors() {
      if (!this.fieldErrors.energyDataPayload) return [];
      return this.fieldErrors.energyDataPayload.map((x) => x.co2);
    },

    energyConsumptionDataYearErrors() {
      if (!this.fieldErrors.energyConsumptionDataPayload) return [];
      let fuelMap = this.energyConsumptionDataLastPayload
        .map((data, i) => ({ ...data, i: i }))
        .filter((entry) => entry.year === this.year)
        .reduce((acc, curr) => {
          acc[curr.fuel] = this.fieldErrors.energyConsumptionDataPayload[
            curr.i
          ];
          return acc;
        }, {});
      return this.fuels.map((fuel) => fuelMap[fuel.id] || {});
    }
  },

  beforeMount() {
    this.fetchBranches();
    this.fetchFuels();
    this.fetchHints();
    this.fetchProject();
  },

  watch: {
    branch() {
      let branchObject;
      for (let branch of this.branches) {
        if (branch.id === this.branch) {
          branchObject = branch;
          break;
        }
      }
      this.categories = branchObject.categories;
      if (this.categories.length === 1) {
        this.category = this.categories[0].id;
      }
    },
    fieldErrors() {
      this.energyConsumptionDataLastPayload = this.energyConsumptionDataPayload;
    },
    projectId: {
      handler: "fetchProject",
      immediate: true
    },
    fuels: "fetchProject",
    project: {
      handler(project) {
        if (project) {
          this.$store.commit("setTitle", this.project.name);
        }
      },
    }
  },

  methods: {
    getStandardParameter(parameter, field) {
      if (field == "energyPriceData") {
        let fuel = this.fuels.filter((fuel) => fuel.name == parameter)[0];
        this.energyPriceData[fuel.id] = fuel.price;
      }
      if (field == "energyPerformanceData") {
        let fuel = this.fuels.filter((fuel) => fuel.name == parameter)[0];
        this.energyPerformanceData[fuel.id] = fuel.primary_energy_factor;
      }
      if (field == "energyCo2Data") {
        let fuel = this.fuels.filter((fuel) => fuel.name == parameter)[0];
        this.energyCo2Data[fuel.id] = fuel.co2_emission_factor;
      }
    },
    runLocalise() {
      axios
        .get(this.endpoint, {
          params: {
            street: this.street,
            city: this.city,
            postalcode: this.postcode,
            format: this.format
          }
        })
        .then((response) => {
          this.lon = response.data[0].lon;
          this.lat = response.data[0].lat;
          this.localise = !this.localise;
        })
        .catch(() => {
          this.$toast("Es gab einen Fehler bei der Lokalisierung.", {
            color: "error"
          });
        });
    },
    fetchHints() {
      this.$http
        .get("/api/v1/projects/help/")
        .then((response) => {
          this.hint = Object.assign(
            {},
            ...response.data.map((x) => ({ [x.key]: x.help }))
          );
        })
        .catch(() => {
          this.hint = [];
        });
    },
    fetchProject() {
      if (this.projectId && this.fuels && this.fuels.length) {
        this.$store.dispatch("fetchCurrentProject", this.projectId).then(() => {
          let project = this.$store.state.currentProject;
          this.setFieldsFromData(project);
        });
      }
    },

    submit() {
      let promise = this.updating
        ? this.$http.put(`/api/v1/projects/${this.projectId}/`, this.data)
        : this.$http.post("/api/v1/projects/", this.data);
      promise
        .then((response) => {
          if (this.updating) {
            this.$toast(
              `Projekt <b>${response.data.name}</b> erfolgreich gespeichert.`,
              { color: "success" }
            );
          } else {
            this.$toast(
              `Projekt <b>${response.data.name}</b> erfolgreich erstellt.`,
              { color: "success" }
            );
          }
          this.$router.push({
            name: "ProjectDashboard",
            params: { projectId: response.data.id }
          });
        })
        .catch(() => {
          this.$toast("Es gab einen Fehler beim Erstellen des Projektes.", {
            color: "error"
          });
        });
    },

    fetchBranches() {
      this.$http
        .get("/api/v1/projects/branches/", { adapter: cacheAdapter })
        .then((response) => {
          this.branches = response.data;
          if (this.branches.length === 1) {
            this.branch = this.branches[0];
          }
        })
        .catch(() => {});
    },

    fetchFuels() {
      this.$http
        .get("/api/v1/projects/fuels_ee/", { adapter: cacheAdapter })
        .then((response) => {
          let fuels = response.data;
          this.energyConsumptionData = this.years.map(() => {
            return fuels.reduce((acc, curr) => {
              acc[curr.id] = { consumption: null, cost: null };
              return acc;
            }, {});
          });
          this.fuels = fuels;

          for (let i in fuels) {
            if (
              this.energyPriceData[String(fuels.map((fuel) => fuel.id))] == null
            ) {
              this.energyPriceData[String(fuels.map((fuel) => fuel.id)[i])] =
                fuels[i].price;
            }
            if (
              this.energyPerformanceData[
                String(fuels.map((fuel) => fuel.id))
              ] == null
            ) {
              this.energyPerformanceData[
                String(fuels.map((fuel) => fuel.id)[i])
              ] = fuels[i].primary_energy_factor;
            }
            if (
              this.energyCo2Data[String(fuels.map((fuel) => fuel.id))] == null
            ) {
              this.energyCo2Data[String(fuels.map((fuel) => fuel.id)[i])] =
                fuels[i].co2_emission_factor;
            }
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
div.heading {
  color: black;
  font-size: 1.25rem;
}
</style>
