<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h2>Beleuchtung (überschlägig)</h2>
    <ul>
      <li>
        Bei einer ungewöhnlich hohen Einsparung wird empfohlen die Maßnahme mit der 
        ausführlichen Methode zu berechnen.
      </li>
      <li>
        Bei einem Alter von &bdquo;0 bis 5
        Jahre&ldquo; der Lichtanlage wird in dieser Methode keine Einsparung
        berechnet, da dieser Wert als Referenzwert für eine Neuanlage gilt und
        eine Erneuerung der Lichtanlage dieser Altersklasse nur in Ausnahmefällen
        lohnenswert ist. 
      </li> 
      <li> 
        Falls nur eine Steuerung nachgerüstet werden soll,
        kann auch die ausführliche Methode bei dieser Altersklasse der Lichtanlage
        durchgeführt werden, da sich durch Angabe einer geplanten Steuerung der
        Leistungsbedarf in W/m&sup2; verringert.
      </li>
      <li>
        Die Brenndauer ist nach der DIN V 18599-10:2018-09 von dem Gebäudetyp abhängig
        und kann manuell angepasst werden.
      </li> 
    </ul>  

    <v-expansion-panels accordion class="mt-5">
         <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Übergreifend</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Gebäudetyp</td>
                <td>Auswahl des Gebäudetyps.</td>
              </tr>
              <tr>
                <td>Bruttogrundfläche</td>
                <td>Eingabe der Bruttogrundfläche (Nettogrundfläche + Mauerwerk).</td>
              </tr>
              <tr>
                <td>Anteil Hauptfläche</td>
                <td>Eingabe der prozentualen Hauptfläche. Diese können in der Hilfe unter Berechnungsgrundlage und Daten nachgeschlagen werden.</td>
              </tr>
              <tr>
                <td>Anteil Nebenfläche</td>
                <td>Eingabe der prozentualen Hauptfläche. Diese können in der Hilfe unter Berechnungsgrundlage und Daten nachgeschlagen werden.</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Parameter der Anlage</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Manuelle Brenndauer</td>
                <td>Option manuelle Brenndauer.</td>
              </tr>
              <tr>
                <td>Brenndauer</td>
                <td>Eingabe der Brenndauer, wenn die Berechnete von der Norm abweicht.</td>
              </tr>
              <tr>
                <td>Alter der Lichtanlage</td>
                <td>Auswahl des Alters der Lichtanlage / Leuchten.</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungsgrundlage und Daten</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h3>Brenndauer</h3>
          <p>
            Die Brenndauer ist ein Normwert nach der DIN V
            18599-10:2018-09, Summe der jährlichen Nutzungsdauer Tag- und Nachtzeit
            multipliziert mit dem Teilbetriebsfaktor der Gebäudebetriebszeit für
            Beleuchtung. Diesen gilt es aber zu prüfen, um mögliche
            Fehlberechnungen zu vermeiden. Die Erfahrung zeigt, dass die Normwerte
            gerade im Bereich der Industrie unter den tatsächlichen Brenndauern
            liegen. Die Brenndauern im Schichtbetrieb sind teilweise eine bessere Schätzung,
            um einen groben Richtwert zu geben, sollen aber auch noch einmal vor
            Berechnung überprüft werden.
          </p>
          <h2>Hinterlegte Werte </h2>
          <p>
            Neben dem freidefinierbaren Wert der Bruttogrundfläche, gibt es
            verschiedene vorgegebene Werte innerhalb der Auswahlmöglichkeiten, welche
            aber z.T. auch geändert werden können. Diese werden Ihnen anhand von
            Tabellen dargestellt.
          </p>

          <h3>Gebäudetyp</h3>
          <p>
            Wählen Sie den Gebäudetyp aus, der am ehesten Ihrem Gebäude, also der
            Hauptnutzungsart entspricht. Der Nebenflächenanteil, der eine weniger hohe
            Beleuchtungsstärke benötigt als die Hauptfläche kann manuell eingegebenen werden. 
            Der spezifische Nebenflächenanteil kann in der Hilfe unter Berechnungsgrundlage und Daten nachgeschlagen werden.
            Folgende Beleuchtungsstärken werden für die Gebäudetypen verwendet, diese entsprechen der DIN V
            18599-10:2018-09 oder Erfahrungswerten:
          </p>
          <ul>
            <li>Bürogebäude &ndash; 500 Lx</li>
            <li>Sporthalle &ndash; 500 Lx</li>
            <li>Schule &ndash; 300 Lx</li>
            <li>Nebenfläche/Lager &ndash; 200 Lx</li>
            <li>Industrie - leichte/feine Arbeit &ndash; 500 Lx</li>
            <li>Shop &ndash; 800 Lx</li>
          </ul>

          <h3>Anteil Neben- und Hauptfläche (%)</h3>
          <p>
            Abhängig von den Gebäudetypen sind die weiteren Auswahlmöglichkeiten, wie
            z.B. der prozentuale Anteil Neben- und Hauptfläche. Der Anteil für das
            Mauerwerk wird in den Berechnungen berücksichtigt. Folgende Werte sind in
            der Datenbank hinterlegt und werden innerhalb der App angezeigt. Sie
            können dort aber auch bei Bedarf angepasst werden:
          </p>
          <table>
            <thead>
              <tr>
                <th>Gebäudeart</th>
                <th>Anteil Neben-/Hauptfläche (%)</th>
                <th>Anteil Mauerwerk (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Bürogebäude</td>
                <td>40/60</td>
                <td>15</td>
              </tr>

              <tr>
                <td>Sporthalle</td>
                <td>10/90</td>
                <td>5</td>
              </tr>

              <tr>
                <td>Schule</td>
                <td>40/60</td>
                <td>15</td>
              </tr>

              <tr>
                <td>Nebenfläche/Lager</td>
                <td>0/100</td>
                <td>5</td>
              </tr>

              <tr>
                <td>Industrie - leichte Arbeit</td>
                <td>0/100</td>
                <td>5</td>
              </tr>

              <tr>
                <td>Industrie - schwere Arbeit</td>
                <td>0/100</td>
                <td>5</td>
              </tr>

              <tr>
                <td>Shop</td>
                <td>10/90</td>
                <td>5</td>
              </tr>
            </tbody>
          </table>

          <h3>Brenndauer</h3>
          <p>
            Die Werte für die Brenndauern sind aus der DIN V 18599-10:2018-09
            entnommen, diese können jedoch überschrieben werden.
          </p>
          <table>
            <thead>
              <tr>
                <th>Gebäudeart</th>
                <th>Brenndauer</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Bürogebäude</td>
                <td>1925</td>
              </tr>
              <tr>
                <td>Sporthalle</td>
                <td>3750</td>
              </tr>
              <tr>
                <td>Schule</td>
                <td>1260</td>
              </tr>
              <tr>
                <td>Nebenfläche/Lager</td>
                <td>2750</td>
              </tr>
              <tr>
                <td>Industrie - leichte Arbeit</td>
                <td>1863</td>
              </tr>
              <tr>
                <td>Industrie - leichte Arbeit (1-Schicht-Betrieb)</td>
                <td>2340</td>
              </tr>
              <tr>
                <td>Industrie - leichte Arbeit (2-Schicht-Betrieb)</td>
                <td>4680</td>
              </tr>
              <tr>
                <td>Industrie - leichte Arbeit (3-Schicht-Betrieb)</td>
                <td>7488</td>
              </tr>
              <tr>
                <td>Industrie - schwere Arbeit</td>
                <td>1863</td>
              </tr>
              <tr>
                <td>Industrie - schwere Arbeit (1-Schicht-Betrieb)</td>
                <td>2340</td>
              </tr>
              <tr>
                <td>Industrie - schwere Arbeit (2-Schicht-Betrieb)</td>
                <td>4680</td>
              </tr>
              <tr>
                <td>Industrie - schwere Arbeit (3-Schicht-Betrieb)</td>
                <td>7488</td>
              </tr>
              <tr>
                <td>Shop</td>
                <td>3600</td>
              </tr>
            </tbody>
          </table>
          <dl>
            <dt><strong>Hinweis:</strong></dt>
            <dd>
              Bitte halten Sie Rücksprache mit dem technischen Leiter der
              Liegenschaft, um die tatsächlichen Brenndauern zu erfragen.
            </dd>
          </dl>

          <h3>Alter der Lichtanlage</h3>
          <p>
            Das Alter der bestehenden Lichtanlage ist eingeteilt in verschiedene
            Altersklassen. Mit Hilfe dieser Angabe wird aus einer Tabelle der spez.
            Leistungsbedarf in W/m&sup2; ermittelt. Folgende Altersklassen sind
            hinterlegt:
          </p>
          <ul>
            <li>älter als 25 Jahre</li>
            <li>bis 25 Jahre</li>
            <li>bis 15 Jahre</li>
            <li>bis 10 Jahre</li>
            <li>0 bis 5 Jahre</li>
          </ul>

          <p>
            Je nach Auswahl des Gebäudetyps und der Altersklasse ergibt sich ein spez.
            Leistungsbedarf.
          </p>

          <p>
            Berechnet wird eine Einsparung gegenüber einer neuen Lichtanlage.
            Referenzwert ist dafür die Altersklasse &bdquo;0 bis 5 Jahre&ldquo;.
            Aufgrund dieser Berechnungsgrundlage wird keine Einsparung berechnet für
            eine bestehende Lichtanlage mit einer Altersklasse &bdquo;0 bis 5
            Jahre&ldquo;. Eine Einsparung durch einen Einbau einer Steuerung kann
            jedoch ermittelt werden.
          </p>


        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Zusätzliche Informationen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
            <h2>Lichttechnische Grundbegriffe </h2>
            <img
              src="@/assets/help/lighting/lighting.png"
              alt="Hängelampe"
            />
            <figcaption>
              Abbildung: Beispiel Hängelampe im Büro
            </figcaption>
            <h3>Leuchte</h3>
            <p>
              Der gesamte Beleuchtungskörper inklusive aller für Befestigung, Betrieb
              und Schutz der Lampe notwendigen Komponenten ist die &bdquo;Leuchte&ldquo;
              &ndash; im Volksmund auch gerne als &bdquo;Lampe&ldquo; bezeichnet. Die
              Leuchte schützt die Lampe, verteilt und lenkt deren Licht, verhindert,
              dass es blendet.
            </p>
            <p>Leuchten werden unterschieden nach</p>
            <ul>
              <li>
                Art der eingesetzten Lampen (für Energiesparlampen, für
                Leuchtstofflampen, für Entladungslampen),
              </li>
              <li>
                der Anzahl der Lampen (ein-, zweilampig bzw. ein-, zweiflammig, usw.),
                nach dem Einsatzbereich (Innenleuchten, Außenleuchten),
              </li>
              <li>dem Einsatzort (Schreibtischleuchten, Badleuchten usw.),</li>
              <li>
                der Schutzart (Leuchten für trockene, feuchte und staubige Räume),
              </li>
              <li>
                der Bauart (offene Leuchten, geschlossene Leuchten, Reflektorleuchten,
                Spiegelleuchten, Rasterleuchten, Wannenleuchten, Scheinwerfer),
              </li>
              <li>der Montageart (Wand-, Decken-, Pendel-, oder Handleuchten),</li>
              <li>
                dem Verwendungszweck (technische Leuchten, Wohnraumleuchten, dekorative
                Leuchten oder Effektleuchten) oder
              </li>
              <li>
                nach der Beleuchtungsaufgabe (Arbeitsplatzleuchten, Straßenleuchten).
              </li>
            </ul>

            <h3>Lampe </h3>
            <p>
              Ohne Lampe kein Licht: &bdquo;Lampe&ldquo; bezeichnet die technische
              Ausführung einer künstlichen Lichtquelle. Die Lampe wandelt elektrische
              Energie in Licht um. Die Lampe wird in der Leuchte eingesetzt, die das
              Licht der Lampe verteilt, lenkt und vor Blendung schützt.
            </p>
            <p>
              Lampen gibt es in vielen Ausführungen. Sie unterscheiden sich durch die
              Art der Lichterzeugung, ihre geometrische Form und ihre lichttechnischen
              Eigenschaften. Im Wohnbereich werden zum Beispiel Halogenlampen,
              Energiesparlampen und Leuchtstofflampen eingesetzt.
            </p>

            <h3>Vorschaltgerät</h3>
            <p>
              Für den Betrieb von Entladungslampen &ndash; zum Beispiel
              Leuchtstofflampen &ndash; sind Vorschaltgeräte (VGs) erforderlich. Denn
              nach der Zündung dieser Lampen erfolgt eine lawinenartige Ionisierung der
              enthaltenen Edelgase oder Metalldämpfe, die zu einem starken Anstieg des
              Lampenstroms führt. Er würde die Lampe nach kurzer Zeit zerstören. Daher
              begrenzt ein VG den Lampenstrom, auch im Betrieb.
            </p>
            <p>
              Zu unterscheiden sind konventionelle (KVGs) und verlustarme (VVGs)
              Vorschaltgeräte sowie elektronische (EVGs) Vorschaltgeräte. EVG für
              Leuchtstofflampen gibt es in dimmbarer und nicht dimmbarer Ausführung. Bei
              Energiesparlampen sind VG im Schraubsockel integriert, ansonsten sind sie
              Bestandteil der Leuchte.
            </p>

            <h3>Lichtstrom in Lumen (lm) </h3>
            <p>
              Der Lichtstrom &Phi; &ndash; gemessen in Lumen (lm) &ndash; ist die
              Lichtleistung einer Lampe. Er beschreibt die von der Lichtquelle in alle
              Richtungen abgestrahlte Leistung im sichtbaren Bereich. Eine Glühlampe hat
              etwa 1.400 Lumen, eine zweiseitig gesockelte Halogenlampe bis zu 44.000
              Lumen. Der Lichtstrom einzelner LEDs beträgt zwischen 18 und 30 Lumen.
            </p>
            <p>
              Gerade im Bereich der LEDs ist die Angabe des Lichtstroms von großer
              Bedeutung. Oftmals werden LEDs auf den Markt gebracht, die eine geringere
              Leistungsaufnahme im Vergleich zu Konkurrenzprodukten haben, jedoch auch
              einen viel geringeren Lichtstrom. Dadurch wird ein
              &bdquo;schwächeres&ldquo; Licht erzeugt. Es ist daher wichtig, diese
              Angabe neben der Leistungsaufnahme ebenfalls zu vergleichen.
            </p>
            <img src="@/assets/help/lighting/lighting_lichtstrom.png" />
            <figcaption>
              Abbildung: Visualisierung des Lichtstroms 
            </figcaption>
            <h3>Beleuchtungsstärke in Lux (lx) </h3>
            <p>
              Die Beleuchtungsstärke (Kurzzeichen: E, Maßeinheit: Lux) definiert, wie
              viel Licht &ndash; lichttechnisch genauer wie viel Lichtstrom (in Lumen)
              &ndash; auf eine bestimmte Fläche fällt: Sie beträgt ein Lux, wenn der
              Lichtstrom von einem Lumen einen Quadratmeter Fläche gleichmäßig
              ausleuchtet. Ein Beispiel: Eine normale Kerzenflamme hat im Abstand von
              einem Meter ungefähr ein Lux Beleuchtungsstärke.
            </p>
            <p>
              Die Beleuchtungsstärke wird mit einem Luxmeter auf horizontalen und
              vertikalen Flächen gemessen. Sie beschreibt allerdings nicht präzise den
              Helligkeitseindruck eines Raumes, da dieser wesentlich von den
              Reflexionseigenschaften der Raumflächen abhängt. So erscheint ein weißer
              Raum bei gleicher Beleuchtungsstärke heller als ein dunkler. Siehe auch:
              halbzylindrische Beleuchtungsstärke.
            </p>
            <p>
              Weil die Lichtverteilung einer normalen Beleuchtung nicht absolut
              gleichmäßig ist, geben Normen in der Regel die mittlere Beleuchtungsstärke
              an. Sie berücksichtigt die Ungleichmäßigkeiten rechnerisch: Die mittlere
              Beleuchtungsstärke ist das gewichtete arithmetische Mittel aller
              Beleuchtungsstärken im Raum.
            </p>
            <div class="row">
              <div class="column">
                <img
                  src="@/assets/help/lighting/lighting_beleuchtungsstaerke.png"
                  alt="Beläuchtungstärke"
                  style="width:75%"
                />
              </div>
              <div class="column">
                <img
                  src="@/assets/help/lighting/lighting_beleuchtungsstaerke_ax.png"
                  alt="Beleuchtungsachsen"
                  style="width:75%"
                />
              </div>
            </div>
            <figcaption>
              Abbildung: Beleuchtungsstärke (links) und gewichtete arithmetische Mittel aller Beleuchtungsstärken im Raum (rechts)
            </figcaption>

            <h3>Lichtstärke in Candela (cd) </h3>
            <p>
              Die Lichtstärke (Kurzzeichen: I) ist der Teil des Lichtstroms, der in eine
              bestimmte Richtung strahlt. Sie wird in Candela (cd) gemessen.
            </p>
            <p>
              Die räumliche Verteilung der Lichtstärke (Lichtstärkeverteilung)
              charakterisiert die Lichtausstrahlung von Leuchten und Reflektorlampen.
              Die Lichtstärkeverteilung wird grafisch in Form von Kurven dargestellt,
              den Lichtstärkeverteilungskurven (LVK) &ndash; siehe Lichtstärkeverteilung
            </p>
            <img src="@/assets/help/lighting/lighting_lichtstärke.png" />
            <figcaption>
              Abbildung: Visualisierung der Lichtstärke
            </figcaption>
            <h3>Lichtstärkeverteilung </h3>
            <p>
              Die Lichtstärkeverteilung beschreibt die räumliche Verteilung der
              Lichtstärke. Form und Symmetrie der Lichtstärkeverteilung kennzeichnen
              tief- und breitstrahlende bzw. symmetrisch und asymmetrisch strahlende
              Leuchten oder Reflektorlampen.
            </p>
            <p>
              Zur Darstellung dient die Lichtstärkeverteilungskurve (LVK): Sie entsteht,
              wenn die in Polarkoordinaten dargestellten Lichtstärken einer Leuchte oder
              Reflektorlampe in ihren verschiedenen Ausstrahlungsrichtungen miteinander
              zu einem Kurvenzug verbunden werden.
            </p>
            <img
              src="@/assets/help/lighting/lighting_lichtstaerkeverteilung.png"
            />
            <figcaption>
              Abbildung: Visualisierung der Lichtstärkeverteilung
            </figcaption>
            <h3>
              Lichtausbeute in Lumen/Watt (lm/W) 
            </h3>
            <p>
              Die Lichtausbeute ist das Maß für die Wirtschaftlichkeit einer Lampe. Sie
              sagt aus, wie viel Lichtstrom in Lumen (lm) pro Watt (W) ihrer
              Leistungsaufnahme eine Lampe erzeugt. Je höher das Verhältnis Lumen/Watt,
              desto besser setzt eine Lampe die eingebrachte Energie in Licht um. Einige
              Beispiele:
            </p>
            <ul>
              <li>Allgebrauchsglühlampe 12 lm/W,</li>
              <li>Halogenlampe 20 lm/W,</li>
              <li>Energiesparlampe 60 lm/W,</li>
              <li>stabförmige Leuchtstofflampe 90 lm/W</li>
              <li>LEDs 100-120 lm/W.</li>
            </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Quellen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul>
            <li>
              Die Definitionen und Abbildungen des Kapitels 4 sind der Webseite
              <a href="http://www.licht.de">http://www.licht.de</a> entnommen. Dort
              erhalten Sie auch weiterführende Begriffserklärungen, etc.
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Lighting1Help"
};
</script>

<style scoped>
</style>