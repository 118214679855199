<template>
  <v-form @submit.prevent="" ref="form">
    <v-autocomplete
      v-model="mainGeneratedEnergy"
      :error-messages="fieldErrors.mainGeneratedEnergy"
      label="Primär erzeugte Energieform"
      :items="energies"
      item-text="name"
      item-value="id"
      :rules="validationErrors.main_generated_energy"
      validate-on-blur
    >
      <v-tooltip v-if="hint.main_generated_energy" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.main_generated_energy }}</span>
      </v-tooltip>
    </v-autocomplete>

  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import { cacheAdapter } from "@/cache";

export default {
  name: "FreeGeneratorFormShared",
  extends: BaseForm,

  data() {
    return {
      mainGeneratedEnergy: null,

      fields: {
        mainGeneratedEnergy: "main_generated_energy"
      },

      energies: []
    };
  },

  mounted() {
    this.fetchEnergies();
  },

  methods: {
    fetchEnergies() {
      this.$http
        .get("/api/v1/methods/freegenerator/energies/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.energies = response.data;
        })
        .catch(() => {});
    }
  }
};
</script>
