// https://codepen.io/dagalti/pen/ywRNYx
<template>
  <div>
    <v-navigation-drawer
      ref="drawer"
      app
      right
      :width="sidebar.width"
      v-model="sidebar.shown"
      clipped
      class="secondary"
      mobile-breakpoint=0
    >
      <div v-if="help" :is="help" class="markdown-body rounded-0"/>
        <div v-else>
          <NewMethod class="markdown-body" />
        </div>

    </v-navigation-drawer>
    <v-layout>
      <v-btn
        @click="sidebar.shown = !sidebar.shown"
        color="primary" dark
      >
        <v-icon v-if="!sidebar.shown" left>mdi-help</v-icon>
          <v-icon v-else left>mdi-close</v-icon>
        Hilfe
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
import "@/assets/StyleMethodHelp.css";
import NewMethod from "@/components/help/NewMethod.vue";

export default {
  name: "Helpbar",
  components: {
    NewMethod
  },
  props: {
    help: null,
  },
  data: () => {
    return {
      sidebar: {
        shown: false,
        width: 700,
        borderSize: 10
      }
    };
  },
  computed: {
    direction() {
      return this.sidebar.shown === false ? "Open" : "Closed";
    }
  },
  mounted() {
    this.setBorderWidth();
    this.setEvents();
  },
  methods: {
    setBorderWidth() {
      let i = this.$refs.drawer.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.sidebar.borderSize + "px";
      i.style.cursor = "ew-resize";
      i.style.backgroundColor = "grey lighten-2";
    },
    setEvents() {
      const minSize = this.sidebar.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.sidebar.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    }
  }
}
</script>
  
<style scoped>
  .markdown-body {
  box-sizing: border-box;
  min-width: 400px;
  /* max-width: 980px; */
  margin: 0 auto;
  padding: 25px 10px 25px 25px;
}
@media (max-width: 767px) {
  .markdown-body {
    padding: 15px;
  }
}
</style>
