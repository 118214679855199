<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h1>Nassläuferpumpe (überschlägig)</h1>

    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Schritte vor der Berechnung</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol type="1">
            <li>
              <b>Einsparpotential und Wirtschaftlichkeit mit EARNS-Potentialanalyse 
                abschätzen anhand der Typenschilddaten</b>
              <p>Erste Annahmen hierbei:</p>
                <ul>
                  <li>
                    Die Bestands-Pumpe läuft im Auslegungspunkt gemäß Typenschild
                  </li>
                </ul> 
              </li> 
              <li> 
                <b>Bedarf überprüfen und mit aktuellem Betriebspunkt vergleichen</b>
                <p>
                  Häufig ist die Bestands-Pumpe größer dimensioniert als erforderlich. 
                  Dies kann z.B. daran liegen, dass die beheizten Bereiche umgenutzt wurden 
                  und keine Anpassung des Heizbedarfs und der Heizwassermenge erfolgte, oder 
                  weil es früher häufig üblich war eine große Sicherheit einzuplanen. 
                  Ziel ist es, die neue Pumpe so klein wie erforderlich auszulegen. 
                  Hierzu lohnt es sich, mindestens folgende Punkte zu prüfen:
                </p>
                <ul>
                  <li>
                    Ist die Pumpe überdimensioniert? In Heizungsanlagen liegt oft 
                    eine Überdimensionierung um bis zu 100% vor.
                  </li>
                  <li>
                    Übereinstimmung der Betriebszeiten der Anlage mit den 
                    Nutzungszeiten der versorgten Zone. Läuft die Pumpe länger als eigentlich erforderlich?
                  </li>
                  <li>
                    Wurden Gebäudeteile stillgelegt, ohne dass die zu bewegende 
                    Heizwassermenge reduziert wurde?
                  </li>
                  <li>
                    Kann durch einen hydraulischen Abgleich die benötigte 
                    Heizwassermenge reduziert werden?
                  </li>
                </ul>
              </li> 
              <li>
              <b>Auslegungs-Betriebspunkt für die neue Pumpe definieren</b>
              </li> 
              <li> 
                <b>Einsparpotential und Wirtschaftlichkeit mit EARNS-
                  Potentialanalyse abschätzen.</b>
                <ul>
                  <li>
                    Anhand der gemessenen Daten (Bestandspumpe) und des neuen 
                    Auslegungspunktes (Neupumpe). In dem EARNS-Potentialanalyse-
                    Report wird eine neue Pumpe vorgeschlagen.
                  </li>
                </ul>
              </li> 
              <li> 
              <b>Überprüfen der baulichen Einbausituation für die Konzeption des 
                Neusystems</b>
              <ul>
                <li>
                  Hierdurch scheiden evtl. bereits gewisse Lösungen für das 
                  Neusystem aus
                </li>
              </ul>
              </li> 
              <li> 
              <b>Überprüfung der Pumpe</b>
              <ul>
                <li>
                  Anhand von Herstellersoftware sollte die für den jeweiligen 
                  Anwendungsfall (Einbausituation, Betriebspunkt, Kosten) optimal 
                  geeignete Pumpe ermittelt werden.
                </li>
              </ul>
              </li> 
              <li> 
              <b>Kosten für die gewählte Systemlösung ermitteln</b>
              <ul>
                <li>
                  unter Berücksichtigung aller relevanten Faktoren wie Aufwand für 
                  Demontage, Umbau, Einbringung, Entleeren, Anschlussarbeiten, 
                  Inbetriebnahme, etc.
                </li>
              </ul>
              </li> 
              <li> 
              <b>Angebotspreis für die gewählte Systemlösung kalkulieren</b>
              </li> 
              <li> 
              <b>Finale Einsparhöhe gemäß der gewählten Systemlösung berechnen</b>
              </li> 
              <li> 
              <b>Finale Wirtschaftlichkeit (Einsparung, Rendite, Amortisation) 
                anhand des Angebotspreises und der finalen Einsparhöhe ermitteln
              </b>
            </li>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Parameter der Anlage</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Anzahl</td>
                <td>
                  Anzahl der Nassläuferpumpen des gleichen Typs und der gleichen
                  Betriebsweise.
                </td>
              </tr>
              <tr>
                <td>Nennweite</td>
                <td>Auswahl der Nennweite in mm</td>
              </tr>
              <tr>
                <td>Norm-Betriebsstunden</td>
                <td>Auswahl ob Norm-Betriebsstunden</td>
              </tr>
              <tr>
                <td>Norm-Betriebsstunden</td>
                <td>Auswahl der Norm-Betriebsstunden</td>
              </tr>
              <tr>
                <td>Jährliche Betriebsstunden</td>
                <td>Jährliche Betriebsstunden in h/a</td>
              </tr>
              <tr>
                <td>Regelung</td>
                <td>Auswahl der Regelung.</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungsgrundlage und Daten</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Zusätzliche Informationen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-container>
            <h3>Energieeffizienzklassen für Pumpen</h3>
            <table>
              <thead>
                <tr>
                  <th>Klasse</th>
                  <th>Energie Effizienz Index (EEI)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A+++</td>
                  <td>EEI &lt; 0,1</td>
                </tr>
                <tr>
                  <td>A++</td>
                  <td>0,1 &leq; EEI &lt; 0,16</td>
                </tr>
                <tr>
                  <td>A+</td>
                  <td>0,16 &leq; EEI &lt; 0,23</td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>0,23 &leq; EEI &lt; 0,3</td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>0,3 &leq; EEI &lt; 0,42</td>
                </tr>
                <tr>
                  <td>C</td>
                  <td>0,42 &leq; EEI &lt; 0,6</td>
                </tr>
                <tr>
                  <td>D</td>
                  <td>0,6 &leq; EEI &lt; 8</td>
                </tr>
                <tr>
                  <td>E</td>
                  <td>0,8 &leq; EEI &lt; 0,9</td>
                </tr>
                <tr>
                  <td>F</td>
                  <td>0,9 &leq; EEI &lt; 1</td>
                </tr>
                <tr>
                  <td>G</td>
                  <td>1 &leq; EEI</td>
                </tr>
              </tbody>
            </table>
            <p>
              Der Energieeffizienzindex (EEI) einer Pumpe ist das Verhältnis der
              gewichteten, mittleren Leistungsaufnahme (ermittelt in einem
              standardisierten Lastprofil) zur Bezugsleistung. Die Bezugsleistung
              bezieht sich auf das Verhältnis zwischen der hydraulischen und der
              elektrischen Leistung der Pumpe unter Berücksichtigung der
              Größenabhängigkeit. Zusätzlich wird bei der Berechnung des EEI ein Faktor
              eingerechnet, der sicherstellen soll, dass höchstens 20 Prozent der am
              Markt verfügbaren Produkte einen EEI von 0,20 erreichen.
            </p>
            <p>
              Nach ErP-Richtlinie für Nassläufer-/ Umwälzpumpen (Verordnung (EG) 641/2009
              und (EU) 622/2012) müssen alle neuen Umwälzpumpen ab August 2015 einen
              mindest-Energieeffizienzindex von 0,23 erfüllen, die in neu in den Markt gebrachten
              Wärmeerzeugern oder Solarstationen eingebaut sind (integrierte Pumpen). 
              Seit 2020 gelten die Vorgaben auch für den Austausch integrierter Pumpen im Bestand.
              Ausgenommen sind Trinkwasserzirkulationspumpen (s. Abb. 2)
            </p>

            <h3>Neue Pumpentechnologie</h3>
            <span><b>Wilo-Stratos / Wilo-Stratos PICO</b></span>
            <p>
              Die Wilo-Stratos ist eine Baureihe für Hocheffizienzpumpen des Herstellers
              Wilo. Angetrieben werden die Pumpen durch einen hocheffizienten EC-Motor.
              EC-Motoren haben gegenüber konventionellen AC-Motoren den Vorteil, dass
              sich im Rotor des Motors keine Spulen, sondern ein starker Dauermagnet
              befindet, der bereits ohne das Aufwenden von Energie das benötigte
              magnetische Feld aufbaut. Benötigt wird aber zusätzlich eine
              VSD-Drehzahlregelung, die i.d.R. im Motor integriert wird. Insgesamt
              ergeben sich für EC-Motoren wesentlich bessere Wirkungsgrade als für
              AC-Motoren, vor allem im Teillastbetrieb. Mit einem EEI ≤0,23 wird somit
              die Wilo-Stratos Baureihe auch den zukünftigen Anforderungen an die
              Energieeffizienz gerecht. Die Leistungsaufnahme liegt je nach Bedarfsfall
              zwischen 9W und 1150W mit einer maximalen Förderhöhe von 13m und einem
              maximalen Förderstrom von 62m³/h.
            </p>
            <p>
              Über eine Kommunikationsschnittstelle für alle gängigen Protokolle
              (Modbus, BACnet, CAN, PLR, LON) können die Pumpen direkt an die
              Gebäudeautomation angebunden werden. Für kleine Lasten ist die
              Wilo-Stratos-PICO noch effizienter mit einem EEI≤0,2 und einer
              Leistungsaufnahme von 3W bis 40W bei einer maximalen Förderhöhe von 6m
              und einem maximalen Förderstrom von 3,5m³/h.
            </p>
            <span><b>Andere Pumpenfabrikate</b></span>
            <p>
              Auch andere große Pumpenhersteller bieten Nassläuferpumpen mit
              EC-Motorentechnologie an, wie zum Beispiel Grundfos Baureihe Magna, KSB
              mit der Rio-Eco oder DAB mit der Baureihe EVOPLUS.
            </p>
            <img src="@/assets/help/wetpump/wilo-stratos.png" alt="Wilo Stratos" />
            <figcaption>Quelle: Wilo</figcaption>
            <h2>Nassläuferpumpe</h2>
            <img src="@/assets/help/wetpump/wetpump.png" alt="Nassläuferpumpe" />
            <figcaption>Quelle: Wilo</figcaption>
            <p>
              Elektromotoren sind in Europa die wichtigsten Stromverbraucher: Sie sind
              für rund 70 % des industriellen Stromverbrauchs und ein Drittel des
              Stromverbrauchs im Dienstleistungssektor verantwortlich. Fast 20 % des
              weltweiten Stromverbrauchs von Motoren entfallen dabei auf Pumpen. Eine
              Reduzierung des Energieverbrauchs kommt nicht nur der Umwelt – durch
              Ressourcenschonung und weniger klimaschädliches CO2 – zugute, sondern
              auch der Nachhaltigkeitsbilanz der Unternehmen. Denn Stromkosten machen
              den größten Teil der Lebenszykluskosten einer Pumpe aus – bis zu 85 %.
              Das weltweite Einsparpotenzial allein durch die Anwendung moderner
              Pumpentechnik beläuft sich auf bis zu 246 TWh, das entspricht einer
              Größenordnung von 24 Atomkraftwerken.
            </p>

            <h3>Optimierungsmöglichkeiten</h3>
            <p>
              Folgende Maßnahmen haben wesentlichen Einfluss auf die Energiekosten einer
              Nassläuferpumpe:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Kategorie</th>
                  <th>Beschreibung</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Motor</td>
                  <td>Austausch der Pumpe durch neue energieeffiziente Pumpe</td>
                </tr>
                <tr>
                  <td>Dimensionierung</td>
                  <td>Anpassen der Pumpengröße an den tatsächlichen Bedarf</td>
                </tr>
                <tr>
                  <td>Regelung / Steuerung</td>
                  <td>
                    Einsatz einer stufenlosen Drehzahlregelung (Variable Speed Drive
                    VSD)
                  </td>
                </tr>
                <tr>
                  <td>Betriebszeit</td>
                  <td>
                    Anpassung der Betriebszeit an die tatsächlichen Nutzungszeiten
                  </td>
                </tr>
                <tr>
                  <td>Verbraucher</td>
                  <td>Systemoptimierung zur Reduzierung des Bedarfs</td>
                </tr>
              </tbody>
            </table>

          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>
        Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>

  </div>
</template>

<script>

export default {
  name: "WetPump1Help"
};
</script>

<style scoped>
</style>