<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h1>Heizkessel</h1>

    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Schritte vor der Berechnung</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h2>Hinweise zur Durchführung der Feinanalyse</h2>
          <p>Das größte Energieeinsparpotenzial bei bestehenden Anlagen liegt 
            oft im Austausch des Kessels. Denn ältere Exemplare haben erhebliche 
            Bereitschaftsverluste, zusätzlich sind viele Systeme überdimensioniert. 
            Dies liegt oftmals an zwischenzeitlich erfolgten energetischen Verbesserungen 
            der Gebäudehülle, aber auch daran, dass vielfach von vornherein zu 
            große Kessel installiert wurden.</p>
          <p>Bei einer anstehenden Kesselerneuerung sollte zuerst geklärt werden, 
            ob der vorhandene Energieträger weiter genutzt werden soll. Welche Art 
            der Wärmeerzeugung dann gewählt wird, ist im wesentlichen von dem 
            Temperaturniveau des vorhandenen Heizungssystems abhängig.</p>
          <ol type="1">
            <li>
            <b>Ermittlung des tatsächlichen Wärmebedarfs</b>
            <p>Im ersten Schritt wird eine Ist-Analyse durchgeführt, bei der der 
              Energieverbrauch und der tatsächliche Wärmebedarf ermittelt werden. 
              Da viele Kessel zu groß dimensioniert sind, sollte der tatsächliche 
              Wärmebedarf ermittelt werden. Hierzu bestehen z.B. folgende Möglichkeiten:</p>
            <ul>
                <li>Rechnerische Ermittlung des Wärmebedarfs</li>
                <li>Tägliche Messungen oder Ablesungen des Brennstoff/Gasverbrauchs</li>
                <li>Wärmefluss-Messung mit Ultraschallmessgerät über längeren Zeitraum</li>
            </ul>
            </li>
            <li>
            <b>Neuen Kessel entsprechend dem reduzierten Wärmebedarf dimensionieren</b>
            <p>Anschließend werden Möglichkeiten der Wärmebedarfsreduzierung (Nutzung 
              Wärmerückgewinnung, Veränderung Produktionsprozess, Gebäudemaßnahmen, 
              Wärmeverluste) geprüft und ggf. angesetzt, um die neue Kesselgröße zu dimensionieren.</p>
            </li>
            <li>
            <b>Ermittlung des tatsächlichen Jahres-Nutzungsgrades (Bestand)</b>
            <ul>
                <li>Mit Hilfe von Brennstoffzähler und Wärmemengenzähler / Ultraschallmessgerät.</li>
            </ul>
            <p>Hilfsweise über:</p>
            <ul>
                <li>periodische Messung des CO2-Gehaltes der Abgase, der Abgastemperatur, 
                  der Rußziffer und des Feuerraumdrucks</li>
                <li>Mittels Oberflächentemperaturfühler wird der Oberflächenverlust des 
                  Heizkessels bzw. des Energieerzeugers errechnet.</li>
                <li>Der Wärmeverlust im Abgassystem nach Abschalten des Brenners, der 
                  sogenannte Ventilationsverlust, wird gemessen.</li>
            </ul>
            </li>
            <li>
            <b>Folgende weitere Punkte sollten geprüft werden:</b>
            <ul>
                <li>hydraulisches Entkoppeln der Wärmeerzeuger bei Mehrkesselanlagen</li>
                <li>automatisch arbeitende Folgeschaltung für Mehrkesselanlagen</li>
                <li>Anpassen der Heizkurven und gegebenenfalls Reduzieren der Vorlauf- und 
                  Kesseltemperatur</li>
                <li>Installieren von automatisch arbeitenden Abgas oder Verbrennungsluftklappen</li>
                <li>hydraulischer Abgleich des Heizungssystems</li>
                <li>Ergänzung, Verbesserung oder Erneuerung der Wärmedämmung an den 
                  Wärmeerzeugern, den Armaturen und der Wärmeverteilung</li>
            </ul>
            </li>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Parameter der Anlage</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Baujahr</td>
                <td>Eingabe des Baujahrs, falls das Baujahr nicht vorhanden ist, das nächst mögliche verwenden.</td>
              </tr>
              <tr>
                <td>Bauart</td>
                <td>
                  Auswahl der Kesselart, um einen Kessel durch eine Wärmepumpe 
                  (teilweise) zu ersetzen, kann die Methode sonstiger Erzeuger verwenden werden.
                </td>
              </tr>
              <tr>
                <td>Nennleistung</td>
                <td>Eingabe der auf dem Typenschild angegebenen Nennleistung in kW</td>
              </tr>
              <tr>
                <td>Brennstoff</td>
                <td>Auswahl des Brennstoffs. Dieser ist abhängig von der Bauart des Kessels.</td>
              </tr>
              <tr>
                <td>Vorlauftemperatur</td>
                <td>
                  Auswahl der Vorlauftemperatur. Falls nicht der reale Wert vorhanden 
                  ist, kann der Werte am nächsten genommen werden. Es sollte beachtet werden, 
                  dass die Temperaturspreizung genau so wenig von der Realität abweicht.
                </td>
              </tr>
              <tr>
                <td>Rücklauftemperatur</td>
                <td>Auswahl der Rücklauftemperatur. Falls nicht der reale Wert vorhanden 
                  ist, kann der Werte am nächsten genommen werden. Es sollte beachtet werden, 
                  dass die Temperaturspreizung genau so wenig von der Realität abweicht.</td>
              </tr>
              <tr>
                <td>Temperaturgleitung</td>
                <td>Temperaturgleitung bedeutet, dass das Temperaturniveau lastabhängig angepasst wird.</td>
              </tr>
              <tr>
                <td>Jährliche Betriebsstunden</td>
                <td>
                  Gesamte Betriebszeit pro Jahr in h. Für einen 24-Stunden-
                  Betrieb an 365 Tagen im Jahr geben Sie 8760 Stunden ein.
                </td>
              </tr>
              <tr>
                <td>Energiebedarf (Referenz)</td>
                <td>Gesamter Energieverbrauch im Jahr in kWh/a</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungsgrundlage und Daten</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Ein Wärmeerzeuger kann anhand verschiedener Energieeinzelkennwerte 
            beschrieben werden, zum einen anhand seiner absoluten Wärmeverluste, 
            zum anderen auch durch Aufwandszahlen oder Nutzungsgrade.
          </p>
          <p>
            Die Verluste jedes beliebigen Erzeugers können in "Verluste des Betriebes" 
            und "fixe Verluste" unterschieden werden. Für Kessel sind ähnliche Größen
            bereits heute gängige Werte. Annähernd fixe Verluste sind Bereitschaftsverluste 
            außerhalb der Betriebszeit sowie Strahlungsverluste während der Betriebszeit. 
            Verluste des Betriebes sind Abgasverluste und Vorspülverluste des Brenners vor 
            dem Brennerstart. Die Betriebszeit ist dabei die Zeit, in der die wirkliche 
            Energieumwandlung erfolgt - eine Vorspülung vor dem Brennerstart gehört damit eigentlich 
            nicht zur Betriebszeit, wird jedoch aus Vereinfachungsgründen häufig dazugerechnet.
          </p>
          <p>
            Der Jahresnutzungsgrad eines Wärmeerzeugers (meist ηa oder einfach η) ist ein Energiekennwert, 
            der vor allem in den konventionellen Energiebilanzverfahren verwendet wird. Er ist das 
            Verhältnis der Energie, die aus dem Wärmeerzeuger fließt (vereinfacht "Nutzen" genannt), 
            zu der Energie, die in den Erzeuger fließt (üblicherweise "Aufwand"). Betrachtungszeitraum 
            für diese Angabe ist ein Jahr. Weil beide Energiemengen dieselbe Einheit haben, ist der 
            Nutzungsgrad eine dimensionslose Größe. Bei der Betrachtung des Aufwandes und des Nutzens 
            eines Erzeugers spielt es keine Rolle, um welche Energieformen es sich handelt. Alle 
            Energieträger werden gleich behandelt. Regenerative Energien werden nicht in die Bilanz 
            einbezogen. 
          </p>
          <p>
            Die Aufwandszahl des Wärmeerzeugers (meist eg) ist der Kehrwert des Nutzungsgrades, 
            es wird also "Aufwand" durch "Nutzen" geteilt. Sie ist damit ebenfalls eine dimensionslose Kenngröße.
          </p>
          <p>
            Die Wirkungsgrade für die Referenz- und Geplante-Anlage werden nach DIN 4701-10 / -12 berechnet.
          </p> 
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Zusätzliche Informationen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-container>
            <h2>Heizkessel</h2>
            <img src="@/assets/help/boiler/boiler.png" alt="Kessel">
            <h3>Kesseltypen</h3>

            <h3>Gas-Spezial-Heizkessel</h3>
            <p>Ein Gas-Spezial-Heizkessel ist ein meist bodenstehender atmosphärischer 
              Kessel ohne Gebläseunterstützung, der speziell für die Nutzung mit Gas 
              gebaut wurde. Die Verbrennungsluft wird durch die Sogwirkung des Gasstromes 
              in die Brennkammer gezogen.</p>

            <h3>NT-Gas-Spezial-Heizkessel</h3>
            <p>Ein NT-Gas-Spezial-Heizkessel (Niedertemperatur-Gas-Spezial-Heizkessel) 
              ist ein meist bodenstehender atmosphärischer Kessel ohne Gebläseunterstützung, 
              der speziell für die Nutzung mit Gas gebaut wurde. Die Verbrennungsluft wird 
              durch die Sogwirkung des Gasstromes in die Brennkammer gezogen. </p>

            <h3>NT-Gebläsekessel</h3>
            <p>Ein Gebläsekessel ist ein Heizkessel, der wahlweise mit einem Öl- oder 
              Gasgebläsebrenner ausgerüstet werden kann. Beim Gebläsekessel wird die 
              durch den Brenner freigesetzte Feuerungswärme des Brennstoffes an das 
              Heizwasser im Heizkessel übertragen.</p>
            <p>Seit den 80er-Jahren sind „Niedertemperaturheizkessel“ (abgekürzt NT-Kessel) 
              auf dem Markt. Das Besondere an diesen Kesseln ist, dass sie auch mit 
              niedrigen Kesseltemperaturen betrieben werden können: Die Kesseltemperatur 
              ändert sich (gleitende Betriebsweise) oder ist konstant niedrig.</p>
            <p>Nach § 2 Begriffsbestimmungen in der Energieeinsparverordnung (EnEV) 
              „ist ein Niedertemperatur-Heizkessel ein Heizkessel, der kontinuierlich 
              mit einer Eintrittstemperatur von 35 bis 40 Grad Celsius betrieben werden 
              kann und in dem es unter bestimmten Umständen zur Kondensation des in den 
              Rauchgasen enthaltenen Wasserdampfes kommen kann.“</p>

            <h3>Brennwertkessel</h3>
            <p>Ein Brennwertkessel ist ein Heizkessel, der für die Kondensation eines 
              Großteils des in den Abgasen enthaltenen Wasserdampfes konstruiert ist. 
              Brennwertkessel bieten heute die bestmögliche Weise der Öl- und 
              Gasverbrennung für Heizzwecke. Wegen des besonders hohen energetischen 
              Zusatznutzens bei Gas (bis zu 15 % gegenüber dem Niedertemperaturkessel) 
              kann der Brennwertkessel in Verbindung mit diesem Brennstoff inzwischen 
              bereits als Standard im Neubau gelten</p>

            <h3>Hackschnitzelkessel und Pelletkessel</h3>
            <p>Auf dem Boden stehender Festbrennstoff-Kessel, der durch Verbrennung 
              von Pellets oder Hackschnitzel warmes Heizwasser und ggf. Warmwasser 
              produziert. Holzpellets werden unter hohem Druck aus getrocknetem, 
              naturbelassenem Restholz (Sägemehl, Hobelspäne, Waldrestholz) mit einem 
              Durchmesser von ca. 6 - 8 mm und einer Länge von 5 - 45 mm gepresst. 
              Holzhackschnitzel sind zerkleinertes Holz (Hackgut), zum Beispiel aus 
              Waldholz, Sägerestholz oder Holz aus der Ver- und Bearbeitung.</p>
            <h4>Prinzipieller Aufbau</h4>
            <img src="@/assets/help/boiler/pellet_aufbau.png" alt="Aufbau">
            <p>Pellet- und Hackschnitzelanlagen bestehen in der Regel aus den folgenden Komponenten:</p>
            <ul>
                <li>Feuerungsanlage</li>
                <li>Austragungssystem</li>
                <li>Brennstofflager</li>
                <li>Wärmeabgabesystem (ggf. mit Pufferspeicher)</li>
                <li>Abgasanlage</li>
                <li>Ascheaustragungssystem</li>
            </ul>
            <p>Im <u>Brennstofflager</u> werden die Holzpellets oder Hackschnitzel 
              zwischengelagert und automatisch durch das Austragungssystem zur 
              Feuerungsanlage transportiert. Das Lager wird in der Regel so ausgelegt, 
              dass dort Brennmaterial für 3 Monate plus 14 Tage Reserve lagern kann. 
              Die Größe hängt von der Leistung der Heizungsanlage ab. Das Lager sollte 
              sich möglichst nah an dem Heizungsraum befinden.</p>
            <p>Das <u>Austragungssystem</u> wird über das Regelungssystem der 
              Heizungsanlage gesteuert und führt der Feuerungsanlage bedarfsgerecht
              Brennstoff zu. Bei Hackschnitzelanlagen kommen in der Regel Förderschnecken 
              zum Einsatz, bei Pelletanlagen sind es Förderschnecken oder Saugleitungen.</p>
            <p>In der <u>Feuerungsanlage</u> wird der Brennstoff verbrannt und die 
              Nutzwärme über ein Wärmeabgabesystem abgeführt. Die Leistung wird über 
              die Brennstoff- und die Sauerstoffzufuhr geregelt. Es empfiehlt sich ein 
              Pufferspeicher, um möglichst viele Vollastbetriebsstunden zu erreichen.</p>
            <p>An die Feuerungsanlage angeschlossen werden noch eine Abgasanlage, 
              bestehend aus einem Schornstein und ggf. einer Rauchgasreinigungsstation, 
              und ein Ascheaustragungssystem, welches häufig in Form einer Rüttelvorrichtung 
              vorliegt und die Asche in einen unter der Feuerungsanlage befindenden 
              Sammelbehälter entsorgt.</p>

            <h4>Wirkungsgrade</h4>
            <p>Moderne Hackschnitzelanlagen sind stufenlos bis ca. 30% der Volllast 
              regelbar. Unter Volllast besitzen sie einen feuerungstechnischen Wirkungsgrad 
              von 90 – 93%. Im Teillastbereich deutlich weniger. Teillasten unter 30% 
              können nur durch Taktung der Anlage erreicht werden, wodurch der 
              Kesselwirkungsgrad auf Grund von Anlaufverlusten sinkt. Dem entsprechend sind 
              Kleinanlagen auf Grund ihrer höheren Kesselwirkungs- und Jahresnutzungsgrade 
              Großanlagen gegenüber zu bevorzugen.</p>
            <p>Moderne Pelletanlagen sind ebenfalls stufenlos regelbar bis auf 30%. Unter 
              Volllast besitzen sie einen feuerungstechnischen Wirkungsgrad von 85-95 %, 
              als Brennwertkessel einen Wirkungsgrad von ~106%.</p>
            <p>Im Vergleich zur Feuerung mit fossilen Energieträgern können 90% der 
              Klimagasemissionen eingespart werden.</p>
            <p>
              Der Austausch eines alten bestehenden Heizkessels ist sehr wirtschaftlich 
              und es besteht dadurch sogar die Möglichkeit den Brennstoff zu wechseln. 
              Der Austausch des bestehenden Heizkessels auch durch Verordnungen 
              vorgegeben. Seit 2024 gilt das neue Gebäudeenergiegesetz (GEG).
              Gemäß dem müssen fossile Energieträger beim Heizen in den nächsten Jahren 
              schrittweise ersetzt werden. Folgendes gilt hierbei:
            </p>
            <ul>
                <li>Ab 2024 dürfen in Neubauten von Neubaugebieten nur noch Heizungen 
                  installiert werden, die zu mindestens 65 Prozent mit erneuerbaren Energien laufen.</li>
                  <li>Für Bestandsgebäude und Neubauten in Baulücken sind Übergangsfristen vorgesehen.</li>
                <li>Defekte Gas- oder Ölheizungen dürfen repariert werden, bei Havarien gibt es Übergangsfristen.</li>
                <li>Austauschpflicht für Öl-und Gasheizungen, die über 30 Jahre alt sind.</li>
            </ul>
            <p>Nach wie vor genießen folgende Heizungsanlagen einen Bestandsschutz und 
              Eigentümer können sie weiter betreiben:</p>
            <ul>
                <li>Niedertemperatur-Heizkessel,</li>
                <li>Brennwert-Heizkessel,</li>
                <li>Heizungsanlagen mit einer Nennleistung unter 4 kW oder über 400 kW,</li>
                <li>Heizkessel für marktunübliche flüssige oder gasförmige Brennstoffe,</li>
                <li>Anlagen, mit denen nur das warme Wasser bereitet wird,</li>
                <li>Küchenherde,</li>
                <li>Geräte, die hauptsächlich ausgelegt sind, um den Raum, in dem sie aufgestellt sind, zu beheizen, 
                  welche jedoch auch Warmwasser für die Zentralheizung und für sonstigen Gebrauch liefern.</li>
            </ul>

            <h3>Optimierung</h3>
            <p>Folgende Maßnahmen haben wesentlichen Einfluss auf die Effizienz eines Wärmeerzeugungs-Systems:</p>
            <table>
                <thead>
                    <tr>
                        <th>Massnahme</th>
                        <th>Beschreibung</th>
                        <th>EARNS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Kesseltausch</td>
                        <td>Austausch eines Kessels durch einen neuen Kessel</td>
                        <td>möglich</td>
                    </tr>
                    <tr>
                        <td>Brennstoffwechsel</td>
                        <td>Verwendung eines anderen Brennstoffes</td>
                        <td>möglich</td>
                    </tr>
                    <tr>
                        <td>Überdimensionierung</td>
                        <td>Anpassen der Kesselgröße an den tatsächlichen Bedarf</td>
                        <td>möglich</td>
                    </tr>
                    <tr>
                        <td>Temperaturniveau</td>
                        <td>Reduzierung des Temperaturniveaus</td>
                        <td>nur Erzeugerseite</td>
                    </tr>
                    <tr>
                        <td>Brennertausch</td>
                        <td>Anpassen des Brenners an den tatsächlichen Bedarf, ggf. mit Brennstoffwechsel auf Erdgas</td>
                        <td>momentan nicht möglich</td>
                    </tr>
                    <tr>
                        <td>Regelung</td>
                        <td>Optimierung der Regelfunktionen und der Kesselfolgeschaltung</td>
                        <td>momentan nicht möglich</td>
                    </tr>
                </tbody>
            </table>
            <p>
              Mit dem EARNS-Modul lässt sich der Tausch eines Kessels (mit ggf. erforderlichem Brennstoffwechsel) 
              in einer Einkessel-Anlage berechnen. Dabei wird ein Heizsystem mit VL-/RL-temperaturen von 70/55°C 
              zugrunde gelegt. Wird bei einem Kesseltausch ein anderer Brennstoff eingesetzt (z.B. Erdgas anstelle 
              Heizöl, oder Holzpellets anstelle Erdgas), so wird neben der Änderung des Wirkungsgrades auch der 
              durch den Brennstoffwechsel ggf. entstehende Kostenvorteil berücksichtigt.
            </p>
            <p>Für komplexere Erzeugersysteme sprechen Sie uns gerne an.</p>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>
        Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>

  </div>
</template>

<script>
export default {
  name: "BoilerHelp"
}
</script>

<style scoped>

</style>