<template>
  <div v-if="project">
    <v-card>
      <v-row no-gutters>
        <v-card-title class="headline">
          Maßnahmen
        </v-card-title>
        <v-row no-gutters class="justify-end">
          <div class="d-flex">
            <v-container>
              <v-btn
                :disabled="!actionList.length"
                class="mb-6"
                @click="calcAction()"
                color="teal accent-3"
                bottom
              >
                <v-icon left>mdi-speedometer</v-icon>
                Analyse starten
              </v-btn>
              
            </v-container>
            <v-container>
              <Helpbar :help="ProjectDashboardHelp" />
            </v-container>
          </div>
        </v-row>
      </v-row>

      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">Gruppe</th>
              <th class="text-left">Name</th>
              <th class="text-left">Methode</th>
              <th class="text-right">Optionen</th>
            </tr>
          </thead>
          <template v-for="(actions, groupId) in byActionGroup">
            <tbody :key="groupId">
              <tr>
                <td v-if="groupId !== 'null'" class="group" colspan="4">
                  <p style="line-height: 35px;" class="mb-0">
                    <span>{{ getGroupName(groupId) }}</span>

                    <span style="float: right">

                    <v-row justify="end" align="center">
                     <v-checkbox
                        :input-value="!checkNoCalcGroup(getGroup(groupId))"
                        @click.stop="addNoCalcGroup(getGroup(groupId))"
                        no-gutters
                        title="Maßnahmengruppe berechnen"
                        >
                      </v-checkbox>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            title="Optionen"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-dialog
                              v-model="dialogDeleteGroup"
                              persistent
                              max-width="290"
                              :retain-focus="false"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  text block
                                  @click="storeGroupIdForDialog(groupId)"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Löschen
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title class="text-h5">
                                  Gruppe löschen
                                </v-card-title>
                                <v-card-text
                                  >Alle Maßnahmen werden aus Gruppe
                                  und Projekt gelöscht!</v-card-text
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="red"
                                    text
                                    @click="dialogDeleteGroup = false"
                                  >
                                    Abbrechen
                                  </v-btn>
                                  <v-btn text @click="deleteActionGroup(getGroup(groupId))">
                                    Löschen
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-list-item>
                          <v-list-item>
                            <v-btn
                            text block
                              @click.stop="cloneActionGroup(getGroup(groupId))"
                              >
                              Kopie erstellen
                            </v-btn>
                          </v-list-item>
                          <v-list-item>
                            <v-dialog
                              v-model="dialogRenameGroup"
                              persistent
                              max-width="600px"
                              :retain-focus="false"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  text block
                                  @click="
                                    storeGroupIdForDialog(groupId);
                                    newActionGroupName = getGroupName(groupId);
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Umbenennen
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">Gruppe umbennen</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-text-field
                                      label="Name"
                                      v-model="newActionGroupName"
                                    ></v-text-field>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="red"
                                    text
                                    @click="
                                      dialogRenameGroup = false;
                                      newActionGroupName = null;
                                    "
                                  >
                                    Abbrechen
                                  </v-btn>
                                  <v-btn
                                    text
                                    @click="renameActionGroup()"
                                    :disabled="!newActionGroupName"
                                  >
                                    Speichern
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                    </span>
                  </p>
                </td>
                <td v-else class="group" colspan="4">
                  ohne Zuordnung
                  </td>
              </tr>
              <tr
                v-for="action in actions"
                :key="action.id"
                class="pointer"
                @click="editAction(action)"
              >
                <td></td>
                <td class="text-left">
                  {{ action.name
                  }}<span v-if="action.partial_plan" class="ml-1 red--text"
                    >(Entwurf)</span
                  >

                </td>

                <td class="text-left">
                
                {{ checkMethodTexts(action) }}

                </td>

                <td class="text-right">

                  <v-row justify="end" align="center">
                  <v-checkbox
                    v-if="!action.group"
                    :input-value="!checkNoCalc(action)"
                    @click.stop="addNoCalc(action)"
                    no-gutters
                    title="Maßnahme berechnen"
                    >
                  </v-checkbox>
                  <v-menu offset-y rounded-xl>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        title="Optionen"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <delete-missile-switch activatorClass="ml-10" @click="deleteAction(action)">
                          Löschen
                        </delete-missile-switch>
                      </v-list-item>
                      <v-list-item>
                        <v-btn text block
                          @click.stop="cloneAction(action)"
                          >
                          Kopie erstellen
                        </v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text block
                              v-bind="attrs"
                              v-on="on"
                            >
                              Gruppe zuordnen
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in groups"
                              :key="index">
                              <v-row no-gutters>
                                <v-col cols="10">
                                  <v-btn text block @click="moveToGroup(item, action)">{{ item.name }}</v-btn>
                                </v-col>
                                <v-col cols="2">
                                  <delete-missile-switch @click="deleteOnlyActionGroup(item)" activatorIcon>
                                    <v-icon>mdi-delete</v-icon>
                                  </delete-missile-switch>
                                </v-col>
                              </v-row>
                            </v-list-item>
                            <v-list-item
                              v-for="(item, index) in nogroup"
                              :key="index">
                              <v-row no-gutters>
                                <v-col cols="10">
                                  <v-btn text block @click="moveToGroup(item, action)">{{ item.name }}</v-btn>
                                </v-col>
                              </v-row>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item>
                      <v-list-item>
                        <v-dialog
                          v-model="dialogRenameAction"
                          persistent
                          max-width="600px"
                          :retain-focus="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            text block
                              @click.stop="storeActionDataForDialog(action);newActionName = action.name"
                              v-bind="attrs"
                              v-on="on"
                              >
                              Umbenennen
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="text-h5">Maßnahme umbennen</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-text-field
                                  label="Name"
                                  v-model="newActionName"
                                ></v-text-field>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="red"
                                text
                                @click.stop="
                                  dialogRenameAction = false;
                                  newActionName = null;
                                "
                              >
                                Abbrechen
                              </v-btn>
                              <v-btn
                                text
                                @click="renameAction(newActionName, dialogActionData)"
                                :disabled="!newActionName"
                              >
                                Speichern
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  </v-row>

                </td>

              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row justify="end" no-gutters class="mt-4">
          <v-dialog
            v-model="dialogCreateGroup"
            persistent
            max-width="600px"
            :retain-focus="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" class="mr-5">
                <v-icon left>mdi-folder-plus</v-icon>
                Gruppe hinzufügen
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Gruppe hinzufügen</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-text-field
                    label="Name"
                    v-model="newActionGroupName"
                  ></v-text-field>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="
                    dialogCreateGroup = false;
                    newActionGroupName = null;
                  "
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  @click="newActionGroup"
                  :disabled="!newActionGroupName"
                >
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-btn @click="newAction" color="primary">
            <v-icon left>mdi-plus</v-icon>
            Maßnahme hinzufügen
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row justify="start" class="mt-2">
      <delete-missile-switch activatorClass="ma-3" @click="deleteProject">
        Projekt löschen
      </delete-missile-switch>

      <v-btn class="ma-3" @click="editProjectDetails" color="primary">
        <v-icon left>mdi-pencil</v-icon>
        Projektdetails bearbeiten
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import DeleteMissileSwitch from "@/components/DeleteMissileSwitch";
import Helpbar from "@/components/Helpbar";
import ProjectDashboardHelp from "@/components/help/ProjectDashboardHelp";

export default {
  name: "ProjectDashboard",
  components: { 
    DeleteMissileSwitch,
    Helpbar
  },
  data() {
    return {
      methodTexts: null,
      newActionGroupName: null,
      dialogGroupId: null,
      dialogCreateGroup: false,
      dialogDeleteGroup: false,
      dialogRenameGroup: false,
      newActionName: null,
      dialogActionData: null,
      dialogCreateAction: false,
      dialogDeleteAction: false,
      dialogRenameAction: false,
      nogroup: [
        { id: null,
          name: "ohne Zuordnung",
          project: this.projectId
        }
      ],
      ProjectDashboardHelp
    };
  },
  computed: {
    byActionGroup() {
      return this.actionList.reduce((acc, curr) => {
        (acc[curr.group] = acc[curr.group] || []).push(curr);
        return acc;
      }, {});
    },
    projectId() {
      return this.$route.params.projectId;
    },
    project() {
      return this.$store.state.currentProject;
    },
    actionList() {
      return this.$store.state.currentProjectActions || [];
    },
    groupsList() {
      return this.$store.state.currentProjectActionGroups || [];
    },
    groups() {
      return (this.$store.state.currentProjectActionGroups || []).reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {}
      );
    }
  },
  watch: {
    project: {
      handler(project) {
        if (project) {
          this.$store.commit("setTitle", this.project.name);
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    this.fetch();
    this.fetchMethodTexts();
  },
  methods: {
    checkNoCalc(item) {
      if (item != null) {
        return this.$store.state.noCalc.includes(item.id) ? true : false;
      }
    },
    checkNoCalcGroup(item) {
      if (item != null) {
        return this.$store.state.noCalcGroup.includes(item.id) ? true : false;
      }
    },
    addNoCalc(item) {
      this.$store.state.noCalc.includes(item.id) ?
      this.$store.state.noCalc = this.$store.state.noCalc.filter(i => i !== item.id) :
      this.$store.state.noCalc.push(item.id)
      
      this.fetch();
    },
    addNoCalcGroup(item) {
      let actionsInGroup = this.actionList.filter(i => i.group == item.id).map(i => i.id)
      const actionsInGroupSet = new Set(actionsInGroup);

      if (this.$store.state.noCalcGroup.includes(item.id)) {
        this.$store.state.noCalcGroup = this.$store.state.noCalcGroup.filter(i => i !== item.id)
        this.$store.state.noCalc = this.$store.state.noCalc.filter(x => !actionsInGroupSet.has(x))
      }
      else {
        this.$store.state.noCalcGroup.push(item.id)
        this.$store.state.noCalc.push(...actionsInGroup)
      }
      
      this.fetch();
    },
    checkMethodTexts(action) {
      if (this.methodTexts != null) {
      return this.methodTexts[action.method];
      }
    },
    fetchMethodTexts() {
      this.$http
        .get("/api/v1/method_text/")
        .then((response) => {
            this.methodTexts = response.data;
        })
    },
    getGroup(groupId){
      return this.groups[groupId];
    },
    getGroupName(groupId) {
      return this.groups[groupId] ? this.groups[groupId].name : "n/a";
    },
    fetch() {
      this.$store.dispatch("fetchCurrentProject", this.projectId);
    },
    storeGroupIdForDialog(id) {
      this.dialogGroupId = id;
    },
    storeActionDataForDialog(data) {
      this.dialogActionData = data
    },
    newActionGroup() {
      this.$http
        .post("/api/v1/actiongroups/", {
          project: this.$route.params.projectId,
          name: this.newActionGroupName
        })
        .then(() => {
          this.$toast("Gruppe erfolgreich erstellt.", { color: "success" });

          this.dialogCreateGroup = false;
          this.newActionGroupName = null; // Reset for new input

          this.fetch();
        })
        .catch((error) => {
          this.$toast(
            "Es gab einen Fehler beim Erstellen der Gruppe. Der Name der Gruppe muss eindeutig sein.",
            { color: "error" }
          );
        });
    },
    deleteOnlyActionGroup(group) {
      this.$http
        .delete(`/api/v1/actiongroups/${group.id}/`)
        .then(() => {
          this.$toast("Gruppe erfolgreich gelöscht.", { color: "success" });

          this.fetch();
        })
        .catch(() => {
          this.$toast("Es gab einen Fehler beim Löschen der Gruppe.", {
            color: "error"
          });
        });
    },
    deleteActionGroup(group) {
      this.$http
        .get(`/api/v1/actiongroups/${this.dialogGroupId}/`)
        .then(() => {
          let actionListGroup = this.actionList.filter(i => i.group == group.id)
          Promise.all(
            actionListGroup.map(async action => {
              await this.$http
                .delete(`/api/v1/actions/${action.id}`)
            })
          )
          this.$http
            .delete(`/api/v1/actiongroups/${this.dialogGroupId}/`)
            .then(() => {
              this.$toast("Gruppe erfolgreich gelöscht.", { color: "success" });
              this.dialogDeleteGroup = false;

              this.fetch();
            })
            .catch(() => {
              this.$toast("Es gab einen Fehler beim Löschen der Maßnahmen in der Gruppe.", {
                color: "error"
              });
              this.dialogDeleteGroup = false;
            });
        })
        .catch(() => {
          this.$toast("Es gab einen Fehler beim Löschen der Gruppe.", {
            color: "error"
          });
          this.dialogDeleteGroup = false;
        });
    },
    renameActionGroup() {
      this.$http
        .patch(`/api/v1/actiongroups/${this.dialogGroupId}/`, {
          name: this.newActionGroupName
        })
        .then(() => {
          this.$toast("Gruppe erfolgreich umbenannt.", { color: "success" });

          this.dialogRenameGroup = false;
          this.newActionGroupName = null; // Reset for new input

          this.fetch();
        })
        .catch((error) => {
          this.$toast(
            "Es gab einen Fehler beim Umbenennen der Gruppe. Der Name der Gruppe muss eindeutig sein.",
            { color: "error" }
          );
        });
    },
    newAction() {
      this.$router.push({
        name: "NewAction",
        params: { projectId: this.projectId }
      });
    },
    editAction(action) {
      this.$router.push({
        name: "Action",
        params: { projectId: this.projectId, actionId: action.id }
      });
    },
    editProjectDetails() {
      this.$router.push({
        name: "ProjectDetails",
        params: { projectId: this.projectId }
      });
    },
    calcAction() {
      this.$router.push({
        name: "ProjectCalc",
        params: { projectId: this.projectId }
      });
    },
    deleteProject() {
      this.$http
        .delete(`/api/v1/projects/${this.projectId}`)
        .then(() => {
          this.$toast(
            `Projekt <b>${this.project.name}</b> erfolgreich gelöscht.`,
            { color: "success" }
          );
          this.$router.go(-1);
        })
        .catch(() => {
          this.$toast("Es gab einen Fehler beim Löschen des Projekts.", {
            color: "error"
          });
        });
    },
    deleteAction(action) {
      this.$http
        .delete(`/api/v1/actions/${action.id}`)
        .then(() => {
          this.$toast(
            `Maßnahme <b>${action.name}</b> erfolgreich gelöscht.`,
            { color: "success" }
            );
          this.fetch();
        })
        .catch(() => {
          this.$toast("Es gab einen Fehler beim Löschen der Maßnahme.", {
            color: "error"
          });
        });
    },
    cloneAction(action) {
      action.name = action.name + " (Kopie)"
      this.$http
        .post("/api/v1/actions/", action)
        .then(() => {
          this.$toast(
            `Maßnahme <b>${action.name}</b> erfolgreich dupliziert.`,
            { color: "success" }
          );
          this.fetch();
        })
        .catch(() => {
          this.$toast("Es gab einen Fehler beim Duplizieren der Maßnahme.", {
            color: "error"
          });
        });
    },
    cloneActionGroup(group) {
      let newGroup = JSON.parse(JSON.stringify(group))
      newGroup.name = group.name + " (Kopie)"
      this.$http
        .post("/api/v1/actiongroups/", newGroup)

        .then(() => {
          this.$http
            .get("/api/v1/actiongroups/", {
              params: { project: this.$route.params.projectId }
            })
            .then((response) => {
              let newGroupId = response.data.filter(i => i.name == newGroup.name)[0].id
              let actionListGroup = this.actionList.filter(i => i.group == group.id)
              for (let action in actionListGroup) {
                actionListGroup[action].group = newGroupId
                actionListGroup[action].name = actionListGroup[action].name + " (Kopie)"
              }
              Promise.all(
                actionListGroup.map(async action => {
                  await this.$http
                    .post("/api/v1/actions/", action)
                })
              )
              this.$toast(
                `Gruppe <b>${group.name}</b> erfolgreich dupliziert.`,
                { color: "success" }
              );
            })
            .catch(() => {
              this.$toast("Es gab einen Fehler beim Duplizieren der Gruppe.", {
                color: "error"
              });
            });
          setTimeout(() => {this.fetch()}, 500);
        })
        .catch(() => {
          this.$toast("Die Gruppe ist bereits dupliziert und muss umbenannt werden.", {
            color: "error"
          });
        });
    },
    moveToGroup(item, action) {
      action.group = item.id
      this.$http
        .put(`/api/v1/actions/${action.id}/`, action)
        .then(() => {
          this.$toast("Maßnahme erfolgreich der Gruppe hinzugefügt.", { color: "success" });

          this.fetch();
        })
        .catch(() => {
          this.$toast(
            "Es gab einen Fehler beim Zuordnen der Maßnahme.",
            { color: "error" }
          );
        });
    },
    renameAction(item, action) {
      action.name = item
      this.$http
        .put(`/api/v1/actions/${action.id}/`, action)
        .then(() => {
          this.$toast("Maßnahme erfolgreich umbenannt.", { color: "success" });

          this.dialogRenameAction = false;
          this.newActionName = null; // Reset for new input

          this.fetch();
        })
        .catch(() => {
          this.$toast(
            "Es gab einen Fehler beim Umbenennen der Maßnahme.",
            { color: "error" }
          );
        });
    },
  }
};
</script>

<style scoped>
tbody tr:hover .group {
  background-color: whitesmoke !important;
}
tbody tr .group {
  background-color: whitesmoke !important;
  font-weight: bold;
}
</style>
