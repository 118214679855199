<template>
  <div>
    <h2>Projekte</h2>
    <p>
      Willkommen zu Ihrer Analyse.
      Diese Seite zeigt die Ergebnisse aller berechneten Maßnahmen und Maßnahmengruppen. 
      Das Ausschließen von Maßnahmen und Maßnahmengruppen ist auf der Seite 
      <router-link :to="{ name: 'ProjectDashboard'}">
        Maßnahmen 
      </router-link>
      möglich. 
    </p>
    <p>
      Für jede einzelne Maßnahme werden die Energiekosteneinsparungen, die 
      Investitionskosten sowie die Einsparungen an Energieträgern angegeben. 
      Auf die Maßnahmen und auf deren Parameter kann per Kopf Maßnahme bearbeiten 
      (Stift Symbol) zugegriffen werden. Maßnahmengruppen werden in der Analyse 
      als eine große Maßnahme zusammengefasst. Maßnahmengruppen können aufgeklappt 
      und im Detail betrachtet werden und die Einzelmaßnahmen zu vergleichen.
      Über alle ausgewählten Maßnahmen werden darüber hinaus die Summen gebildet. 
      Um die Ergebnisse der Analyse für die weitere Verwendung und Bearbeitung zu erhalten, 
      können Bericht (alle Maßnahmen u. Gruppen), Kurzberichte (einzelne Maßnahmen oder Gruppen)
      und Excel Tabellen (Analyse Tabelle) erzeugt und heruntergeladen werden.
    </p>
    <dl>
      <dt><strong>Hinweise:</strong></dt>
      <dd>
        Für die Einsparung der überschlägigen Maßnahmen ist eine geringe Genauigkeit 
        der Berechnung zu berücksichtigen, da aufgrund der geringen Anzahl an 
        Parametern nur eine Genauigkeit von +- 15% möglich ist. Deshalb wird bei 
        einer ungewöhnlich hohen Einsparungen empfohlen dies Maßnahmen mit der 
        ausführlichen Methode gegenzurechnen.
      </dd>
      <dd>
        Bei den absoluten Einsparpotentialen ist zu beachten, dass die Referenzanlagen 
        und die geplanten Anlagen der Maßnahmen theoretisch berechnet werden. Die somit 
        theoretisch berechneten absoluten Einsparungen entsprechen nicht den tatsächlichen 
        Einsparungen in der praxis. Für eine präzisere Aussage über die Einsparung 
        können die relativen Einsparungen in Bezug auf gemessene Verbrauchswerte bezogen werden.
      </dd>
    </dl>
    <p>Folgende Funktionen sind hier vorhanden:</p>
    <ul>
      <li>
        Übersicht über alle Einsparungen.
      </li>
      <li>
        Berichte herunterladen für alle Maßnahmen, Maßnahmengruppen und Einzelmaßnahmen.
      </li>
      <li>
        Excel Tabelle herunterladen.
      </li>
    </ul>
    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Zusätzliche Informationen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h3>Berechnung</h3>
            <p>Folgende Annahmen wurden für die Analyse angenommen:</p>
              <ul>
                <li>
                  Für die Kälteenergieeinsparung wird ein COP (Kälteleistungszahl) von 4 verwendet.
                </li>
                <li>
                  Die Investitionskosten berechnen sich aus den Demontagekosten und den Kosten der Neuanlage.
                </li>
                <li>
                  Bei der Wärmeenergieeinsparung wird für die 
                  Verbrennung und die Fernwärme ein Wirkungsgrad von 0,9 oder und 1 verwendet.
                </li>
              </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer class="mt-10">
      <small
        >Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.</small
      >
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "NewMethod",
};
</script>

<style scoped></style>
