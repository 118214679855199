<template>
  <div>
    <h2>Projekte</h2>
    <p>
      Willkommen zu Ihren Maßnahmen. 
      Diese Seite zeigt die Maßnahmen und Maßnahmengruppen des Projekts. Hier können sie in eine Maßnahme 
      einsehen indem Sie auf dieses klicken. Mittels den Funktionen unter den Optionen eine Maßnahmen diese verwalten.
      Eine neue Maßnahme können Sie unten rechtes auf dem Knopf erstellen. 
      Wählen Sie an dieser Stelle die Maßnahmen aus, die Sie in die Analyse einbeziehen möchten.  
    </p>
    <p>
      Oben rechts auf der Seite haben Sie die Möglichkeit die Analyse zu öffnen.
      Unten links auf der Seite haben Sie die Möglichkeit das Projekt zu löschen.
    </p>
    <p>Folgende Funktionen sind hier vorhanden:</p>
    <ul>
      <li>
        Verwaltung aller Maßnahmen und Maßnahmengruppen.
      </li>
      <li>
        Maßnahmen auswählen und Analyse starten.
      </li>
      <li>
        Löschung des aktuellen Projekts.
      </li>
    </ul>
    <v-footer class="mt-10">
      <small
        >Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.</small
      >
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "NewMethod",
};
</script>

<style scoped></style>
