<template>
  <div>
    <h2>Projekte</h2>
    <p>
      Willkommen zu Ihren Projekten. 
      Diese Seite zeigt die von Ihnen hinzugefügten Projekte und ein Demo Projekt.
      Hier können Sie in die Projekte einsehen, indem Sie auf diese klicken oder ein 
      <router-link :to="{ name: 'NewProject'}">
        neues Projekt
      </router-link>
       erstellen.
    </p>
    <p>Folgende Funktionen sind hier vorhanden:</p>
    <ul>
      <li>
        Neues Projekt hinzufügen.
      </li>
      <li>
        Projekt auswählen.
      </li>
    </ul>
    <v-footer class="mt-10">
      <small
        >Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.</small
      >
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "NewMethod",
};
</script>

<style scoped></style>
