<template>
  <v-card>
    <v-row no-gutters>
      <v-card-title class="headline">
        Projekte
      </v-card-title>
      <v-row no-gutters class="justify-end">
        <div>
          <v-container>
            <Helpbar :help="ProjectListHelp" />
          </v-container>
        </div>
      </v-row>
    </v-row>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-right">
              Erstellt
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in projects"
              :key="item.id"
              @click="onClick(item.id)"
              class="pointer"
          >
            <td>{{ item.name }}</td>
            <td class="text-right">{{ $filters.date(item.created_at) }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row justify="end" no-gutters class="mt-4">
        <v-btn @click="newAction" color="primary">
          <v-icon left>mdi-plus</v-icon>
          Neues Projekt
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Helpbar from "@/components/Helpbar";
import ProjectListHelp from "@/components/help/ProjectListHelp";

export default {
  name: "ProjectList",
  components: {
    Helpbar,
  },
  data() {
    return {
      ProjectListHelp,
    }
  },
  computed: {
    projects() {
      return Object.values(this.$store.state.allProjects)
    },
  },
  beforeMount() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.$store.dispatch("fetchAllProjects")
    },
    onClick(projectId) {
      this.$router.push({name: "ProjectDashboard", params: {projectId: projectId}})
    },
    newAction() {
      this.$router.push({name: "NewProject", params: {}})
    },
  }
}
</script>
