<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h1>Elektrische Antriebe (überschlägig)</h1>
    <ul>
      <li>
        Die Anzahl der Anlagen sollte nicht Vereinfacht werden, da dies zur
        Effizienzsteigerung führt.
      </li>
    </ul>

    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Schritte vor der Berechnung</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol>
            <li>
              <b>
                Einsparpotential und Wirtschaftlichkeit mit EARNS-Feinanalyse
                abschätzen anhand der Typenschilddaten
              </b>
              <p>Erste Annahmen hierbei:</p>
              <ul>
                <li>
                  Der Bestands-Antrieb läuft im Auslegungspunkt gemäß Typenschild
                </li>
              </ul>
            </li>
            <li>
              <b>
                Kosten für die gewählte Systemlösung ermitteln, unter
                Berücksichtigung aller relevanten Faktoren wie Aufwand für Demontage,
                Umbau, Einbringung, Anschlussarbeiten, Inbetriebnahme, etc.
              </b>
            </li>
            <li><b>Angebotspreis für die gewählte Systemlösung kalkulieren</b></li>
            <li>
              <b>Finale Einsparhöhe gemäß der gewählten Systemlösung berechnen</b>
            </li>
            <li>
              <b>
                Finale Wirtschaftlichkeit (Einsparung, Rendite, Amortisation) anhand
                des Angebotspreises und der finalen Einsparhöhe ermitteln
              </b>
            </li>
          </ol>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Parameter der Anlage</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Anzahl</td>
                <td>
                  Anzahl der elektrischen Antriebe des gleichen Typs und der gleichen
                  Betriebsweise.
                </td>
              </tr>
              <tr>
                <td>Nennleistung</td>
                <td>Auswahl der auf dem Typenschild angegebenen Nennleistung in kW</td>
              </tr>
              <tr>
                <td>Baujahr</td>
                <td>Eingabe des Baujahrs, falls das Baujahr nicht vorhanden ist, das nächst mögliche verwenden.</td>
              </tr>
              <tr>
                <td>Bauart</td>
                <td>Auswahl der Elektromotorart.</td>
              </tr>
              <tr>
                <td>VSD / FU</td>
                <td>Wählen Sie die vorhandene Regelungsmethode.</td>
              </tr>
              <tr>
                <td>Jährliche Betriebsstunden</td>
                <td>
                  Gesamte Betriebszeit pro Jahr in h. Für einen 24-Stunden-
                  Betrieb an 365 Tagen im Jahr geben Sie 8760 Stunden ein.
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungsgrundlage und Daten</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <h4>Alte Wirkungsgradklassen (EFF)</h4>
            <p>
              Im Rahmen der freiwilligen Selbstverpflichtung zwischen dem europäischen
              Sektor-Komitee für elektrische Antriebe CEMEP und der Europäischen
              Kommission wurden im Jahr 1998 drei Wirkungsgradklassen im
              Leistungsbereich 1,1 - 90 kW definiert:
            </p>

            <table>
              <thead>
                <tr>
                  <th>Klasse</th>
                  <th>Beschreibung</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>EFF3</td>
                  <td>
                    Motoren mit niedrigem Wirkungsgrad (meist eingesetzt bis ca. Jahr
                    2000)
                  </td>
                </tr>
                <tr>
                  <td>EFF2</td>
                  <td>Motoren mit verbessertem Wirkungsgrad</td>
                </tr>
                <tr>
                  <td>EFF1</td>
                  <td>Motoren mit erhöhtem Wirkungsgrad</td>
                </tr>
              </tbody>
            </table>
            <img
              src="@/assets/help/electricdrive/marktanteile-eff.png"
              alt="Marktanteile von Eff-Motoren"
            />
            <h4>Neue Wirkungsgradklassen (IE) ab 2008</h4>
            <p>
              Die Norm IEC 60034-30:2008 definiert die Wirkungsgradklassen für
              Drehstrom-Niederspannungsmotoren im Leistungsbereich von 0,75 kW bis 375
              kW. Die Abkürzung IE steht für International Efficiency
            </p>
            <table>
              <thead>
                <tr>
                  <th>Klasse</th>
                  <th>Beschreibung</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>IE1</td>
                  <td>Standard Wirkungsgrad (vergleichbar EFF2)</td>
                </tr>
                <tr>
                  <td>IE2</td>
                  <td>Hoher Wirkungsgrad (vergleichbar EFF1)</td>
                </tr>
                <tr>
                  <td>IE3</td>
                  <td>Premium Wirkungsgrad</td>
                </tr>
                <tr>
                  <td>IE4</td>
                  <td>Super Premium Efficiency</td>
                </tr>
                <tr>
                  <td>IE5</td>
                  <td>Ultra Premium Efficiency</td>
                </tr>
              </tbody>
            </table>

            <p>EU-Verordnungen für Effizienzklassen:</p>
            <ul>
              <li>
                Seit Juli 2021 müssen Dreiphasenmotoren mit einer Nennausgangsleistung
                zwischen 0,75 kW und höchstens 1000 kW das Effizienzniveau IE3
                aufweisen.
              </li>
              <li>
                Seit Juli 2023 ist für Motoren mit einer Leistung zwischen 75 kW und 200
                kW das Effizienzniveau IE4 verpflichtend. Der Geltungsbereich wird auch
                auf Ex eb-Motoren und Einphasenmotoren erweitert, die mindestens 
                Energieeffizienzklasse IE2 erreichen müssen.
              </li>
            </ul>

            <img
              src="@/assets/help/electricdrive/power-eff.png"
              alt="Effizienz über Nennleitung"
            />

            <h4>Vergleich mit den bisherigen Wirkungsgradklassen (EFF-Klassen)</h4>
            <p>
              Der Hauptunterschied zwischen den beiden Wirkungsgradklassen (EFF und IE)
              liegt im Verfahren zu deren Ermittlung. Beim direkten Vergleich am selben
              Motor ist davon auszugehen, dass sich der nach den neuen Messverfahren
              ermittelte Wirkungsgrad reduziert. So ist z. B. ein 4-poliger, 11 kW, EFF1
              Motor mit einem Wirkungsgrad von 91,0 % physikalisch identisch mit einem
              IE2 Motor mit einem Wirkungsgrad von 89,8 %.
            </p>
            <img
              src="@/assets/help/electricdrive/eff-klassen.png"
              alt="Effizienz Klassen"
            />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Zusätzliche Informationen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-container>
            <h2>Elektrische Antriebe</h2>
            <img
              src="@/assets/help/electricdrive/electricdrive.png"
              alt="Elektromotor"
            />
            <p>
              Elektromotoren sind in Europa die größten Stromverbraucher: Sie sind
              für 65 - 70 % des industriellen Stromverbrauchs und ein Drittel des
              Stromverbrauchs im Dienstleistungssektor verantwortlich. Die Kosten für
              den Stromverbrauch machen bis zu 96 % der Kosten eines Motors über die
              gesamte Lebensdauer aus. Der Rest teilt sich zu 2,5 % auf den Kaufpreis
              und zu 1,5 % auf die Wartungskosten auf.
            </p>
            <h3>Optimierungsmöglichkeiten</h3>
            <p>
              Folgende Maßnahmen haben wesentlichen Einfluss auf die Energiekosten eines
              elektrischen Antriebssystems:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Kategorie</th>
                  <th>Beschreibung</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Motor</td>
                  <td>Austausch des Motors durch neuen energieeffizienten Motor IE3</td>
                </tr>
                <tr>
                  <td>Dimensionierung</td>
                  <td>Anpassen der Motorgröße an den tatsächlichen Bedarf</td>
                </tr>
                <tr>
                  <td>Regelung / Steuerung</td>
                  <td>
                    Einsatz einer stufenlosen Drehzahlregelung (Variable Speed Drive
                    VSD)
                  </td>
                </tr>
                <tr>
                  <td>Betriebszeit</td>
                  <td>
                    Anpassung der Betriebszeit an die tatsächlichen Nutzungszeiten
                  </td>
                </tr>
                <tr>
                  <td>Verbraucher</td>
                  <td>Mechanische Systemoptimierung zur Reduzierung des Bedarfs</td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Quellen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h2>Quellen</h2>
          <ul>
            <li>ZVEI-Publikation „Motoren und geregelte Antriebe“</li>
            <li>ZVEI-Publikation „Energiesparen mit elektrischen Antrieben“</li>
            <li>Topmotors.ch</li>
            <li>Motor-Challenge-Programm der EU</li>
            <li>Hochschule Luzern, Technik & Architektur</li>
            <li>EU-Richtlinie 2005/32/EG und IEC 60034-30</li>
            <li>EU-Richtlinie 2019/1781</li>
            <li>
              Arqum GmbH / Forschungsstelle für Energiewirtschaft e.V. i. A. des
              Bayerischen Staatsminis-teriums für Umwelt und Gesundheit (2010)
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>
        Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>
  </div>
</template>

<script>

export default {
  name: "ElectricDrive1Help"
};
</script>

<style scoped>
</style>