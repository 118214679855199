<template>
  <div class="about markdown-body">
    <div class="d-flex justify-center mb-6 bg-surface-variant">
      <img
        src="@/assets/romtechnikearns.png"
        alt="ROM TECHNIK EARNS - THE ENERGY EFFICIENCY TOOLBOX"
        class="center"
      />
    </div>
    <body class="rounded-xl">
      <div class="container">
        <h1>Über EARNS</h1>
        <p>
          EARNS (<b>E</b>nergy <b>A</b>nylysis Expe<b>R</b>t
          <b>N</b>etwork<b>S</b>) ist ein Tool für die Energieanalyse von
          Anlagen in Bestandsgebäuden, das eine effiziente Alternative zu den
          üblichen Methoden darstellt. Es bietet Ihnen Unterstützung bei der
          Umsetzung wirtschaftlicher und umsetzungsorientierter
          Effizienzmaßnahmen. Mit EARNS können Sie schnell und einfach
          die potenziellen Einsparungen durch die Umsetzung energieeffizienter
          Maßnahmen berechnen und bewerten, z. B. durch die Umrüstung auf
          LED-Beleuchtung oder die Installation von neuen Ventilatoren.
        </p>
        <v-img
          src="@/assets/home.png"
          alt="ROM Technik Earns The Energy Efficiency Toolbox"
        />
        <h2>Schlüsselfunktionen</h2>
        <ul>
          <li>Leicht zu bedienende Eingabe von Projekten und Maßnahmen</li>
          <li>
            Berechnung auf der Grundlage wissenschaftlicher und zertifizierter
            Methoden
          </li>
          <li>Unterstützung für mehrere Projekte und Systeme</li>
          <li>Anpassbare Berichte und exportierbare Daten</li>
        </ul>
        <h2>Vorteile bei Energieeffizienzanalysen mit Earns</h2>
        <p>
          Unsere Energieeffizienz-Webapp EARNS ist eine benutzerfreundliche
          Plattform, die unseren Mitarbeitern hilft, die potenziellen
          Energieeinsparungen verschiedener Anlagen und Systeme in Unternehmen
          zu berechnen und bewerten. Dabei nutzt Earns wissenschaftliche und
          zertifizierte Methoden für alle Querschnittstechnologien, wodurch eine
          höhere Sicherheit für die berechnete Energieeinsparung gewährleistet
          wird. Darüber hinaus ermöglicht EARNS eine schnelle Analyse, was
          wiederum zu einer kostengünstigeren Lösung führt. Ein weiterer Vorteil
          von EARNS ist die unterstützte und automatische Erstellung qualitativ hochwertiger
          Ergebnisberichte.
          <br />
          <v-img src="@/assets/IndustryBuilding.png" alt="Industrie Bild" />
          <br />
          Insgesamt bietet EARNS eine innovative Lösung für
          Energieeffizienzanalysen in Bestandsgebäuden und stellt eine
          effiziente Alternative zu den üblichen Methoden dar. Mit EARNS können
          Sie Zeit und Kosten sparen, während sie ihre Ziele effektiver
          erreichen und dabei auf eine höhere Sicherheit und Qualität setzen.
        </p>
        <h2>Programmübersicht</h2>
        <v-hover v-slot:default="{ hover }">
          <v-carousel hide-delimiters :cycle="hover ? false : true">
            <v-carousel-item v-for="image in images" :key="image.src">
              <viewer :options="options">
                <img
                  class="image"
                  :src="require(`@/assets/examples/${image.src}`)"
                />
              </viewer>
              <p class="carousel">{{ image.title }}</p>
            </v-carousel-item>
          </v-carousel>
        </v-hover>
        <h2>Mehr erfahren</h2>
        <p>
          Für weitere Informationen oder um uns zu kontaktieren, besuchen Sie
          bitte unsere
          <router-link :to="{ name: 'Contact'}">
            Kontakt-Seite
          </router-link>
          . Sind Sie bereit, Energie und Geld
          zu sparen?
          <router-link :to="{ name: 'Register'}">
            Registrieren Sie sich noch heute für EARNS 
          </router-link>
          und legen Sie los.
        </p>
        <h2>Gemeinsame Weiterentwicklung</h2>
        <p>
          EARNS wird momentan als Web-App neu aufgebaut. Der Umfang ist daher
          noch eingeschränkt und es kann vereinzelt zu Bugs und Fehler kommen.
          Wir bemühen uns, diese schnellstmöglich zu beheben. Dazu sind wir auch
          auf Ihr Feedback angewiesen. Läuft etwas nicht wie erwartet? Dann
          schreiben sie uns an:
          <a href="mailto:earns@rom-technik.de?subject=Feedback"
            >earns@rom-technik.de</a
          >.<br /><br />
          Vielen Dank für die Unterstützung,<br />
          Ihre FuE
        </p>
      </div>
    </body>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
export default {
  data() {
    return {
      images: [
        {
          src: "RegisterExample.png",
          title:
            "Schritt 1: Registrierung - Nach der Registrierung bitte eine E-Mail " +
            "an earns@rom-technik.de schicken damit das Benutzterkonto aktiviert wird."
        },
        {
          src: "LoginExample.png",
          title:
            "Schritt 2: Login - Ein Login ist nur mit einem aktivierten Benutzerkonto möglich."
        },
        {
          src: "HomeExample.png",
          title:
            "Schritt 3: Startseite - Auf der Startseite kann die Liste der Projekte geöffnet" +
            " oder ein neues Projekt erstellt werden."
        },
        {
          src: "ProjectListExample.png",
          title:
            "Schritt 4: Projekte - Auf dieser Seite sind alle Projekte aufgelistet. Hier" +
            " kann ein weiteres Projekt hinzugefügt werden. Sobald ein Projekt geöffnet oder" +
            " neu erstellt wird, wird die Navigation erweitert."
        },
        {
          src: "ProjectDetailsExample.png",
          title:
            "Schritt 5: Projektdetails - Bei der Neuerstellung eines Projekts werden hier" +
            " die Projektinformationen eingegeben. Um diese abzuspeichern muss mindestens die Projektbezeichung" +
            " eingegeben sein. Außerdem müssen die historischen Energieverbauchsdaten für die Analyse eingetragen sein."
        },
        {
          src: "ProjectDashboardExample.png",
          title:
            "Schritt 6: Maßnahmen - Auf dieser Seite sind alle Maßnahmen und Gruppen" +
            " aufgelistet. Hier können Maßnahmen und Gruppen erstellt, gelöscht, dupliziert, verschoben," +
            " umbenannt und aus der Analyse ausgeschlossen werden. Sobald eine Maßnahme geöffnet oder" +
            " neu erstellt wird, öffnet sich die Maßnahmeneingabe."
        },
        {
          src: "ActionVentilatorExample.png",
          title:
            "Schritt 7: Maßnahmeneingabe - Hier werden die Maßnahmenmethode und die Informationen der" +
            " Referenzanlage sowie der geplanten Anlage eingegeben. Wenn die Pflichtfelder ausgefüllt sind, kann" +
            " das vorraussichtliche Ergebnisse ausgeklappt und betrachtet werden und anschließend " +
            " abgespeichert werden."
        },
        {
          src: "ActionHelpExample.png",
          title:
            "Schritt 7.1: Maßnahmenhilfe - Die Pflichtfelder der Maßnahme sind die" +
            " Methode, Name der Maßnahme und die technischen Parameter. Für zusätzliche Hilfe zur Eingabe der Maßnahme" +
            " kann rechts oben die Maßnahmenhilfe geöffnet werden."
        },
        {
          src: "ProjectCalcExample.png",
          title:
            "Schritt 8: Analyse - Auf der Seite Maßnahmen (siehe Schritt 6) oder in der Navigation" +
            " kann die Analyse geöffnet werden. Um die Ergebnisse der Analyse" +
            " für die weitere Verwendung und Bearbeitung zu erhalten, können Bericht (alle Maßnahmen u. Gruppen)," +
            " Kurzberichte (einzelne Maßnahmen oder Gruppen) und Excel Tabellen (Analyse Tabelle)" +
            " erzeugt und heruntergeladen werden."
        },
        {
          src: "FeinAnalyseExample.png",
          title:
            "Schritt 9: Word Bericht - Hier wird das gesamte Projekt mit allen Maßnahmen" +
            " und Gruppen untersucht. Je nachdem ob keine oder alle wirtschaftlichen" +
            " Informationen eingetragen wurden, wird eine einfache Potentialanalyse" +
            " oder eine detailierte Feinanalyse erzeugt."
        },
        {
          src: "KurzreportActionExample.png",
          title:
            "Schritt 9.1: Kurzbericht einer Maßnahme - 1-2 Seitiges Dokument mit den wichtigsten" +
            " technischen, ökologischen und ökonomischen Informationen bezüglich der Maßnahme."
        },
        {
          src: "KurzreportGroupExample.png",
          title:
            "Schritt 9.2: Kurzbericht einer Gruppe - 1-2 Seitiges Dokument mit den wichtigsten" +
            " ökologischen und ökonomischen Informationen bezüglich der Gruppe." +
            " Hier sind keine Parameter der einzelnen Anlagen aufgeführt."
        }
      ],
      image: "home.png",
      options: {
        title: false,
        navbar: false,
        toolbar: false,
        movable: false
      }
    };
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    show() {
      this.$viewer.show();
    }
  }
};
</script>

<style scoped>
.image {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 75%;
}
.carousel {
  position: absolute;
  top: 0;
  font-weight: bold;
  text-align: justify;
}
/* Set a background color */
body {
  background-color: #f5f5f5;
}

/* Set font and text styles */
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333333;
}

/* Add a gradient background color to the header */
header {
  background: linear-gradient(to right, #6190e8, #a7bfe8);
  padding: 40px 0;
  text-align: center;
}

/* Add a background color and padding to the sections */
section {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 40px;
}

/* Add a hover effect to the call-to-action button */
section #cta a:hover {
  background-color: #6190e8;
  color: #fff;
}

/* Add a border and background color to the footer */
footer {
  border-top: 1px solid #ddd;
  background-color: #f5f5f5;
  text-align: center;
  padding: 20px;
}
</style>
