<template>
  <div v-if="project">
    <v-alert
      v-if="selectedType && selectedType.hint"
      v-model="methodHint"
      class="mt-5"
      align="left"
      border="left"
      dense
      prominent
      text
      elevation="3"
      dismissible
      type="warning"
    >
      {{ selectedType.hint }}
    </v-alert>
    <v-card>
      <v-row no-gutters>
        <v-card-title class="headline"> Maßnahme </v-card-title>
        <v-row no-gutters class="justify-end">
          <div class="d-flex">
            <v-container v-if="selectedType && selectedType.hint">
              <v-btn color="orange lighten" dark @click="methodHint = true">
                <v-icon left> mdi-alert </v-icon>
                Achtung!
              </v-btn>
            </v-container>
            <v-container v-if="help">
              <Helpbar :help="selectedType.help" />
            </v-container>
            <v-container v-else>
              <Helpbar NewMethod />
            </v-container>
          </div>
        </v-row>
      </v-row>

      <v-card-text>
        <v-form ref="formHead">
          <v-select
            v-model="selectedType"
            :items="types"
            return-object
            label="Methode"
          />
          <v-text-field
            v-model="name"
            label="Maßnahmen Name"
            hint="Name einer einzelnen Maßnahme oder mehrer gleicher Maßnahmen"
            :rules="this.validationErrors.name"
            validate-on-blur
          />
          <v-autocomplete
            v-model="group"
            :items="actionGroups"
            item-text="name"
            item-value="id"
            label="Gruppe"
            :disabled="!actionGroups.length"
            clearable
          />
        </v-form>
      </v-card-text>
    </v-card>

    <v-row v-if="selectedType" class="mt-3">
      <v-col v-if="selectedType.sharedForm" :cols="12">
        <v-card>
          <v-card-title>
            <div class="headline">Übergreifend</div>
          </v-card-title>
          <v-card-text>
            <div
              ref="formShared"
              :is="selectedType.sharedForm"
              v-model="sharedData"
              :validation-errors="validationErrorsShared"
              :method-value="selectedType.__typename.toLowerCase()"
            ></div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :cols="6" :xs="12">
        <v-card>
          <v-card-title>
            <div class="headline">Referenz</div>
          </v-card-title>
          <v-card-text>
            <div
              ref="formReference"
              :is="selectedType.form"
              :reference="true"
              v-model="referenceMethod"
              :other-data="plannedMethod"
              :shared-data="sharedData"
              :validation-errors="validationErrorsReference"
              :method-value="selectedType.__typename.toLowerCase()"
            ></div>
            <v-divider class="my-3" />
            <h2 class="mt-5 mb-2">Finanzen</h2>
            <v-text-field
              v-model.number="referenceRemovalCost"
              type="number"
              label="Demontagekosten"
              :error-messages="validationErrorsReference.overwrite_removal_cost"
              suffix="€"
              :hint="financialHelp.overwrite_removal_cost"
            />
            <v-text-field
              v-model.number="referenceOperationCost"
              type="number"
              label="Betriebs- und Instandhaltungskosten"
              :error-messages="
                validationErrorsReference.overwrite_operation_cost
              "
              suffix="€/a"
              :hint="financialHelp.overwrite_operation_cost"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :cols="6" :xs="12">
        <v-card>
          <v-card-title>
            <div class="headline">Geplant</div>
          </v-card-title>
          <v-card-text>
            <div
              ref="formPlanned"
              :is="selectedType.form"
              :planned="true"
              v-model="plannedMethod"
              :other-data="referenceMethod"
              :shared-data="sharedData"
              :validation-errors="validationErrorsPlanned"
              :method-value="selectedType.__typename.toLowerCase()"
            ></div>

            <v-divider class="my-3" />
            <h2 class="mt-5 mb-2">Finanzen</h2>
            <v-text-field
              v-model.number="plannedAcquisitionCosts"
              type="number"
              label="Kosten Neuanlage"
              :error-messages="
                validationErrorsPlanned.overwrite_acquisition_cost
              "
              suffix="€"
              :hint="financialHelp.overwrite_acquisition_cost"
            />
            <v-text-field
              v-model.number="plannedOperationCost"
              type="number"
              label="Betriebs- und Instandhaltungskosten"
              :error-messages="validationErrorsPlanned.overwrite_operation_cost"
              suffix="€/a"
              :hint="financialHelp.overwrite_operation_cost"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card>
      <Previewcard class="mt-2" :actdata="data" :actvalid="valid" />
    </v-card>

    <v-row
      :justify="updating ? 'space-between' : 'end'"
      no-gutters
      class="mt-5"
    >
      <v-row no-gutters>
        <delete-missile-switch v-if="updating" @click="onDelete" />
        <v-btn class="ml-4" v-if="updating" @click="onClone">
          <v-icon left> mdi-content-duplicate </v-icon>
          Duplizieren
        </v-btn>
      </v-row>
      <div>
        <v-row no-gutters>
          <v-btn
            v-if="
              !validationProtocolNote && validationProtocolButton && isSuperUser
            "
            color="orange lighten"
            dark
            @click="validationProtocolNote = true"
            class="mr-4"
          >
            <v-icon left> mdi-alert </v-icon>
            Fehler Protokoll
          </v-btn>

          <v-btn :disabled="!selectedType" @click="onSubmit">Speichern</v-btn>
        </v-row>
      </div>
      <v-alert
        v-if="isSuperUser"
        v-model="validationProtocolNote"
        class="mt-5"
        align="left"
        border="left"
        style="width: 90%"
        dense
        prominent
        text
        elevation="3"
        dismissible
        type="warning"
      >
        <h3>Fehlerprotokoll:</h3>
        <br />
        <li v-for="(value, key) in validationErrors" :key="key">
          <b>{{ key }}:</b> {{ value }}
        </li>

        <li v-for="(value, key) in validationErrorsShared" :key="key">
          <b>{{ key }}:</b> {{ value }}
        </li>
      </v-alert>
    </v-row>
  </div>
</template>

<script>
import BoilerForm from "@/components/methods/BoilerForm";
import Lighting1Form from "@/components/methods/Lighting1Form";
import Lighting1FormShared from "@/components/methods/Lighting1FormShared";
import Lighting2Form from "@/components/methods/Lighting2Form";
import Lighting2FormShared from "@/components/methods/Lighting2FormShared";
import RltForm from "@/components/methods/RltForm";
import RltFormShared from "@/components/methods/RltFormShared";
import FreeConsumerForm from "@/components/methods/FreeConsumerForm";
import Fan1Form from "@/components/methods/Fan1Form";
import Fan2Form from "@/components/methods/Fan2Form";
import DryPump1Form from "@/components/methods/DryPump1Form";
import WetPump1Form from "@/components/methods/WetPump1Form";
import ElectricDrive1Form from "@/components/methods/ElectricDrive1Form";
import ElectricDrive2Form from "@/components/methods/ElectricDrive2Form";
import ChillerForm from "@/components/methods/ChillerForm";
import DeleteMissileSwitch from "@/components/DeleteMissileSwitch";
import Helpbar from "@/components/Helpbar";

import NewMethod from "@/components/help/NewMethod";
import BoilerHelp from "@/components/help/BoilerHelp";
import HVACHelp from "@/components/help/HVACHelp";
import Lighting1Help from "@/components/help/Lighting1Help";
import Lighting2Help from "@/components/help/Lighting2Help";
import Fan1Help from "@/components/help/Fan1Help";
import Fan2Help from "@/components/help/Fan2Help";
import WetPump1Help from "@/components/help/WetPump1Help";
import DryPump1Help from "@/components/help/DryPump1Help";
import ElectricDrive1Help from "@/components/help/ElectricDrive1Help";
import ElectricDrive2Help from "@/components/help/ElectricDrive2Help";
import FreeConsumerHelp from "@/components/help/FreeConsumerHelp";
import FreeGeneratorHelp from "@/components/help/FreeGeneratorHelp";
import ChillerHelp from "@/components/help/ChillerHelp";

import Previewcard from "@/components/calcpreview/Previewcard.vue";
import FreeGeneratorForm from "../components/methods/FreeGeneratorForm.vue";
import FreeGeneratorFormShared from "../components/methods/FreeGeneratorFormShared.vue";

export default {
  name: "Action",
  components: {
    DeleteMissileSwitch,
    BoilerForm,
    Lighting1Form,
    Lighting1FormShared,
    Lighting2Form,
    Lighting2FormShared,
    RltForm,
    RltFormShared,
    FreeConsumerForm,
    Fan1Form,
    Fan2Form,
    DryPump1Form,
    WetPump1Form,
    ElectricDrive1Form,
    ElectricDrive2Form,
    ChillerForm,
    NewMethod,

    Helpbar,
    Previewcard
  },
  data() {
    return {
      validateFields: false,
      helpDialog: false,
      helpContent: null,
      name: null,
      selectedType: null,
      group: null,
      isSuperUser: false,
      types: [
        { header: "Beleuchtung" },
        { divider: true },
        {
          text: "Beleuchtung (überschlägig)",
          value: "lighting1",
          form: Lighting1Form,
          help: Lighting1Help,
          hint: "Bei der Verwendung dieser Methode sollte beachtet werden, dass die Anlagenparameter" +
                " stark vereinfacht sind. Deshalb wird bei einer ungewöhnlich niedrigen oder hohen Einsparung" +
                " empfohlen Ihre Maßnahme mit der ausführlichen Methode gegenzurechnen.",
          sharedForm: Lighting1FormShared,
          __typename: "Lighting1"
        },
        {
          text: "Beleuchtung",
          value: "lighting2",
          form: Lighting2Form,
          help: Lighting2Help,
          sharedForm: Lighting2FormShared,
          __typename: "Lighting2"
        },
        { header: "Elektrische Antriebe" },
        { divider: true },
        {
          text: "Elektrische Antriebe (überschlägig)",
          value: "electricdrive1",
          form: ElectricDrive1Form,
          help: ElectricDrive1Help,
          hint: "Bei der Verwendung dieser Methode sollte beachtet werden, dass die Anlagenparameter" +
                " stark vereinfacht sind. Deshalb wird bei einer ungewöhnlich hohen Einsparung" +
                " empfohlen Ihre Maßnahme mit der ausführlichen Methode gegenzurechnen.",
          __typename: "ElectricDrive1"
        },
        {
          text: "Elektrische Antriebe",
          value: "electricdrive2",
          form: ElectricDrive2Form,
          help: ElectricDrive2Help,
          __typename: "ElectricDrive2"
        },
        { header: "Pumpen" },
        { divider: true },
        {
          text: "Trockenläuferpumpe (überschlägig)",
          value: "drp1",
          form: DryPump1Form,
          help: DryPump1Help,
          hint: "Bei der Verwendung dieser Methode sollte beachtet werden, dass die Anlagenparameter" +
                " stark vereinfacht sind. Deshalb wird bei einer ungewöhnlich hohen Einsparung" +
                " empfohlen Ihre Maßnahme mit der ausführlichen Methode gegenzurechnen.",
          __typename: "DryPump1"
        },
        {
          text: "Nassläuferpumpe (überschlägig)",
          value: "wrp1",
          form: WetPump1Form,
          help: WetPump1Help,
          hint: "Bei der Verwendung dieser Methode sollte beachtet werden, dass die Anlagenparameter" +
                " stark vereinfacht sind. Deshalb wird bei einer ungewöhnlich hohen Einsparung" +
                " empfohlen Ihre Maßnahme mit der ausführlichen Methode gegenzurechnen.",
          __typename: "WetPump1"
        },
        { header: "Ventilatoren + RLT" },
        { divider: true },
        {
          text: "RLT",
          value: "hvac",
          form: RltForm,
          help: HVACHelp,
          sharedForm: RltFormShared,
          __typename: "HVAC"
        },
        {
          text: "Ventilator (überschlägig)",
          value: "fan1",
          form: Fan1Form,
          help: Fan1Help,
          hint: "Bei der Verwendung dieser Methode sollte beachtet werden, dass die Anlagenparameter" +
                " stark vereinfacht sind. Deshalb wird bei einer ungewöhnlich hohen Einsparung" +
                " empfohlen Ihre Maßnahme mit der ausführlichen Methode gegenzurechnen.",
          __typename: "Fan1"
        },
        {
          text: "Ventilator",
          value: "fan2",
          form: Fan2Form,
          help: Fan2Help,
          __typename: "Fan2"
        },
        { header: "Erzeuger" },
        { divider: true },
        {
          text: "Heizkessel",
          value: "boiler",
          form: BoilerForm,
          help: BoilerHelp,
          __typename: "Boiler"
        },
        {
          text: "Kältemaschine",
          value: "chiller",
          form: ChillerForm,
          help: ChillerHelp,
          __typename: "Chiller"
        },
        { header: "Sonstiges" },
        { divider: true },
        {
          text: "Sonstiger Verbraucher",
          value: "free-con",
          form: FreeConsumerForm,
          help: FreeConsumerHelp,
          __typename: "FreeConsumer"
        },
        {
          text: "Sonstiger Erzeuger",
          value: "freegen",
          form: FreeGeneratorForm,
          sharedForm: FreeGeneratorFormShared,
          help: FreeGeneratorHelp,
          __typename: "FreeGenerator"
        }
      ],
      validationErrors: {},
      validationTimer: null,

      referenceMethod: {},
      plannedMethod: {},
      sharedData: {},

      referenceRemovalCost: null,
      referenceOperationCost: null,
      plannedAcquisitionCosts: null,
      plannedOperationCost: null,

      actionGroups: [],
      financialHelp: String,
      validationProtocolNote: false,
      validationProtocolButton: false,
      methodHint: false
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject;
    },
    actionId() {
      return this.$route.params.actionId;
    },
    updating() {
      return !!this.actionId;
    },
    validationErrorsReference() {
      return this.validationErrors?.reference || {};
    },
    validationErrorsPlanned() {
      return this.validationErrors?.planned || {};
    },
    validationErrorsShared() {
      if (!this.selectedType?.sharedForm) return {};
      let allErrors = {
        ...this.validationErrors?.planned,
        ...this.validationErrors?.reference
      };

      return Object.values(this.selectedType.sharedForm.data().fields).reduce(
        (acc, curr) => {
          if (allErrors.hasOwnProperty(curr)) {
            acc[curr] = allErrors[curr];
          }
          return acc;
        },
        {}
      );
    },
    data: {
      get() {
        if (!this.selectedType) {
          return null;
        }
        return {
          name: this.name,
          group: this.group,
          project: this.project?.id,
          method: this.selectedType?.value,
          reference: {
            ...this.referenceMethod,
            __typename: this.selectedType?.__typename,
            overwrite_operation_cost: this.referenceOperationCost,
            overwrite_removal_cost: this.referenceRemovalCost
          },
          planned: {
            ...this.plannedMethod,
            __typename: this.selectedType?.__typename,
            overwrite_operation_cost: this.plannedOperationCost,
            overwrite_acquisition_cost: this.plannedAcquisitionCosts
          }
        };
      },
      set(action) {
        if (this.project?.id !== action.project) {
          alert("critical error: action not from expected project.");
        }

        this.name = action.name;
        this.group = action.group;
        //this.project = {id: action.project}
        for (let method of this.types) {
          if (method.value === action.method) {
            this.selectedType = method;
          }
        }
        let reference = action.partial_ref
          ? JSON.parse(action.partial_ref)
          : action.reference;
        let planned = action.partial_plan
          ? JSON.parse(action.partial_plan)
          : action.planned;

        this.referenceMethod = reference;
        this.plannedMethod = planned;
        this.sharedData = planned;

        this.referenceOperationCost = reference.overwrite_operation_cost;
        this.referenceRemovalCost = reference.overwrite_removal_cost;
        this.plannedOperationCost = planned.overwrite_operation_cost;
        this.plannedAcquisitionCosts = planned.overwrite_acquisition_cost;
      }
    },
    valid() {
      return (
        Object.keys(this.validationErrors).length === 0 &&
        !this.validationTimer &&
        this.selectedType &&
        this.name
      );
    },
    validDraft() {
      return this.selectedType && this.name;
    },
    help() {
      return !!this.selectedType;
    }
  },
  watch: {
    data(data) {
      if (data) {
        this.validate();
      }
    },
    $route: {
      handler() {
        this.fetch();
      },
      immediate: true
    },
    project: {
      handler(project) {
        if (project) {
          this.$store.commit("setTitle", this.project.name);
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    this.fetchFinancialHelp();
    this.fetchActionGroups();
    this.fetchIsSuperUser();
  },
  methods: {
    fetchFinancialHelp() {
      if (!!this.selectedType) {
        this.$http
          .get(
            `/api/v1/methods/${this.selectedType.__typename.toLowerCase()}/help/`
          )
          .then((response) => {
            this.financialHelp = Object.assign(
              {},
              ...response.data.map((x) => ({ [x.key]: x.help }))
            );
          })
          .catch(() => {
            this.financialHelp = [];
          });
      }
    },
    fetchActionGroups() {
      this.$http
        .get("/api/v1/actiongroups/", {
          params: { project: this.$route.params.projectId }
        })
        .then((response) => {
          this.actionGroups = response.data;
        })
        .catch(() => {
          // TODO: error handling
        });
    },
    fetch() {
      this.$store.dispatch("fetchCurrentProject", this.$route.params.projectId);

      if (this.updating) {
        this.$http
          .get(`/api/v1/actions/${this.actionId}/`)
          .then((response) => {
            this.data = response.data;
          })
          .catch(() => {});
      }
    },
    validate() {
      if (this.validationTimer) {
        clearTimeout(this.validationTimer);
      }
      this.validationTimer = setTimeout(() => {
        this.validationTimer = null;
        this.$http
          .post("/api/v1/actions/validate/", this.data)
          .then(() => {
            this.validationErrors = {};
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.validationErrors = error.response.data;
            }
          });
      }, 600);
    },
    onSubmit() {
      let data = { ...this.data };
      data.partial_plan = null;
      data.partial_ref = null;

      let promise = this.updating
        ? this.$http.put(`/api/v1/actions/${this.actionId}/`, data)
        : this.$http.post("/api/v1/actions/", data);

      promise
        .then((response) => {
          if (this.updating) {
            this.$toast(
              `Maßnahme <b>${response.data.name}</b> erfolgreich erstellt.`,
              { color: "success" }
            );
          } else {
            this.$toast(
              `Maßnahme <b>${response.data.name}</b> erfolgreich gespeichert.`,
              { color: "success" }
            );
          }
          this.$router.go(-1);
        })
        .catch((error) => {
          this.validationProtocolButton = true;
          for (let formCard in this.$refs) {
            if (formCard !== "formHead") {
              this.validateFields = true;
              this.$refs[formCard].validateForm();
            } else {
              this.$refs[formCard].validate();
            }
          }
          this.onSubmitDraft();
        });
    },
    onSubmitDraft() {
      let data = { ...this.data };
      data.partial_plan = JSON.stringify(data.planned);
      data.partial_ref = JSON.stringify(data.reference);
      delete data.planned;
      delete data.reference;

      let promise = this.updating
        ? this.$http.put(`/api/v1/actions/${this.actionId}/`, data)
        : this.$http.post("/api/v1/actions/", data);

      promise
        .then((response) => {
          if (this.updating) {
            this.$toast(
              `Maßnahme <b>${response.data.name}</b> erfolgreich als Entwurf gespeichert.`,
              { color: "warning" }
            );
          } else {
            this.$toast(
              `Maßnahme <b>${response.data.name}</b> erfolgreich als Entwurf erstellt.`,
              { color: "warning" }
            );
          }
        })
        .catch((error) => {
          for (let formCard in this.$refs) {
            if (formCard !== "formHead") {
              this.validateFields = true;
              this.$refs[formCard].validateForm();
            } else {
              this.$refs[formCard].validate();
            }
          }
          this.$toast(
            "Es gab einen Fehler beim Erstellen/Speichern des Maßnahmenentwurfs.",
            { color: "error" }
          );
        });
    },
    onDelete() {
      this.$http
        .delete(`/api/v1/actions/${this.actionId}/`, this.data)
        .then((response) => {
          this.$toast(
            `Maßnahme <b>${this.data.name}</b> erfolgreich gelöscht.`,
            { color: "success" }
          );
          this.$router.go(-1);
        })
        .catch((error) => {
          this.$toast("Es gab einen Fehler beim Löschen der Maßnahme.", {
            color: "error"
          });
        });
    },
    onClone() {
      let data = { ...this.data };
      data.partial_plan = null;
      data.partial_ref = null;

      this.$http
        .post("/api/v1/actions/", data)
        .then((response) => {
          this.$toast(
            `Maßnahme <b>${this.data.name}</b> erfolgreich dupliziert.`,
            { color: "success" }
          );
          this.$router.go(-1);
        })
        .catch((error) => {
          this.$toast("Es gab einen Fehler beim Duplizieren der Maßnahme.", {
            color: "error"
          });
        });
    },
    fetchIsSuperUser() {
      this.$http
        .get("/api/v1/superuser/")
        .then((response) => {
          this.isSuperUser = response.data.is_superuser;
        })
        .catch(() => {
          // TODO: error handling
        });
    }
  }
};
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
  padding: 15px;
  resize: vertical;
}
</style>
