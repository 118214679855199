<template>
  <div>
    <h1>Earns Methoden</h1>
    <p>
      Maßnahmen können mit der überschlägigen oder der genormten / wissenschaftlichen 
      Methode berechnet werden. Die überschlägigen Methoden benötigen weniger Daten 
      und sind somit schneller zu untersuchen. Die genormten / wissenschaftlichen Methoden 
      bieten auf der anderen Seite eine zuverlässige Berechnung welche belastbar sind.
    </p>
    <p>
      Um eine Maßnahme abspeichern zu können, sind in alle technischen
      Pflichtfelder valide Werte einzutragen. Zusätzlich können optional die
      finanziellen Parameter eingetragen werden.
    </p>
    <h1>Kältemaschinen</h1>

    <v-expansion-panels accordion class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Schritte vor der Berechnung</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol type="1">
            <li>
              <b>Erfassung der aktuellen Betriebsdaten der Kältemaschine</b>
              <ul>
                <li>
                  Sammeln der relevanten Daten der bestehenden Kältemaschine, 
                  einschließlich Kälteleistung, Stromverbrauch, Kältemitteltyp, 
                  Verdampfungs- und Verflüssigungstemperaturen, sowie der 
                  Betriebsstunden.
                </li>
                <li>
                  Erfassung der Betriebsparameter wie Umgebungstemperatur, 
                  Kühlwassertemperatur und Lastprofil (Tages- und 
                  Jahresschwankungen).
                </li>
              </ul> 
            </li> 
            <li>
            <b>Überprüfung des Ist-Zustands der Kältemaschine</b>
              <ul>
                <li>
                  Überprüfung der Wartungszustände der Kältemaschine, 
                  einschließlich des Zustands des Verdampfers, Verflüssigers, 
                  Kompressors und der Steuerungssysteme.
                </li>
                <li>
                  Feststellen, ob die Kältemaschine im Teillast- oder 
                  Volllastbetrieb läuft und wie effizient dieser Betrieb ist.
                </li>
              </ul> 
            </li> 
            <li>
            <b>Ermittlung des tatsächlichen Kältebedarfs</b>
              <ul>
                <li>
                  Analysieren, ob der Kältebedarf den tatsächlichen 
                  Anforderungen entspricht oder ob die Anlage überdimensioniert 
                  ist.
                </li>
                <li>
                  Prüfung der Lastprofile der angeschlossenen Verbraucher, 
                  um festzustellen, ob der Betrieb optimiert werden kann.
                </li>
                <li>
                  Evaluierung der Möglichkeit, Lastspitzen zu reduzieren 
                  oder zu verschieben
                </li>
              </ul> 
            </li> 
            <li>
            <b>Überprüfung der Regelungs- und Steuerungssysteme</b>
              <ul>
                <li>
                  Untersuchung, ob die Steuerungs- und Regelungssysteme 
                  der Kältemaschine auf dem neuesten Stand sind und ob 
                  sie optimal arbeiten.
                </li>
                <li>
                  Überprüfung der Einsatzmöglichkeiten für moderne 
                  Regelungsstrategien wie Laststeuerung, freie Kühlung 
                  (Free Cooling), oder adaptive Regelungen.
                </li>
              </ul> 
            </li> 
            <li>
            <b>Optimierungspotenziale im Kältemittelkreislauf identifizieren</b>
              <ul>
                <li>
                  Prüfung des Kältemittelkreislaufs auf mögliche Verluste 
                  oder Ineffizienzen, wie z.B. durch Leckagen, 
                  unzureichende Isolierung oder ungünstige Temperaturverhältnisse.
                </li>
                <li>
                  Überlegen, ob ein Kältemittelwechsel zu einem 
                  energieeffizienteren Betrieb beitragen kann.
                </li>
              </ul> 
            </li> 
            <li>
            <b>Überprüfung der mechanischen Komponenten</b>
              <ul>
                <li>
                  Analyse des Zustands der mechanischen Komponenten 
                  wie Verdichter, Pumpen und Lüfter.
                </li>
                <li>
                  Überprüfung der Effizienz von Wärmetauschern und 
                  ob diese gereinigt oder ersetzt werden müssen, um 
                  eine höhere Leistung zu erzielen.
                </li>
              </ul> 
            </li> 
            <li>
            <b>Ermittlung eines optimierten Betriebspunktes</b>
              <ul>
                <li>
                  Festlegung eines neuen, optimierten Betriebspunktes 
                  basierend auf dem tatsächlichen Kältebedarf und den 
                  analysierten Daten.
                </li>
                <li>
                  Berücksichtigung der Möglichkeit, die Verdampfungs- 
                  und Verflüssigungstemperaturen zu optimieren, um den 
                  Energieverbrauch zu minimieren.
                </li>
              </ul> 
            </li> 
            <li>
            <b>Abschätzung des Einsparpotenzials und der Wirtschaftlichkeit</b>
              <ul>
                <li>
                  Analyse des Energieeinsparpotenzials durch Maßnahmen 
                  wie Verdichtermodulation, Einsatz von frequenzgeregelten 
                  Antrieben, oder die Integration von Wärmerückgewinnung.
                </li>
                <li>
                  Durchführung einer Wirtschaftlichkeitsanalyse, um die 
                  Rentabilität der vorgeschlagenen Maßnahmen zu bewerten.
                </li>
              </ul> 
            </li> 
            <li>
            <b>Überprüfung der baulichen Gegebenheiten und des Einbauorts</b>
              <ul>
                <li>
                  Analyse der baulichen Gegebenheiten, um festzustellen, 
                  ob die vorhandene Infrastruktur (z.B. Rohrleitungen, 
                  Isolierungen, Belüftung) den Anforderungen einer 
                  optimierten Kältemaschine entspricht.
                </li>
                <li>
                  Überprüfen, ob der Standort der Kältemaschine (z.B. im 
                  Freien, in einem Technikraum) einen Einfluss auf die 
                  Effizienz hat und ob Änderungen notwendig sind.
                </li>
              </ul> 
            </li> 
            <li>
            <b>Auswahl der optimalen technischen Lösung</b>
              <ul>
                <li>
                  Basierend auf den gesammelten Daten und den identifizierten 
                  Optimierungsmöglichkeiten, Auswahl der effizientesten und 
                  wirtschaftlichsten technischen Lösung.
                </li>
                <li>
                  Berücksichtigung von Faktoren wie Systemkompatibilität, 
                  Investitionskosten, Betriebskosten und den spezifischen 
                  Anforderungen des Anwendungsfalls.
                </li>
              </ul> 
            </li> 
            <li>
            <b>Kostenkalkulation und Angebotserstellung</b>
              <ul>
                <li>
                  Ermittlung aller anfallenden Kosten für die Umsetzung 
                  der optimierten Systemlösung, einschließlich Demontage, 
                  Neubau, Installation und Inbetriebnahme.
                </li>
                <li>
                  Erstellen eines detaillierten Angebots für die gewählte 
                  Lösung, unter Berücksichtigung der erwarteten 
                  Energieeinsparungen und der Amortisationszeit.
                </li>
              </ul> 
            </li> 
            <li>
            <b>Finale Berechnung der Energieeinsparung und Wirtschaftlichkeit</b>
              <ul>
                <li>
                  Berechnung der erwarteten Energieeinsparung auf Grundlage 
                  der neuen Betriebspunkte und technischen Lösungen.
                </li>
                <li>
                  Durchführung einer finalen Wirtschaftlichkeitsanalyse 
                  unter Berücksichtigung der gesamten Investitions- und 
                  Betriebskosten, sowie der zu erwartenden Einsparungen 
                  und Renditen.
                </li>
              </ul>
            </li>
          </ol>        
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Eingabe der Bestandsanlage (Referenz) und der geplanten Anlage</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <table>
            <figcaption>Übergreifend</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Typ der Kältemaschine</td>
                <td>Auswahl des Kältemaschinentyp</td>
              </tr>
            </tbody>
          </table>
          <table>
            <figcaption>Parameter der Kompressionskältemaschine</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Nutzungsart</td>
                <td>Auswahl der Anzahl an unterschiedlichen Nutzungsarten</td>
              </tr>
              <tr>
                <td>Nutzungsprofil</td>
                <td>Auswahl des Nutzungsprofiles im Gebäude</td>
              </tr>
              <tr>
                <td>Anteil der Nutzungsart</td>
                <td>Eingabe des prozentualen Nutzungsartanteils an der Gesamtfläche</td>
              </tr>
              <tr>
                <td>Betriebsart</td>
                <td>Auswahl der Vorlauftemperatur</td>
              </tr>
              <tr>
                <td>Feuchteanforderung</td>
                <td>Auswahl der Feuchteanforderung</td>
              </tr>
              <tr>
                <td>Wärmerückgewinnung</td>
                <td>Auswahl der Wärmerückgewinnung</td>
              </tr>
              <tr>
                <td>Nennkälteleistung</td>
                <td>Eingabe der Nennkälteleistung</td>
              </tr>
              <tr>
                <td>Elektrische Nennantriebsleistung</td>
                <td>Eingabe der elektrischen Nennantriebsleistung</td>
              </tr>
              <tr>
                <td>Jährlicher elektrischer Energiebedarf</td>
                <td>Eingabe des jährlichen elektrischen Energiebedarfs</td>
              </tr>
              <tr>
                <td>Jährliche Betriebsstunden</td>
                <td>Eingabe Jährliche Betriebsstunden in h</td>
              </tr>
              <tr>
                <td>Rückkühlung</td>
                <td>Auswahl der Rückkühlung</td>
              </tr>
              <tr>
                <td>Art des Verdichters und/oder der Teillastregelung</td>
                <td>Auswahl der Verdichterart und/oder der Teillastregelung</td>
              </tr>
              <tr>
                <td>Art der Rückkühlung</td>
                <td>Auswahl der Rückkühlung</td>
              </tr>
              <tr>
                <td>Art der Rückkühlers</td>
                <td>Auswahl des Rückkühlers</td>
              </tr>
            </tbody>
          </table>

          <table>
            <figcaption>Parameter der Sorptionskältemaschine</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Nennkälteleistung</td>
                <td>Auswahl der Rücklauftemperatur</td>
              </tr>
              <tr>
                <td>Jährliche Betriebsstunden</td>
                <td>Eingabe Jährliche Betriebsstunden in h</td>
              </tr>
              <tr>
                <td>Jährliche thermischer Energiebedarf</td>
                <td>Eingabe Jährliche thermischer Energiebedarf</td>
              </tr>
              <tr>
                <td>Kältemittel/Lösungsmittel Paar</td>
                <td>Auswahl des Kältemittel/Lösungsmittel Paars</td>
              </tr>
              <tr>
                <td>Art des Verdichters und/oder der Teillastregelung</td>
                <td>Auswahl der Verdichterart und/oder der Teillastregelung</td>
              </tr>
            </tbody>
          </table>

          <table>
            <figcaption>Parameter der Raumklimasystem</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>SEER bekannt</td>
                <td>Auswahl on SEER bekannt</td>
                <td></td>
              </tr>
              <tr>
                <td>Jahresarbeitszahl</td>
                <td>Eingabe der Jahresarbeitszahl im Kühlbetrieb </td>
              </tr>
              <tr>
                <td>Nutzungsart</td>
                <td>Auswahl der Anzahl an unterschiedlichen Nutzungsarten</td>
              </tr>
              <tr>
                <td>Nutzungsprofil</td>
                <td>Auswahl des Nutzungsprofiles im Gebäude</td>
              </tr>
              <tr>
                <td>Anteil der Nutzungsart</td>
                <td>Eingabe des prozentualen Nutzungsartanteils an der Gesamtfläche</td>
              </tr>
              <tr>
                <td>Betriebsart</td>
                <td>Raumkühlung</td>
                <td></td>
              </tr>
              <tr>
                <td>Nennkälteleistung</td>
                <td>Eingabe der Nennkälteleistung</td>
              </tr>
              <tr>
                <td>Jährlicher elektrischer Energiebedarf</td>
                <td>Eingabe des jährlichen elektrischen Energiebedarfs</td>
              </tr>
              <tr>
                <td>Jährliche Betriebsstunden</td>
                <td>Eingabe Jährliche Betriebsstunden in h</td>
              </tr>
              <tr>
                <td>Anlagensystem</td>
                <td>Auswahl des Anlagensystems</td>
              </tr>
              <tr>
                <td>Art des Verdichters und/oder der Teillastregelung</td>
                <td>Auswahl der Verdichterart und/oder der Teillastregelung</td>
              </tr>
            </tbody>
          </table>

          <table>
            <figcaption>Finanzielle Parameter</figcaption>
            <thead>
              <th>Parameter</th>
              <th>Beschreibung</th>
            </thead>
            <tbody>
              <tr>
                <td>Demontagekosten (Referenz)</td>
                <td>
                  Gesamtkosten der Demontagekosten inkl. Arbeitszeit, 
                  Maschinenkosten und Entsorgungskosten.
                </td>
              </tr>
              <tr>
                <td>Kosten Neuanlage (Geplant)</td>
                <td>Gesamte Anschaffungskosten bis hin zur Inbetriebnahme.</td>
              </tr>
              <tr>
                <td>Betriebs- und Instandhaltungskosten</td>
                <td>
                  Jährliche Kosten für Betriebsmaterial und
                  Instandhaltungsarbeiten (ohne Energiekosten).
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Berechnungsgrundlage und Daten</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>

          <h2>Endenergiebedarf für die Kälteerzeugung</h2>

          <h3>Kompressionskältemaschinen</h3>
          <p>
            Die energetische Bewertung von Kaltdampf-Kompressionskältemaschinen
            erfolgt anhand der Nennkälteleistungszahl \(EER\) und eines mittleren
            Teillastfaktors \(PLV_\mathrm{av}\). Auf Basis dieser Werte wird die
            Jahreskälteleistungszahl \(SEER\) wie folgt berechnet:
          </p>
          <p>
            $$ SEER = EER \cdot PLV_\mathrm{av} =
            \frac{Q_\mathrm{C,f}}{Q_\mathrm{C,outg}} $$
          </p>
          <p>
            Dabei sind
          </p>
          <ul>
            <li>
              \(EER\): die Nennkälteleistungszahl (en: energy efficiency ratio)
            </li>
            <li>
              \(PLV_\mathrm{av}\): der mittlere Teillastfaktor (en: part load value)
            </li>
            <li>
              \(SEER\): Jahreskälteleistungszahl (en: seasonal energy efficiency
              ratio)
            </li>
            <li>
              \(Q_\mathrm{C,f}\): der elektrische Endenergiebedarf der
              Kompressionskältemaschinen
            </li>
            <li>
              \(Q_\mathrm{C,outg}\): die Erzeugerkälteabgabe der
              Kompressionskältemaschinen
            </li>
          </ul>

          <p>
            Die Nennkälteleistungszahl EER stellt dabei das Verhältnis aus
            Nennkälteleistung (Nutzen) und elektrischer Antriebsleistung (Aufwand)
            unter Auslegungsbedingungen nach folgender Gleichung dar:
          </p>
          <p>
            $$EER = \frac{\dot{Q}_\mathrm{C,outg}}{P_\mathrm{C,elektr}}$$
          </p>
          <p>
            Dabei sind
          </p>
          <ul>
            <li>
              \(\dot{Q}_\mathrm{C,outg}\): die Nennkälteleistung der
              Kompressionskältemaschine
            </li>
            <li>\(P_\mathrm{C,elektr}\): die elektrische Nennantriebsleistung</li>
          </ul>
          <p>
            Die Leistungszahl einer Kältemaschine variiert unter Teillastbedingungen.
            Diese variablen Bedingungen werden durch den technologieabhängigen
            mittleren Teillastfaktor \(PLV_\mathrm{av}\) abgebildet. Er berücksichtigt
            das reale Teillastverhalten der Kältemaschine, den Einfluss der
            Kühlwasser- bzw. Außenlufttemperatur und den Einfluss der im Teillastfall
            überdimensionierten Wärmeübertrager.
          </p>
          <h4>Wassergekühlte Kompressionskältemaschinen</h4>
          <p>
            Im Rahmen der DIN 18599-7 werden folgende Verdichter- bzw. Regelungsarten
            betrachtet:
          </p>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Art des Verdichters und der Teillastregelung</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>(1)</td>
                <td>
                  Kolben-/Scrollverdichter mit Zweipunktregelung taktend
                  (EIN/AUS-Betrieb)
                </td>
              </tr>
              <tr>
                <td>(2)</td>
                <td>
                  Kolben-/Scrollverdichter mehrstufig schaltbar (mindestens vier
                  Schaltstufen als Verdichterverbund)
                </td>
              </tr>
              <tr>
                <td>(3)</td>
                <td>Kolbenverdichter durch Zylinderabschaltung</td>
              </tr>
              <tr>
                <td>(4)</td>
                <td>Kolben-/Scrollverdichter mit Heißgasbypassregelung</td>
              </tr>
              <tr>
                <td>(5)</td>
                <td>Schraubenverdichter mit Steuerschieberregelung</td>
              </tr>
              <tr>
                <td>(6)</td>
                <td>Turboverdichter mit Einlassdrosselregelung</td>
              </tr>
              <tr>
                <td>(7)</td>
                <td>Invertergeregelte Schraubenverdichter</td>
              </tr>
              <tr>
                <td>(8)</td>
                <td>Invertergeregelte Turboverdichter</td>
              </tr>
            </tbody>
          </table>

          <p>
            <b>Hinweise:</b>
          </p>
          <ul>
            <li>
              Die mittlere jährliche Teillastfaktor ist abgängig von der Verdichterart
              und der Teillastregelung, der Nutzungsart (Nutzungsprofile nach DIN
              18599-10), von der Art der Kältenutzung (Raumsystem oder RLT-System),
              der Art der Kühlwasserregelung (konstante oder variable
              Kühlwasserbedingungen) und von der Bauweise der Rückkühlers
              (Verdunstungsrückkühler oder Trockenrückkühler).
            </li>
            <li>
              Bei RLT-Systemen sind zusätzlich die erforderlichen
              Luftbehandlungsfunktionen (Befeuchtung, Wärmerückgewinnung) zu
              definieren.
            </li>
            <li>
              Bie mehreren Nutzungsarten wird der Teillastfaktor entsprechend den
              prozentualen Anteilen der jährlichen Erzeugernutzkälteabgabe für die
              jeweilige Nutzung gewichtet.
            </li>
            <li>
              Es sind nicht alle Kombinationen von Verdichter/Teillastregelung, Art
              der Nutzung, der Kältenutzung und der Kühlwasserregelung möglich. Für
              einige Kombinationen sind keine Teillastfaktoren definiert. Hier sei auf
              die DIN 18599-7 Anhang A.3 verwiesen.
            </li>
          </ul>

          <h4>Luftgekühlte Kompressionskältemaschinen</h4>
          <p>
            Im Rahmen der DIN 18599-7 werden folgende Verdichter- bzw. Regelungsarten
            betrachtet:
          </p>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Art des Verdichters und der Teillastregelung</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>(A)</td>
                <td>
                  Kolben-/Scrollverdichter mit Zweipunktregelung taktend mit
                  Pufferspeicher (EIN/AUS-Betrieb)
                </td>
              </tr>
              <tr>
                <td>(B)</td>
                <td>
                  Kolben-/Scrollverdichter mehrstufig schaltbar (mindestens vier
                  Schaltstufen als Verdichterverbund)
                </td>
              </tr>
              <tr>
                <td>(C)</td>
                <td>Schraubenverdichter mit Steuerschieberregelung</td>
              </tr>
              <tr>
                <td>(D)</td>
                <td>Invertergeregelter Schraubenverdichter</td>
              </tr>
              <tr>
                <td>(E)</td>
                <td>Invertergeregelter Turboverdichter</td>
              </tr>
              <tr>
                <td>(F)</td>
                <td>Digitaler Scrollverdichter</td>
              </tr>
            </tbody>
          </table>

          <p>
            <b>Hinweise:</b>
          </p>
          <ul>
            <li>
              Die mittlere jährliche Teillastfaktor ist abgängig von der Verdichterart
              und der Teillastregelung, der Nutzungsart (Nutzungsprofile nach DIN
              18599-10), von der Art der Kältenutzung (Raumsystem oder RLT-System)
              sowie der Anordnung und den Temperaturkonditionen des Rückkühlers
              (Anordnung in der Außenluft oder in der RLT-Fortluft als
              Verdunstungsrückkühler oder Trockenrückkühler).
            </li>
            <li>
              Bei RLT-Systemen sind zusätzlich die erforderlichen
              Luftbehandlungsfunktionen (Befeuchtung, Wärmerückgewinnung) zu
              definieren.
            </li>
            <li>
              Bie mehreren Nutzungsarten wird der Teillastfaktor entsprechend den
              prozentualen Anteilen der jährlichen Erzeugernutzkälteabgabe für die
              jeweilige Nutzung gewichtet.
            </li>
            <li>
              Es sind nicht alle Kombinationen von Verdichter/Teillastregelung, Art
              der Nutzung, der Kältenutzung und der Kühlwasserregelung möglich. Für
              einige Kombinationen sind keine Teillastfaktoren definiert. Hier sei auf
              die DIN 18599-7 Anhang A.3 verwiesen.
            </li>
          </ul>

          <h4>Raumklimasysteme</h4>
          <p>
            Ein Raumklimasystem ist eine spezielle Form einer luftgekühlte
            Kompressionskältemaschinen. Raumklimageräte &le; 12 kW unterliegen seit
            2013 einer Energieetikettierungspflicht nach der Verordnung (EU) Nr.
            626/2011 und müssen Mindesteffizienzwerte nach der Verordnung (EU) Nr.
            206/2012 einhalten. Für unter diese Verordnungen fallende Raumklimageräte
            darf im Rahmen des Kennwertverfahrens die auf dem Energieetikett
            angegebene Jahresarbeitszahl im Kühlbetrieb <i>SEER</i> direkt verwendet
            werden. Raumklimageräte bis Baujahr 2012 (ohne Energieetikett nach der
            Verordnung (EU) Nr. 626/2011) und Raumklimasysteme &gt; 12 kW werden im
            Rahmen des Kennwertverfahrens betrachtet. Dieses gilt auch bei nicht
            bekannten Gerätekennwerten für alle Baujahre. Die Berechnungen erfolgen
            Analog zu luftgekühlten Kompressionskältemaschinen.
          </p>
          <p>
            Im Rahmen der DIN 18599-7 werden folgende Verdichter- bzw. Regelungsarten
            betrachtet:
          </p>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Art des Verdichters und der Teillastregelung</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>(a)</td>
                <td>
                  Zweipunktregelung für Einzonensystem taktend (EIN/AUS-Betrieb)
                </td>
              </tr>
              <tr>
                <td>(b)</td>
                <td>
                  Zweipunktregelung für Mehrzonensystem taktend (gegebenenfalls mit
                  Schadraumzuschaltung oder Zylinderabschaltung)
                </td>
              </tr>
              <tr>
                <td>(c)</td>
                <td>
                  Stetige Regelung für Einzonensysteme Invertergeregelt/taktend, mit
                  elektronischem Expansionsventil
                </td>
              </tr>
              <tr>
                <td>(d)</td>
                <td>
                  Stetige Regelung für Mehrzonensysteme Invertergeregelt/taktend, mit
                  elektronischem Expansionsventil
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Ab-/Adsportionskältemaschinen</h3>
          <p>
            Die energetische Bewertung von Sorptionskältemaschinen erfolgt anhand des
            Wärmeverhältnisses \(\zeta\) und eines mittleren Teillastfaktors
            \(PLV_\mathrm{av}\) nach folgender Gleichung:
          </p>
          <p>
            $$ \zeta_\mathrm{av} = \zeta \cdot PLV_\mathrm{av} =
            \frac{Q_\mathrm{C,out,g}}{Q_\mathrm{C,f}} $$
          </p>
          <p>Dabei ist</p>
          <ul>
            <li>
              \(\zeta\): das Nennwärmeverhältnis
            </li>
            <li>
              \(PLV_\mathrm{av}\): der mittlere Teillastfaktor (en: part load value)
            </li>
            <li>
              \(\zeta_\mathrm{av}\): das mittlere Nennwärmeverhältnis
            </li>
            <li>
              \(Q_\mathrm{C,f}\): der thermische Endenergiebedarf der Kältemaschine;
            </li>
          </ul>
          <p>
            Das Nennwärmeverhältnis \(\zeta\) stellt dabei das Verhältnis aus
            Nennkälteleistung (Nutzen) und zugeführter Heizwärmeleistung (Aufwand)
            unter Auslegungsbedingungen dar:
          </p>
          <p>
            $$ \zeta = \frac{\dot{Q}_\mathrm{C,outg}}{\dot{Q}_\mathrm{C,therm}} $$
          </p>
          <p>
            Dabei ist
          </p>
          <ul>
            <li>
              \(\dot{Q}_\mathrm{C,outg}\): die Nennkälteleistung der
              Sorptionskältemaschine
            </li>
            <li>
              \(\dot{Q}_\mathrm{C,therm}\): die Nennheizwärmeleistung
            </li>
          </ul>
          <p>
            Die Leistungszahl einer Sorptionskältemaschine variiert unter
            Teillastbedingungen. Diese variablen Bedingungen werden durch den
            technologieabhängigen mittleren Teillastfaktor \(PLV_\mathrm{av}\)
            abgebildet. Er berücksichtigt das reale Teillastverhalten der
            Kältemaschine, den Einfluss der Kühlwassertemperatur und den Einfluss der
            im Teillastfall überdimensionierten Wärmeübertrager.
          </p>
          <p>
            Zusätzlich zum thermischen Endenergiebedarf von Sorptionskältemaschinen
            wird ein elektrischer Hilfsenergiebedarf für die Nebenantriebe (vorwiegend
            Pumpen) benötigt. Diese berechnet sich zu:
          </p>
          <p>
            $$ W_\mathrm{C,f,therm} = \frac{\dot{Q}_\mathrm{C,outg} \cdot
            P_\mathrm{el,C,therm} \cdot t_\mathrm{op}}{1000} $$
          </p>
          <p>
            Dabei ist
          </p>
          <ul>
            <li>
              \(W_\mathrm{C,f,therm}\): der eletkrische Hilfsenergiebedarf für die
              Nebenantriebe der Sorptionskältemaschine
            </li>
            <li>
              \(\dot{Q}_\mathrm{C,outg}\): die Nennkälteleistung der
              Sorptionskältemaschine
            </li>
            <li>
              \(P_\mathrm{el,C,therm}\): der technologieabhängige, spezifische
              Hilfsenergieaufwand für Pumpen der Sorptionskältemaschine
            </li>
            <li>
              \(t_\mathrm{op}\): Betriebszeit der Sorptionskältemaschine
            </li>
          </ul>
          <h4>Hinweise</h4>
          <ul>
            <li>
              Sorptionskältemaschinen sind immer wassergekühlt, da die DIN 18599-7
              keine luftgekühlten Sorptionskältemaschine vorsieht.
            </li>
            <li>
              Der mittlere Teillastfaktor ist für Sorptionskältemaschinen im Gegensatz
              zu Kompressionskältemaschinen unabhängig von der Nutzungs- und
              Betriebsart.
            </li>
            <li>
              Für den mittleren Teillastfaktor \(PLV_\mathrm{av}\) wird für alle Typen
              von Sorptionskältemaschinen ein Wert von 0,95 vorgegeben, da langjährige
              praktische Erfahrungen, die eine detailierte Validierung zuließen, für
              diese neuen Technologien noch nicht in ausreichendem Maße vorliegen.
            </li>
            <li>
              Für den Nutzungsfaktor des Rückkühlers \(f_\mathrm{R,av}\) wird
              ebenfalls für alle Technologien ein Wert von 0,65 vorgegeben.
            </li>
          </ul>

          <h3>Endenergiebedarf für die Rückkühlung</h3>
          <p>
            Die energetische Bewertung der Rückkühlung erfolgt anhand des spezifischen
            konstruktionsbedingten Elektroenergieaufwandes des Rückkühlers
            \(q_\mathrm{R,elektr}\) und eines mittleren Nutzungsfaktors
            \(f_\mathrm{R,av}\) der Rückkühlung. Unter Berücksichtigung der
            Nennrückkühlleistung und der mittleren Betriebszeit ergibt sich der
            Endenergiebedarf des Rückkühlers folgender Gleichung:
          </p>
          <p>
            $$W_\mathrm{C,f,R} = \dot{Q}_\mathrm{R,outg} \cdot q_\mathrm{R,elektr}
            \cdot f_\mathrm{R,av} \cdot t_\mathrm{R,op} \cdot f_\mathrm{R,FC}$$
          </p>
          <p>
            Dabei ist
          </p>
          <ul>
            <li>
              \(W_\mathrm{C,f,R}\): der elektrische Endenergiebedarf für Rückkühlung
            </li>
            <li>
              \(\dot{Q}_\mathrm{R,outg}\): die Nennrückkühlleistung
            </li>
            <li>
              \(q_\mathrm{R,elektr}\): der auf die Rückkühlleistung bezogene
              Elektroenergieaufwand für Rückkühler (Standardwert \(0,03
              \mathrm{kW/kW}\))
            </li>
            <li>
              \(f_\mathrm{R,av}\): der mittlere Nutzungsfaktor Rückkühlung
            </li>
            <li>
              \(t_\mathrm{R,op}\): die Betriebszeit der Rückkühlung
            </li>
            <li>
              \(f_\mathrm{R,FC}\): der Nutzungsfaktor für die freie Kühlung; ohne
              freie Kühlung ist \(f_\mathrm{R,FC} = 1\); siehe freie Kühlung
            </li>
          </ul>
          <p>
            mit
          </p>
          <p>
            $$ \dot{Q}_\mathrm{R,outg} = \dot{Q}_\mathrm{C,outg} \cdot \left(1 +
            \frac{1}{EER} \right) \quad \text{für Kompressionskältemaschinen} $$
          </p>
          <p>
            bzw.
          </p>
          <p>
            $$ \dot{Q}_\mathrm{R,outg} = \dot{Q}_\mathrm{C,outg} \cdot \left(1 +
            \frac{1}{\zeta} \right) \quad \text{für Sorptionskältemaschinen} $$
          </p>

          <p><b>Hinweise:</b></p>
          <ul>
            <li>
              Der mittlere Nutzungsfaktor ist abhängig von der Bauweise der
              Rückkühlers (Verdunstungsrückkühler oder Trockenrückkühler), der
              Nutzungsart (Nutzungsprofile nach DIN 18599-10), von der Art der
              Kältenutzung (Raumsystem oder RLT-System) und der Art der
              Kühlwasserregelung (konstante oder variable Kühlwasserbedingungen).
            </li>
            <li>
              Der Endenergiebedarf für die Rückkühlung luftgekühlter Kältemaschinen
              ist bereits im elektrischen Endenergiebedarf enthalten und wird nicht
              gesondert berechnet.
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Zusätzliche Informationen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-container>
            <p>
              Das Verfahren nach DIN 18599-7 ermöglicht die Berechnung der elektrischen
              Endenergie von Kompressionskältemaschinen (KKM) und der thermischen
              Endenergie von Sorptionskältemaschinen (AKM) einschließlich der
              elektrischen Endenergie für die Rückkühlung. Die im folgenden Bild
              dargestellten Kälteerzeugungssysteme sind mittels Kennwertverfahren
              abbildbar.
            </p>

            <img
              src="@/assets/help/chiller/chiller.svg"
              alt="Kältemäschine"
              width="500px"
              style="float: left; margin: 0 45px 0 30px;"
            />
            <b>A: Antriebssystem</b> mit
            <ul style="list-style-type:none;">
              <li>A.1: Verdichter (I) &rarr; Kompressionskältemaschinen</li>
              <li>
                A.2: Absorber (J), Lösungsmittelpumpe (L), Desorber (L) und
                Expansionsventil &rarr; Sorptionskältemaschinen
              </li>
            </ul>
            <b>B: Kondensator</b> mit
            <ul style="list-style-type:none;">
              <li>B.1: luftgekühlter Kondensator &rarr; luftgekühlte Kältemaschinen</li>
              <li>
                B.2: wassergekühlter Kondensator mit Rückkühler (E) und Kühlwasserpumpe
                (F) &rarr; wassergekühlte Kältemaschinen
              </li>
            </ul>
            <b>C: Expansionsventil</b>
            <b>D: Verdampfer</b> mit
            <ul style="list-style-type:none;">
              <li>D.1: direkt im Luftkreis &rarr; Direktverdampfer</li>
              <li>
                D.2: im Kaltwasserkreis mit Luftkühler (G) und Kaltwasserpumpe (H)
              </li>
            </ul>
            <b>E: Rückkühler</b> mit
            <ul style="list-style-type:none;">
              <li>E.1: trockener Wärmeabfuhr &rarr; Trockenrückkühler</li>
              <li>E.2: nasser Wärmeabfuhr &rarr; Verdunstungskühler</li>
            </ul>

            <p>
              Allgemein berechnet sich der Endenergiebedarf für die Kälteerzeugung aus
              dem Energiebedarf der Kältemaschine und dem Energiebedarf für die
              Rückkühlung. Dabei ist die Endenergie der Kältemaschine in elektrische
              Endenergie (Strom) bei Kompressionskälteanlagen und thermische Endenergie
              (Heißwasser) bei Sorptionskälteanlagen zu unterscheiden. Die Endenergie
              der Rückkühlung ist elektrische Endenergie (Strom).
            </p>

            <h3>Erzeugernutzkälteabgabe</h3>
            <p>
              Die von einer Kältemaschine versorgten Gebäudezone werden als
              Kälteversorgungsbereiche bezeichnet. Innerhalb eines solchen
              Kälteversorgungsbereich können mehreren <b>Nutzungsarten</b> mit
              unterschiedlichen <b>Nutzungsprofilen</b> nach DIN V 18599-10 vorliegen.
              Die Nutzkälte kann innerhalb einer Gebäudezone über ein
              <b>RLT-System</b> oder ein <b>Raumsystem</b> bereitgestellt werden.
            </p>
            <p>
              Die Erzeugernutzkälteabgabe einer Kältemaschine wird je Nutzungsart
              getrennt ermittelt. Die Gesamtsumme der jährlichen Erzeugernutzkälteabgabe
              der Kältemaschine eines Kälteversorgungsbereiches ergibt sich aus der
              Summe der Erzeugernutzkälteabgabe aller Nutzungsarten.
            </p>

            <h4>Hinweise</h4>
            <ul>
              <li>
                In der aktuellen Version von EARNS ist es lediglich möglich, eine
                Gebäudezone mit maximal drei unterschiedlichen Nutzungsarten zu
                definieren.
              </li>
              <li>
                Die Berechnungsvorschrift der DIN 18599-7 fordert nicht für alle
                Kältemaschinentypen eine Definition der Nutzungsarten.
              </li>
            </ul>

            <hr />

            

            <hr />

            <h2>Freie Kühlung</h2>
            <p>
              Der nutzbare Anteil der freien Kühlung mittels Anlagentechnik ist abhängig
              von:
            </p>
            <ul>
              <li>
                Nutzungsart und Bauschwere der gekühlten Zone
              </li>
              <li>
                Bauart der Rückkühlung (Trocken-/Verdunstungskühler)
              </li>
              <li>
                Temperaturniveau des Kaltwasserkreislaufs
              </li>
              <li>
                Betriebsweise (Parallelbetrieb, Alternativbetrieb) und Regelung der
                Rückkühlung
              </li>
              <li>
                Auslegungsfaktor für die Rückkühler
              </li>
            </ul>
            <h3>Freie Kühlung mittels Rückkühlwerken</h3>
            <p>
              Bei der Freien Kühlung mittels Rückkühlwerken werden vorhandene
              Rückkühlwerke in Zeiten reduzierter thermischer Belastungen und damit
              geringerer Kühllasten direkt zur Kälteversorgung genutzt. Die
              Kältemaschine wird dabei nicht in Betrieb genommen
              (<b>Alternativbetrieb</b>) oder kann bei mehreren Rückkühlwerken gemeinsam
              mit der Freien Rückkühlung genutzt werden (<b>Parallelbetrieb</b>).
            </p>

            <h3>
              Freie Kühlung mittels luftgekühlten Kältemaschinen mit integrierten
              Freikühlregistern
            </h3>
            <p>
              Die maschinenintegrierte Freie Kühlung von luftgekühlten Kältemaschinen
              wird von den Herstellern mit verschiedenen Verfahren realisiert. Am
              häufigsten werden so genannte Freikühlregister im Parallelbetrieb mit den
              Kondensatoren betrieben, die kaltwasserdurchströmt und rücklaufseitig
              angeordnet sind. Als Betriebsweise ist daher nur
              <b>Parallelbetrieb</b> möglich. Des Weiteren können nur folgenden
              Teillastregelarten ausgewählt werden:
            </p>
            <ul>
              <li>
                (B): Kolben-/Scrollverdichter mehrstufig schaltbar (mindestens vier
                Schaltstufen als Verdichterverbund)
              </li>
              <li>
                (C): Schraubenverdichter mit Steuerschieberregelung
              </li>
              <li>
                (D): Invertergeregelter Schraubenverdichter
              </li>
            </ul>

            <hr />

            <h2>Geothermische Kühlung</h2>
            <p>
              work in progress...
            </p>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="secondary">
          <div class="heading">Quellen</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h2>Quellen</h2>
          <ul>
            <li>
              DIN V 18599-7: Energetische Bewertung von Gebäuden - Berechnung des
              Nutz-, End- und Primärenergiebedarfs für Heizung, Kühlung, Lüftung,
              Trinkwarmwasser und Beleuchtung - Teil 7: Endenergiebedarf von
              Raumlufttechnik- und Klimakältesystemen für den Nichtwohnungsbau
            </li>
            <li>
              Schiller, Heiko; Mai, Roony: Berechnungs-Algorithmen für Freie und
              Regenerative Kühltechnologien in Nichtwohngebäuden. Fraunhofer IRB
              Verlag
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer class="mt-10">
      <small>
        Das Fenster kann an der linken Kante angepasst und auf Hilfe wieder
        geschlossen werden.
      </small>
    </v-footer>

  </div>
</template>

<script>

export default {
  name: "ChillerHelp",
  mounted() {
    if (window.MathJax) {
      window.MathJax.typeset();
    }
  }
};
</script>

<style scoped></style>
