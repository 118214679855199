<template>
  <v-form @submit.prevent="" ref="form">
    <v-text-field
      v-model.number="amount"
      type="number"
      :error-messages="fieldErrors.amount"
      label="Anzahl"
      :hint="hint.amount"
      :rules="validationErrors.amount"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.amount"
        @click="getReferenceParameter(`amount`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="volumeFlow"
      type="number"
      :error-messages="fieldErrors.volumeFlow"
      label="Luftmenge"
      suffix="m³/h"
      :hint="hint.volume_flow"
      :rules="validationErrors.volume_flow"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.volume_flow"
        @click="getReferenceParameter(`volume_flow`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="totalPressure"
      type="number"
      :error-messages="fieldErrors.totalPressure"
      label="Gesamtdruck"
      suffix="Pa"
      :hint="hint.total_pressure"
      :rules="validationErrors.total_pressure"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.total_pressure"
        @click="getReferenceParameter(`total_pressure`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="fanType"
      :error-messages="fieldErrors.fanType"
      label="Laufrad"
      :items="fanTypes"
      :rules="validationErrors.fan_type"
      validate-on-blur
    >
      <v-tooltip v-if="hint.fan_type" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.fan_type }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.fan_type"
        @click="getReferenceParameter(`fan_type`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="regulation"
      :error-messages="fieldErrors.regulation"
      label="Regelung"
      :items="regulations"
      :rules="validationErrors.regulation"
      validate-on-blur
    >
      <v-tooltip v-if="hint.regulation" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.regulation }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.regulation"
        @click="getReferenceParameter(`regulation`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="ratedPower"
      :error-messages="fieldErrors.ratedPower"
      label="Leistungsklasse"
      :items="ratedPowers"
      item-text="power"
      item-value="id"
      suffix="kW"
      :rules="validationErrors.rated_power"
      validate-on-blur
    >
      <v-tooltip v-if="hint.rated_power" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.rated_power }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.rated_power"
        @click="getReferenceParameter(`rated_power`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="poles"
      :error-messages="fieldErrors.poles"
      label="Polzahl"
      :items="poleList"
      :rules="validationErrors.poles"
      validate-on-blur
    >
      <v-tooltip v-if="hint.poles" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.poles }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.poles"
        @click="getReferenceParameter(`poles`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="efficiencyClass"
      :error-messages="fieldErrors.efficiencyClass"
      label="Effizienzklasse"
      :items="efficiencyClasses"
      :rules="validationErrors.efficiency_class"
      validate-on-blur
    >
      <v-tooltip v-if="hint.efficiency_class" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.efficiency_class }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.efficiency_class"
        @click="getReferenceParameter(`efficiency_class`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="gear"
      :error-messages="fieldErrors.gear"
      label="Antrieb"
      :items="gears"
      :rules="validationErrors.gear"
      validate-on-blur
    >
      <v-tooltip v-if="hint.gear" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.gear }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.gear"
        @click="getReferenceParameter(`gear`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      v-model.number="fullLoad"
      type="number"
      :error-messages="fieldErrors.fullLoad"
      label="Anteil Volllast"
      suffix="%"
      :hint="hint.full_load"
      :rules="validationErrors.full_load"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.full_load"
        @click="getReferenceParameter(`full_load`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="partLoad75"
      type="number"
      :error-messages="fieldErrors.partLoad75"
      label="Anteil 75% Teillast"
      suffix="%"
      v-if="!(fullLoad == null) && fullLoad < 100"
      :hint="hint.part_load_75"
      :rules="validationErrors.part_load_75"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.part_load_75"
        @click="getReferenceParameter(`part_load_75`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="partLoad50"
      type="number"
      :error-messages="fieldErrors.partLoad50"
      label="Anteil 50% Teillast"
      suffix="%"
      v-if="!(fullLoad == null) && fullLoad < 100"
      :hint="hint.part_load_50"
      :rules="validationErrors.part_load_50"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.part_load_50"
        @click="getReferenceParameter(`part_load_50`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="partLoad25"
      type="number"
      :error-messages="fieldErrors.partLoad25"
      label="Anteil 25% Teillast"
      suffix="%"
      v-if="!(fullLoad == null) && fullLoad < 100"
      :hint="hint.part_load_25"
      :rules="validationErrors.part_load_25"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.part_load_25"
        @click="getReferenceParameter(`part_load_25`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="uptime"
      type="number"
      :error-messages="fieldErrors.uptime"
      label="Jährliche Betriebsstunden"
      suffix="h"
      :hint="hint.uptime"
      :rules="validationErrors.uptime"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.uptime"
        @click="getReferenceParameter(`uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import { djangoChoicesToVuetify } from "@/utils";
import { cacheAdapter } from "@/cache";

export default {
  name: "Fan2Form",
  extends: BaseForm,

  data() {
    return {
      amount: null,
      volumeFlow: null,
      totalPressure: null,
      fanType: null,
      regulation: null,
      ratedPower: null,
      poles: null,
      efficiencyClass: null,
      gear: null,
      fullLoad: null,
      partLoad75: null,
      partLoad50: null,
      partLoad25: null,
      uptime: null,

      fields: {
        amount: "amount",
        volumeFlow: "volume_flow",
        totalPressure: "total_pressure",
        fanType: "fan_type",
        regulation: "regulation",
        ratedPower: "rated_power",
        poles: "poles",
        efficiencyClass: "efficiency_class",
        gear: "gear",
        fullLoad: "full_load",
        partLoad75: "part_load_75",
        partLoad50: "part_load_50",
        partLoad25: "part_load_25",
        uptime: "uptime"
      },

      fanTypes: [],
      regulations: [],
      ratedPowers: [],
      poleList: [2, 4, 6],
      efficiencyClasses: [],
      gears: []
    };
  },

  beforeMount() {
    this.fetchFanTypes();
    this.fetchRegulations();
    this.fetchRatedPowers();
    this.fetchEfficiencyClasses();
    this.fetchGears();
  },

  mounted() {
    if (!this.partLoad75) {
      this.partLoad75 = 0;
    }
    if (!this.partLoad50) {
      this.partLoad50 = 0;
    }
    if (!this.partLoad25) {
      this.partLoad25 = 0;
    }
  },

  methods: {
    fetchFanTypes() {
      this.$http
        .get("/api/v1/methods/fan2/fan-type/", { adapter: cacheAdapter })
        .then((response) => {
          this.fanTypes = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {
          // TODO: error handling
        });
    },
    fetchRegulations() {
      this.$http
        .get("/api/v1/methods/fan2/regulation/", { adapter: cacheAdapter })
        .then((response) => {
          this.regulations = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {
          // TODO: error handling
        });
    },
    fetchRatedPowers() {
      this.$http
        .get("/api/v1/methods/fan2/rated_powers/", { adapter: cacheAdapter })
        .then((response) => {
          this.ratedPowers = response.data;
        })
        .catch(() => {
          // TODO: error handling
        });
    },
    fetchEfficiencyClasses() {
      this.$http
        .get("/api/v1/methods/fan2/efficiency-classes/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.efficiencyClasses = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {
          // TODO: error handling
        });
    },
    fetchGears() {
      this.$http
        .get("/api/v1/methods/fan2/gear/", { adapter: cacheAdapter })
        .then((response) => {
          this.gears = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {
          // TODO: error handling
        });
    }
  }
};
</script>

<style scoped></style>