var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.project)?_c('div',[_c('v-card',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Maßnahmen ")]),_c('v-row',{staticClass:"justify-end",attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex"},[_c('v-container',[_c('v-btn',{staticClass:"mb-6",attrs:{"disabled":!_vm.actionList.length,"color":"teal accent-3","bottom":""},on:{"click":function($event){return _vm.calcAction()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-speedometer")]),_vm._v(" Analyse starten ")],1)],1),_c('v-container',[_c('Helpbar',{attrs:{"help":_vm.ProjectDashboardHelp}})],1)],1)])],1),_c('v-card-text',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Gruppe")]),_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Methode")]),_c('th',{staticClass:"text-right"},[_vm._v("Optionen")])])]),_vm._l((_vm.byActionGroup),function(actions,groupId){return [_c('tbody',{key:groupId},[_c('tr',[(groupId !== 'null')?_c('td',{staticClass:"group",attrs:{"colspan":"4"}},[_c('p',{staticClass:"mb-0",staticStyle:{"line-height":"35px"}},[_c('span',[_vm._v(_vm._s(_vm.getGroupName(groupId)))]),_c('span',{staticStyle:{"float":"right"}},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-checkbox',{attrs:{"input-value":!_vm.checkNoCalcGroup(_vm.getGroup(groupId)),"no-gutters":"","title":"Maßnahmengruppe berechnen"},on:{"click":function($event){$event.stopPropagation();_vm.addNoCalcGroup(_vm.getGroup(groupId))}}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","title":"Optionen"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-dialog',{attrs:{"persistent":"","max-width":"290","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","block":"","color":"red"},on:{"click":function($event){return _vm.storeGroupIdForDialog(groupId)}}},'v-btn',attrs,false),on),[_vm._v(" Löschen ")])]}}],null,true),model:{value:(_vm.dialogDeleteGroup),callback:function ($$v) {_vm.dialogDeleteGroup=$$v},expression:"dialogDeleteGroup"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Gruppe löschen ")]),_c('v-card-text',[_vm._v("Alle Maßnahmen werden aus Gruppe und Projekt gelöscht!")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialogDeleteGroup = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteActionGroup(_vm.getGroup(groupId))}}},[_vm._v(" Löschen ")])],1)],1)],1)],1),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":function($event){$event.stopPropagation();_vm.cloneActionGroup(_vm.getGroup(groupId))}}},[_vm._v(" Kopie erstellen ")])],1),_c('v-list-item',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","block":""},on:{"click":function($event){_vm.storeGroupIdForDialog(groupId);
                                  _vm.newActionGroupName = _vm.getGroupName(groupId);}}},'v-btn',attrs,false),on),[_vm._v(" Umbenennen ")])]}}],null,true),model:{value:(_vm.dialogRenameGroup),callback:function ($$v) {_vm.dialogRenameGroup=$$v},expression:"dialogRenameGroup"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Gruppe umbennen")])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.newActionGroupName),callback:function ($$v) {_vm.newActionGroupName=$$v},expression:"newActionGroupName"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialogRenameGroup = false;
                                    _vm.newActionGroupName = null;}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","disabled":!_vm.newActionGroupName},on:{"click":function($event){return _vm.renameActionGroup()}}},[_vm._v(" Speichern ")])],1)],1)],1)],1)],1)],1)],1)],1)])]):_c('td',{staticClass:"group",attrs:{"colspan":"4"}},[_vm._v(" ohne Zuordnung ")])]),_vm._l((actions),function(action){return _c('tr',{key:action.id,staticClass:"pointer",on:{"click":function($event){return _vm.editAction(action)}}},[_c('td'),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(action.name)),(action.partial_plan)?_c('span',{staticClass:"ml-1 red--text"},[_vm._v("(Entwurf)")]):_vm._e()]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.checkMethodTexts(action))+" ")]),_c('td',{staticClass:"text-right"},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[(!action.group)?_c('v-checkbox',{attrs:{"input-value":!_vm.checkNoCalc(action),"no-gutters":"","title":"Maßnahme berechnen"},on:{"click":function($event){$event.stopPropagation();return _vm.addNoCalc(action)}}}):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","rounded-xl":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","title":"Optionen"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('delete-missile-switch',{attrs:{"activatorClass":"ml-10"},on:{"click":function($event){return _vm.deleteAction(action)}}},[_vm._v(" Löschen ")])],1),_c('v-list-item',[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.cloneAction(action)}}},[_vm._v(" Kopie erstellen ")])],1),_c('v-list-item',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","block":""}},'v-btn',attrs,false),on),[_vm._v(" Gruppe zuordnen ")])]}}],null,true)},[_c('v-list',[_vm._l((_vm.groups),function(item,index){return _c('v-list-item',{key:index},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":function($event){return _vm.moveToGroup(item, action)}}},[_vm._v(_vm._s(item.name))])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('delete-missile-switch',{attrs:{"activatorIcon":""},on:{"click":function($event){return _vm.deleteOnlyActionGroup(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)],1)}),_vm._l((_vm.nogroup),function(item,index){return _c('v-list-item',{key:index},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":function($event){return _vm.moveToGroup(item, action)}}},[_vm._v(_vm._s(item.name))])],1)],1)],1)})],2)],1)],1),_c('v-list-item',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","block":""},on:{"click":function($event){$event.stopPropagation();_vm.storeActionDataForDialog(action);_vm.newActionName = action.name}}},'v-btn',attrs,false),on),[_vm._v(" Umbenennen ")])]}}],null,true),model:{value:(_vm.dialogRenameAction),callback:function ($$v) {_vm.dialogRenameAction=$$v},expression:"dialogRenameAction"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Maßnahme umbennen")])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.newActionName),callback:function ($$v) {_vm.newActionName=$$v},expression:"newActionName"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){$event.stopPropagation();_vm.dialogRenameAction = false;
                                _vm.newActionName = null;}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","disabled":!_vm.newActionName},on:{"click":function($event){return _vm.renameAction(_vm.newActionName, _vm.dialogActionData)}}},[_vm._v(" Speichern ")])],1)],1)],1)],1)],1)],1)],1)],1)])})],2)]})],2),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"end","no-gutters":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-folder-plus")]),_vm._v(" Gruppe hinzufügen ")],1)]}}],null,false,2006892723),model:{value:(_vm.dialogCreateGroup),callback:function ($$v) {_vm.dialogCreateGroup=$$v},expression:"dialogCreateGroup"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Gruppe hinzufügen")])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.newActionGroupName),callback:function ($$v) {_vm.newActionGroupName=$$v},expression:"newActionGroupName"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialogCreateGroup = false;
                  _vm.newActionGroupName = null;}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"text":"","disabled":!_vm.newActionGroupName},on:{"click":_vm.newActionGroup}},[_vm._v(" Speichern ")])],1)],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.newAction}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Maßnahme hinzufügen ")],1)],1)],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"start"}},[_c('delete-missile-switch',{attrs:{"activatorClass":"ma-3"},on:{"click":_vm.deleteProject}},[_vm._v(" Projekt löschen ")]),_c('v-btn',{staticClass:"ma-3",attrs:{"color":"primary"},on:{"click":_vm.editProjectDetails}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Projektdetails bearbeiten ")],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }